import React, { FC, ChangeEvent } from 'react'
import { Slider, SliderProps } from '@material-ui/core'

export interface ISurSlider extends Omit<SliderProps, 'onChange'> {
  onChange: (event: ChangeEvent<object>, newValue: number | number[]) => void
}

const SurSlider: FC<ISurSlider> = (props) => <Slider {...props} />

SurSlider.displayName = 'SurSlider'
SurSlider.defaultProps = {
  color: 'primary',
  valueLabelDisplay: 'auto',
  value: 0,
}

export default SurSlider
