import * as React from 'react'

const AlertInfo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    id="jasmijn_web_ico_info_01"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <rect id="container" width="24" height="24" fill="none" />
    <path
      id="jasmijn_web_ico_info_01-2"
      data-name="jasmijn_web_ico_info_01"
      d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm1.2,18H10.8V10.8h2.4V18Zm0-9.6H10.8V6h2.4V8.4Z"
      fill="#33A4E1"
    />
  </svg>
)

const MemoSvgComponent = React.memo(AlertInfo)
export default MemoSvgComponent
