import { Box, ClickAwayListener } from '@material-ui/core'
import React, { FC, memo, useRef } from 'react'
import { animated, useSpring } from 'react-spring'
import useMeasure from 'utils/useMeasure'
import SurSearchProvider from './SurSearch.context'
import useStyles from './SurSearch.style'
import SurSearchInput from './SurSearchInput'
import SurSearchPopper from './SurSearchPopper'

export interface ISurSearch {
  open: boolean
  history: any[]
  value?: any
  loading?: boolean
  data: any[]
  labelExp: string
  onOpen(): void
  onClose(): void
  onChange(value?: any): void
  onItemClick(option: any): void
  onHistoryItemRemove?(item: any, filterHistory: any[]): void
  onSubmit?(value?: any): void
}

const SurSearch: FC<ISurSearch> = (props) => {
  const { open, onClose } = props
  const classes = useStyles()
  const node = useRef(null)
  const [bind, { height }] = useMeasure()
  const animationProps = useSpring({
    height: open ? +height + 42 : 42,
  })

  return (
    <SurSearchProvider {...props}>
      <ClickAwayListener onClickAway={onClose}>
        <Box className={classes.root}>
          <animated.div
            style={animationProps}
            className={classes.container}
            data-testid="SurSearch"
            ref={node}
          >
            <SurSearchInput />
            <Box className={classes.listContainer} {...bind}>
              <SurSearchPopper />
            </Box>
          </animated.div>
        </Box>
      </ClickAwayListener>
    </SurSearchProvider>
  )
}

SurSearch.displayName = 'SurSearch'
export default memo(SurSearch)
