import React, { ReactNode, FC } from 'react'
import { FormHelperText } from '@material-ui/core'

interface ISurErrorProp {
  error?: ReactNode
}
const SurError: FC<ISurErrorProp> = ({ error }) =>
  error ? <FormHelperText error>{error}</FormHelperText> : null

export default SurError
