import { makeFloraMaidStyles } from 'layout/FloraMaidTheme'

interface IStyleProps {
  isYearModalOpen?: boolean
  isMonthModalOpen?: boolean
}
const datePickerModalAnimation = () => ({
  from: { opacity: 0 },
  to: { opacity: 1 },
})

export default makeFloraMaidStyles<IStyleProps>((theme) => ({
  root: {
    '& *': {
      '-webkit-touch-callout': 'none' /* iOS Safari */,
      '-webkit-user-select': 'none' /* Safari */,
      '-khtml-user-select': 'none' /* Konqueror HTML */,
      '-moz-user-select': 'none' /* Old versions of Firefox */,
      '-ms-user-select': 'none' /* Internet Explorer/Edge */,
      'user-select':
        'none' /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */,
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: '0',
    border: `1px solid ${theme.palette?.neutral?.[500]}`,
    borderRadius: 3,
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: '#fff',
  },
  months: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: 370 * 3,
    flexWrap: 'wrap',
    // [theme?.breakpoints?.up?.("largePhone") as string]: {
    // 	width: 324,
    // },
    // [theme?.breakpoints?.up?.("smallTablet") as string]: {
    // 	width: 280,
    // },
    // [theme?.breakpoints?.up?.("largeTablet") as string]: {
    // 	width: 288,
    // },
    // [theme?.breakpoints?.up?.("laptop") as string]: {
    // 	width: 369,
    // },
    // [theme?.breakpoints?.up?.("desktop") as string]: {
    // 	width: 450,
    // },
  },
  month: {
    display: 'flex',
    flexDirection: 'column',
    width: 370,
  },
  monthHeader: {
    position: 'relative',
    '& .DatePickerModalContainer': {
      display: 'flex',
      zIndex: 9,
      flexWrap: 'wrap',
      position: 'absolute',
      padding: '40px 0 0 0',
      top: 55,
      left: 0,
      right: 0,
      bottom: 0,
      overflow: 'hidden',
      backgroundColor: 'rgba(255,255,255,0.5)',
      transition: 'all 1s',
      fontWeight: '600',
      color: theme.palette?.neutral?.[900],
      height: 0,
      opacity: 0,
      '&.DatePickerModalActive': {
        height: 500,
        opacity: 1,
      },
      '& .DatePickerModal': {
        display: 'flex',
        flexWrap: 'wrap',
        height: 257,
        padding: '0 28px',
        backgroundColor: '#fff',
        '& .DatePickerModalButton': {
          display: 'flex',
          cursor: 'pointer',
          flex: '1 23%',
          height: 60,
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: 12,
          margin: '5px 3px',
          transition: '0.5s all',
          fontSize: '0.75rem',
          '&:hover': {
            color: theme.palette?.neutral?.[900],
            backgroundColor: theme.palette?.secondary?.A300,
          },
          '&.DatePickerModalCurrentButton': {
            backgroundColor: theme.palette?.primary?.[50],
            color: theme.palette?.neutral?.[900],
            '&:hover': {
              color: theme.palette?.neutral?.[900],
              backgroundColor: theme.palette?.secondary?.A300,
            },
          },
        },
      },
    },
    '& header': {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '12px 26px 0 20px',
      marginBottom: 21,
      alignItems: 'center',
      height: 46,
      '& .datePickerHeaderLeft': {
        display: 'flex',
        '& .datePickerHeaderLeftBtns': {
          border: 'unset',
          background: 'unset',
          cursor: 'pointer',
          marginRight: 5,
          '& h5': {
            fontWeight: '600',
            color: theme.palette?.neutral?.[900],
          },
        },
      },
      '& .datePickerHeaderRight': {
        '& .datePickerHeaderRightBtn': {
          cursor: 'pointer',
          color: theme.palette?.secondary?.main,
          border: 'unset',
          background: 'unset',
          transition: '0.5s all',
          padding: '4px 10px',
          borderRadius: 3,
          '&:hover': {
            backgroundColor: theme.palette?.secondary?.[100],
            color: theme.palette?.secondary?.[500],
          },
        },
      },
    },
  },
  monthMain: {
    padding: '0 28px 0 28px',
    '& .datePickerWeekLabelsContainer': {
      display: 'grid',
      gridTemplateColumns: 'repeat(7, 1fr)',
      justifyContent: 'center',
      marginBottom: 5,

      '& .datePickerWeekLabels': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        fontSize: '0.688rem',
        color: theme.palette?.neutral?.[500],
        textTransform: 'uppercase',
        textAlign: 'center',
        width: 38,
        // height: 40,
        margin: '0 5px 12px 5px',
        transition: '0.5s opacity',
        '&.todayWeek': {
          color: theme.palette?.primary?.main,
          '&:after': {
            content: "''",
            // width: 46,
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: -9,
            height: 2,
            borderRadius: 10,
            backgroundColor: theme.palette?.primary?.main,
          },
        },
        '&.activeWeek': {
          color: theme.palette?.secondary?.main,
          '&:after': {
            content: "''",
            // width: 46,
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: -9,
            height: 2,
            borderRadius: 10,
            backgroundColor: theme.palette?.secondary?.main,
          },
        },
      },
    },
    '& .datePickerDaysContainer': {
      display: 'grid',
      gridTemplateColumns: 'repeat(7, 1fr)',
      justifyContent: 'center',
    },
  },
  footer: {
    '& .datePickerFooterHolidayes': {
      position: 'relative',
      width: '100%',
      height: 24,
      margin: '8px 0 18px 0',
      '& .datePickerFooterHoliday': {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 20,
        display: 'flex',
        animation: '$holidayAnimaitonIn .5s',
        alignItems: 'center',
        '& img': {
          marginRight: 5,
        },
      },
    },
    '& .datePickerFooterDay': {
      display: 'flex',
      flexDirection: 'column',
      minHeight: 64,
      backgroundColor: theme.palette?.primary?.main,
      // justifyContent: 'center',
      // alignItems: 'center',
      color: '#fff',
      fontSize: '1.375rem',
      padding: '0 31px',
      '& .selectedDateText': {
        display: 'flex',
        // justifyContent: 'center',
        alignItems: 'center',
        margin: '16px 0 12px 0 ',
      },
    },
  },
  [`@keyframes holidayAnimaitonIn`]: {
    from: {
      left: -50,
      opacity: 0,
    },
    to: {
      left: 20,
      opacity: 1,
    },
  },
  [`@keyframes datePickerMonthAnimation`]: datePickerModalAnimation,
  [`@keyframes datePickerYearAnimation`]: datePickerModalAnimation,
}))
