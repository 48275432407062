import React from 'react'
import useStyle from './SurDoughnutChart.style'
import { ChartData as ReactChartData, Doughnut } from 'react-chartjs-2'
import { ChartData, ChartOptions, ChartDataSets } from 'chart.js'

export interface ISurDoughnutChartProps {
  datasets: SurChartDataSets[]
  labels: string[]
  options?: ChartOptions
  width?: number
  height?: number
}
export interface SurChartDataSets extends ChartDataSets {
  type?: 'bar'
}

const defaultOptions: ChartOptions = {
  responsive: true,
  maintainAspectRatio: true,
  animation: {
    easing: 'easeInOutExpo',
  },
  scales: {
    yAxes: [
      {
        ticks: {
          fontColor: 'rgba(0,0,0,0.5)',
          fontStyle: 'bold',
          beginAtZero: true,
          maxTicksLimit: 5,
          padding: 20,
        },
        gridLines: {
          drawTicks: false,
          display: true,
          borderDash: [8, 4],
        },
      },
    ],
    xAxes: [
      {
        gridLines: {
          zeroLineColor: 'transparent',
          display: false,
        },
        ticks: {
          padding: 20,
          fontColor: 'rgba(0,0,0,0.5)',
          fontStyle: 'bold',
        },
      },
    ],
  },
  plugins: {
    filler: {
      propagate: true,
    },
  },
}

const SurDoughnutChart: React.FC<ISurDoughnutChartProps> = ({
  datasets,
  labels,
  options = defaultOptions,
  width = 700,
  height = 400,
}) => {
  const classes = useStyle({})

  const SurDoughnutChartConfig: ReactChartData<ChartData> = {
    labels,
    datasets,
  }
  return (
    <div className={classes.root}>
      <Doughnut data={SurDoughnutChartConfig} {...{ options, width, height }} />
    </div>
  )
}

SurDoughnutChart.displayName = 'SurDoughnutChart'
export default SurDoughnutChart
