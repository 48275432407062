import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { ArrowIcon } from '@sur-ui/icons'
import SurCheckbox from 'components/SurCheckbox'
import React, { FC } from 'react'
import useStyle from './SurExpansion.style'

export interface ISurAccordion {
  defaultExpanded?: boolean
  disabled?: boolean
  expanded?: boolean
  square?: boolean
  children?: React.ReactNode
  summary?: React.ReactNode
  checkable?: boolean
  checked?: boolean
  hasSpacing?: boolean
  onCheckboxChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void
  onChange?(event: React.ChangeEvent<object>, expanded: boolean): void
}

const SurAccordion: FC<ISurAccordion> = ({
  children,
  defaultExpanded,
  disabled,
  expanded,
  summary,
  square,
  onChange,
  onCheckboxChange,
  checkable,
  checked,
  hasSpacing,
}) => {
  const classes = useStyle({ hasSpacing })

  return (
    <Accordion
      {...{
        classes,
        defaultExpanded,
        disabled,
        expanded,
        square,
        onChange,
      }}
    >
      <AccordionSummary
        expandIcon={<ArrowIcon style={{ width: 16, height: 16 }} />}
      >
        {checkable ? (
          <FormControlLabel
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
            control={
              <SurCheckbox {...{ checked, onChange: onCheckboxChange }} />
            }
            label={summary}
          />
        ) : (
          summary
        )}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  )
}

SurAccordion.displayName = 'SurExpansionPanel'
export default SurAccordion
