
import React, {useState, useCallback, ReactNode, FC} from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Card } from './SurSortableDrag'
import update from 'immutability-helper'

interface ISurSortableProps {
  items: {body: ReactNode, id: number}[]
}

export interface Item {
  id: number
  body: string
}

export interface ContainerState {
  cards: Item[]
}

const SurSortable: FC<ISurSortableProps> = ({items}) => {

  const [cards, setCards] = useState(items)

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragCard = cards[dragIndex]
      setCards(
        update(cards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      )
    },
    [cards],
  )

  const renderCard = (card: { id: number; body: ReactNode }, index: number) => (
    <Card
      key={card.id}
      index={index}
      id={card.id}
      moveCard={moveCard}
    >{card.body}</Card>
  )

  return (
    <DndProvider backend={HTML5Backend}>
      {cards.map((card, i) => renderCard(card, i))}
    </DndProvider>
  )
}

export default SurSortable
