import { Box } from '@material-ui/core'
import {ClearText as ClearIcon} from '@sur-ui/icons'
import {SearchHistory as SearchHistoryIcon} from '@sur-ui/icons'
import SurButton from 'components/SurButton'
import React, { FC } from 'react'
import { useSurSearchContext } from './SurSearch.context'
import useStyles from './SurSearch.style'

interface ISurSearchItem {
  option: any
  isHistory?: boolean
}

const SurSearchItem: FC<ISurSearchItem> = ({ option, isHistory }) => {
  const classes = useStyles()
  const {
    onItemClick,
    labelExp,
    onHistoryItemRemove,
    history,
  } = useSurSearchContext()
  const onClick = () => onItemClick(option)
  const handleHistoryItemRemove = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void => {
    event.stopPropagation()
    event.preventDefault()
    const filterHistory = history.filter(
      (item) => item[labelExp] !== option[labelExp],
    )
    onHistoryItemRemove && onHistoryItemRemove(option, filterHistory)
  }

  return (
    <Box
      data-testid={isHistory ? 'SurSearchHistoryItem' : 'SurSearchItem'}
      {...{
        component: 'li',
        className: classes.groupedOptionsItem,
        onClick,
      }}
    >
      <Box className={classes.itemLabel}>
        {isHistory && <SearchHistoryIcon />}
        <Box component="span" data-testid="SurSearchItemValue">
          {option[labelExp]}
        </Box>
      </Box>
      {isHistory && onHistoryItemRemove && (
        <SurButton
          data-testid="SurSearchHistoryItemRemoveBtn"
          classes={{ root: classes.searchBtnRoot }}
          variant="text"
          color="inherit"
          onClick={handleHistoryItemRemove}
        >
          <ClearIcon />
        </SurButton>
      )}
    </Box>
  )
}

SurSearchItem.displayName = 'SurSearchItem'
export default SurSearchItem
