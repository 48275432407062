import { Popover, PopoverProps } from '@material-ui/core'
import React, { FC } from 'react'

export type ISurPopOver = Omit<PopoverProps, 'open'>

const SurPopOver: FC<ISurPopOver> = ({ anchorEl, ...props }) => (
  <Popover
    {...{
      anchorEl,
      ...props,
    }}
    open={Boolean(anchorEl)}
  />
)

SurPopOver.displayName = 'SurPopOver'
export default SurPopOver
