import * as React from 'react'

const TimeIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.996"
    height="16"
    viewBox="0 0 15.996 16"
    {...props}
  >
    <path
      id="jasmijn_web_ico_time_02"
      d="M20676,18792a8,8,0,0,1,12.965-6.273.666.666,0,1,1-.828,1.043,6.653,6.653,0,1,0,1.867,2.33.666.666,0,1,1,1.2-.58,7.9,7.9,0,0,1,.793,3.48,8,8,0,1,1-16,0Zm8,.666a.666.666,0,0,1-.666-.666v-4.668a.666.666,0,0,1,1.332,0v4h2.668a.667.667,0,0,1,0,1.334Z"
      transform="translate(-20676 -18784)"
      fill="#fff"
    />
  </svg>
)

const MemoSvgComponent = React.memo(TimeIcon)
export default MemoSvgComponent
