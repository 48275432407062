import { Box } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { ArrowIcon as PopUpIcon } from '@sur-ui/icons'
import classNames from 'classnames'
import React from 'react'
import useSurAutocomplete from './SurAutocomplete.biz'
import { ISurAutocomplete } from './SurAutocomplete.types'
import SurAutocompletePaper from './SurAutocompletePaper'

const SurAutocomplete: React.FC<ISurAutocomplete> = ({
  data,
  options,
  inputProps,
  onChange,
  popupIcon,
  classes,
  value,
  ...rest
}) => {
  const {
    isLoading,
    multiple,
    disabled,
    disableCloseOnSelect,
    noOptionText,
  } = options
  const {
    classes: customClasses,
    filterOptions,
    ...other
  } = useSurAutocomplete({
    data,
    value,
    inputProps,
    options,
  })

  return (
    <Autocomplete
      data-testid={rest.id}
      classes={{
        root: classNames(customClasses.root, classes?.root),
        ...classes,
      }}
      options={data}
      disableClearable
      handleHomeEndKeys
      noOptionsText={noOptionText ?? 'No Options'}
      popupIcon={
        popupIcon ? (
          popupIcon
        ) : (
          <Box component="div" className={customClasses.popupIcon}>
            <PopUpIcon width={15} height={15} />
          </Box>
        )
      }
      {...{
        PaperComponent: (params) => (
          <SurAutocompletePaper
            {...{
              classes: customClasses,
              isLoading,
              params,
              multiple,
            }}
          />
        ),
        filterOptions,
        multiple,
        disableCloseOnSelect,
        disabled,
        value,
        ...other,
        ...rest,
      }}
      onChange={(_, val) => onChange(val)}
    />
  )
}

SurAutocomplete.displayName = 'SurAutocomplete'
export default SurAutocomplete
