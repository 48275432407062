import { makeFloraMaidStyles } from 'layout/FloraMaidTheme'

interface IStyleProps {
  varient?: 'default' | 'outlined'
  hasAvatar?: boolean
  hasExpand?: boolean
}
export default makeFloraMaidStyles<IStyleProps>((theme) => ({
  root: {
    width: 450,
    // maxWidth: 450,
    boxShadow: ({ varient }) =>
      varient === 'outlined' ? 'unset' : '1px 1px 3px 1px rgba(0,0,0,0.2)',
    border: ({ varient }) =>
      varient === 'outlined'
        ? `1px solid ${theme.palette?.neutral?.[200]}`
        : 'unset',
    borderRadius: 3,
    '& .SurCardHeaderRoot': {
      padding: '24px 13px 24px 24px',

      '& .MuiCardHeader-root': {
        padding: 0,
        '& .MuiCardHeader-action': {
          marginTop: '0!important',
          marginRight: '0!important',
        },
        '& .MuiCardHeader-content': {
          flexDirection: 'column',
          color: theme.palette?.neutral?.[500],
          display: ({ hasAvatar }) => (!hasAvatar ? 'flex' : 'block'),
          // justifyContent: ({ hasAvatar }) => (!hasAvatar ? "center" : "unset"),
        },
      },
    },
    '& .MuiCardContent-root': {
      padding: '16px 24px 20px 24px',
      '& .MuiTypography-root': {
        color: theme.palette?.neutral?.[500],
      },
    },
  },
  media: {
    height: 0,
    paddingTop: 180, // 16:9
  },
  avatar: {
    backgroundColor: theme.palette?.error?.[500],
  },
  cardActions: {
    '& .SurExpandButtons': {
      flex: 1,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      '& .SurExpandLeftButtons': {
        '& .MuiPaper-root': {
          marginRight: 10,
        },
      },
      '& .SurExpandRightButtons': {},
    },
  },
  paper: {
    '& ul': {
      width: '100%',
      margin: 0,
      padding: 0,
      '& > li': {
        width: '100%',
        listStyleType: 'none',
        minHeight: 30,
        display: 'flex',
      },
    },
  },
}))
