import { makeFloraMaidStyles } from 'layout/FloraMaidTheme'

export default makeFloraMaidStyles(({ palette: { neutral } }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    position: 'relative',
    '& .circle-background,& .circle-progress': {
      fill: 'none',
    },

    '& .circle-background': {
      stroke: neutral[100],
    },

    '& .circle-progress': {
      stroke: '#33A4E1',
      'stroke-linecap': 'round',
      'stroke-linejoin': 'round',
      transition: '0.2s',
    },

    '& .circle-text': {
      fontSize: '0.5rem',
      fontWeight: 'bold',
      fill: neutral[500],
      transition: '0.2s',
    },
    '& span': {
      color: neutral[500],
      transition: '0.2s',
      fontWeight: 'bold',
      fontSize: '0.75rem',
      marginTop: 10,
    },
  },
  closeBtn: {
    position: 'absolute',
    right: -5,
    top: -10,
    '&:hover': {
      backgroundColor: 'unset',
      '& #jasmijn_web_ico_clear_01-2': {
        fill: neutral[800],
      },
    },
    '& #jasmijn_web_ico_clear_01-2': {
      fill: neutral[500],
    },
  },
  image: {
    width: 90,
    height: 90,
    backgroundSize: 'cover',
    borderRadius: 15,
  },
}))
