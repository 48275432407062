import { Box } from '@material-ui/core'
import React from 'react'
import useStyle from './CircularProgressBar.styles'
import { byteConvertor } from 'utils/numbers'
import {CloseChip as ChipClose} from '@sur-ui/icons'
import SurIconButton from 'components/SurIconButton'
import { ICircularProgressBarProps } from '../SurFileUploader.types'

const CircularProgressBar: React.FC<ICircularProgressBarProps> = ({
  id,
  loaded,
  total,
  sqSize,
  strokeWidth,
  url,
  defaultImageAddress,
  onCancelDrops,
}) => {
  const classes = useStyle()
  if (url) {
    const urlType = url.match(/[^.]+$/g)?.[0]?.match(/(png|jp(e*)g|svg|gif)$/)

    return (
      <div
        className={classes.image}
        style={{
          backgroundImage: `url('${urlType ? url : defaultImageAddress}')`,
        }}
      />
    )
  }
  const value = loaded && total && ((loaded * 100) / total).toFixed(0)
  // Size of the enclosing square
  // SVG centers the stroke width on the radius, subtract out so circle fits in square
  const radius = (sqSize - strokeWidth) / 2
  // Enclose cicle in a circumscribing square
  const viewBox = `0 0 ${sqSize} ${sqSize}`
  // Arc length at 100% coverage is the circle circumference
  const dashArray = radius * Math.PI * 2
  // Scale 100% coverage overlay with the actual percent
  const dashOffset = value && dashArray - (dashArray * +value) / 100

  return (
    <Box component="div" className={classes.root}>
      <SurIconButton
        className={classes.closeBtn}
        variant="text"
        color="inherit"
        disableRipple
        onClick={() => onCancelDrops(id)}
        type="button"
      >
        <ChipClose />
      </SurIconButton>
      <svg width={sqSize} height={sqSize} viewBox={viewBox}>
        <circle
          className="circle-background"
          cx={sqSize / 2}
          cy={sqSize / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
        />
        <circle
          className="circle-progress"
          cx={sqSize / 2}
          cy={sqSize / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
          // Start progress marker at 12 O'Clock
          transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
          style={{
            strokeDasharray: dashArray,
            strokeDashoffset: dashOffset,
          }}
        />
        <g className="circle-uploader" transform="translate(26.137 23.201)">
          <path
            id="jasmijn_web_ico_upload_01-2"
            data-name="jasmijn_web_ico_upload_01"
            d="M20013.025,18247.475a3.614,3.614,0,0,1-3.613-3.621v-3.094a1.547,1.547,0,1,1,3.094,0v3.094a.514.514,0,0,0,.52.52h13.387a.512.512,0,0,0,.508-.52v-3.094a1.547,1.547,0,1,1,3.094,0v3.094a3.617,3.617,0,0,1-3.6,3.621Zm16.438-12.4a1.547,1.547,0,0,1,0-3.094h2.336a9.434,9.434,0,0,0,.582-18.855,1.553,1.553,0,0,1-1.316-.875,12.568,12.568,0,0,0-23.762,3.5,1.548,1.548,0,0,1-1.535,1.34,7.447,7.447,0,1,0-.156,14.891h4.324a1.547,1.547,0,0,1,0,3.094h-4.324a10.543,10.543,0,0,1-1.133-21.031,15.668,15.668,0,0,1,28.977-3.922,12.525,12.525,0,0,1-1.656,24.953Z"
            transform="translate(-19994.865 -18199.797)"
            fill="#809eb0"
          />
          <path
            id="jasmijn_web_ico_upload_01-3"
            data-name="jasmijn_web_ico_upload_01"
            d="M3.588,13.217V4.105L2.041,5.656a1.179,1.179,0,0,1-1.687,0,1.2,1.2,0,0,1,0-1.7l3.59-3.6a1.179,1.179,0,0,1,1.687,0l3.59,3.6a1.2,1.2,0,0,1,0,1.7,1.186,1.186,0,0,1-1.693,0L5.978,4.1v9.115a1.195,1.195,0,1,1-2.39,0Z"
            transform="translate(20.068 25.854)"
            fill="#809eb0"
          />
          <text
            className="circle-text"
            x="50%"
            y="50%"
            dy=".3em"
            textAnchor="middle"
            transform="translate(-24.068 -31.854)"
          >
            {value ? `${value}%` : 'Waiting...'}
          </text>
        </g>
      </svg>
      {loaded && total && (
        <span>
          {byteConvertor(loaded)} / {byteConvertor(total)}
        </span>
      )}
    </Box>
  )
}

export default CircularProgressBar
