import { lighten } from '@material-ui/core'
import { makeFloraMaidStyles } from 'layout/FloraMaidTheme'

interface ISurExpansionStyleProps {
  hasSpacing?: boolean
}

export default makeFloraMaidStyles<ISurExpansionStyleProps>(
  ({ palette }) => ({
    root: {
      boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
      '&.Mui-disabled': {
        backgroundColor: palette.neutral[50],
      },
      '& .MuiCollapse-container .MuiCollapse-wrapper': {
        backgroundColor: lighten(palette.neutral[50], 0.5),
        // color: palette.neutral[700],
        // backgroundColor: palette.neutral[50],
        padding: 24,
      },

      '& .MuiExpansionPanelSummary-root': {
        '& .MuiExpansionPanelSummary-expandIcon': {
          marginRight: 'unset',
        },
        '& .MuiExpansionPanelSummary-content': {
          '& > label.MuiFormControlLabel-root': {
            width: '100%',
            '& > label': {
              margin: 'unset',
            },
          },
        },
      },
      '&.Mui-expanded': {
        margin: ({ hasSpacing }) => (hasSpacing ? '16px 0' : 0),
        '&::before': {
          opacity: ({ hasSpacing }) => (hasSpacing ? 0 : 1),
        },
      },
    },
  }),
  { name: 'SurExpansion' },
)
