import React, { FC, ReactNode } from 'react'
import { Radio, RadioGroup, RadioGroupProps } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import SurError from 'components/SurError'

type labelPlacement = 'start' | 'end' | 'top' | 'bottom'
export interface ISurRadioProps
  extends Pick<RadioGroupProps, 'value' | 'name'> {
  labelPlacement?: labelPlacement
  options: { value: string | number; label: string; disabled?: boolean }[]
  color?: 'primary' | 'secondary'
  onChange: (value: any) => void
  error?: ReactNode
  orientation?: 'vertical' | 'horizontal'
  'data-testid'?: string
}

const SurRadio: FC<ISurRadioProps> = ({
  onChange,
  name,
  value: val,
  color,
  labelPlacement,
  options,
  error,
  orientation,
  'data-testid': dataCy,
}) => (
  <RadioGroup
    style={{
      position: 'relative',
      marginBottom: 25,
      flexDirection: orientation === 'horizontal' ? 'row' : 'column',
    }}
    {...{ name, value: val, onChange, 'data-testid': dataCy }}
  >
    {options.map((option) => (
      <FormControlLabel
        data-testid="SurRadioLabel"
        key={`${option.value}`}
        control={<Radio color={color} data-testid="SurRadioItem" />}
        {...{ labelPlacement }}
        {...option}
      />
    ))}
    <SurError {...{ error }} />
  </RadioGroup>
)

SurRadio.defaultProps = { color: 'primary', labelPlacement: 'start' }
SurRadio.displayName = 'SurRadio'

export default SurRadio
