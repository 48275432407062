import SurAutocomplete from 'components/SurAutocomplete'
import { find } from 'ramda'
import React, { useMemo } from 'react'
import { ISurDataGridPaginationProps } from '../SurDataGrid.types'
import { FormikConfig } from 'formik'
import SurForm from 'components/SurForm'
import { SurTextFormField } from 'components/FormFields'

const SurDataGridPaginationDetail = ({
  pageSize,
  currentPage,
  total,
  labelDisplayedRows,
  labelPerPage,
  pageSizes,
  onChangePageSize,
  setPageSize,
  gotoPage,
}: Omit<
  ISurDataGridPaginationProps,
  'onChangePage' | 'hideArrow' | 'hidePageRange' | 'hideDetail'
>) => {
  const from = pageSize! * (currentPage! - 1) + 1
  const to =
    pageSize! * currentPage! > total! ? total! : pageSize! * currentPage!

  const data = useMemo(
    () => pageSizes?.map((item) => ({ label: `${item}`, value: item })),
    [pageSizes],
  )

  const AutoCompleteValue =
    data && find((item: any) => item.value === pageSize)(data)

  const initialValues = {
    goto: undefined,
  }
  const onSubmit: FormikConfig<typeof initialValues>['onSubmit'] = (
    values,
    // actions,
  ) => {
    gotoPage!(+(values.goto ?? 1) - 1)
  }

  return (
    <div className="actions">
      {total! > pageSize! && (
        <>
          Go To:
          <SurForm {...{ initialValues, onSubmit }} validateOnChange>
            {() => <SurTextFormField name="goto" />}
          </SurForm>
        </>
      )}
      {labelDisplayedRows!({ from, to, total: total!, page: currentPage! })}
      <div className="per-page">
        <label>{labelPerPage}</label>
        <SurAutocomplete
          data={data as any[]}
          id="controlled-demo"
          value={AutoCompleteValue}
          inputProps={{ disabled: true, value: AutoCompleteValue.value }}
          options={{
            labelExp: 'label',
            valueExp: 'value',
            hasAutoComplete: false,
          }}
          onChange={(newValue: any) => {
            onChangePageSize!(newValue.value)
            setPageSize!(newValue.value)
          }}
        />
      </div>
    </div>
  )
}

export default SurDataGridPaginationDetail
