import { Box, Typography } from '@material-ui/core'
import animationData from 'assets/animations/miniLoading.json'
import SurLoading from 'components/SurLoading'
import React, { FC } from 'react'
import { useSurSearchContext } from './SurSearch.context'
import useStyle from './SurSearch.style'
import SurSearchItem from './SurSearchItem'

const SurSearchPopper: FC = () => {
  const classes = useStyle()
  const { data, loading, history, labelExp } = useSurSearchContext()
  return (
    <Box data-testid="SurSearchPopper">
      {loading && (
        <>
          <SurLoading
            {...{ animationData }}
            lable="Loading..."
            width={35}
            height={35}
            className={classes.loading}
          />
          <Typography component="hr" className={classes.devider} />
        </>
      )}
      {data.length > 0 ? (
        <>
          <Box className={classes.groupedOptions}>
            {data.map((option) => (
              <SurSearchItem key={option[labelExp]} {...{ option }} />
            ))}
          </Box>
          <Typography component="hr" className={classes.devider} />
        </>
      ) : null}
      {history.length > 0 && (
        <Box className={classes.groupedOptions}>
          {history.map((option) => (
            <SurSearchItem
              key={option[labelExp]}
              isHistory
              {...{
                option,
              }}
            />
          ))}
        </Box>
      )}
    </Box>
  )
}

export default SurSearchPopper
