import { useMemo } from 'react'
import format from 'date-fns/format'
import { getDays, getWeekdayLabels } from './useMonth.utils'
import { UseMonthProps, UseMonthResult } from '../DatePicker.types'

export const dayLabelFormatFn = (date: Date) => format(date, 'dd')
export const weekdayLabelFormatFn = (date: Date) => format(date, 'EEE')
export const monthLabelFormatFn = (date: Date) => format(date, 'MMMM')
export const yearLabelFormatFn = (date: Date) => format(date, 'yyyy')

export const useMonth = ({
  year,
  month,
  firstDayOfWeek = 1,
  dayLabelFormat = dayLabelFormatFn,
  weekdayLabelFormat = weekdayLabelFormatFn,
  monthLabelFormat = monthLabelFormatFn,
  yearLabelFormat = yearLabelFormatFn,
}: UseMonthProps): UseMonthResult => {
  const days = useMemo(
    () => getDays({ year, month, firstDayOfWeek, dayLabelFormat }),
    [year, month, firstDayOfWeek, dayLabelFormat],
  )
  const weekdayLabels = useMemo(
    () => getWeekdayLabels({ firstDayOfWeek, weekdayLabelFormat }),
    [firstDayOfWeek, weekdayLabelFormat],
  )

  return {
    days,
    weekdayLabels,
    monthLabel: monthLabelFormat(new Date(year, month)),
    yearLabel: yearLabelFormat(new Date(year, month)),
  }
}
