import addDays from 'date-fns/addDays'
import eachDay from 'date-fns/eachDayOfInterval'
import endOfMonth from 'date-fns/endOfMonth'
import endOfWeek from 'date-fns/endOfWeek'
import format from 'date-fns/format'
import getDay from 'date-fns/getDay'
import startOfMonth from 'date-fns/startOfMonth'
import startOfWeek from 'date-fns/startOfWeek'
import {
  GetDaysProps,
  CalendarDay,
  GetWeekdayLabelsProps,
} from '../DatePicker.types'

export const getWeekdayLabels = ({
  firstDayOfWeek = 1,
  weekdayLabelFormat = (date: Date) => format(date, 'iiiiii'),
}: GetWeekdayLabelsProps = {}) => {
  const now = new Date()
  const arr = eachDay({
    start: addDays(startOfWeek(now), firstDayOfWeek),
    end: addDays(endOfWeek(now), firstDayOfWeek),
  })
  return arr.reduce((array: any, date) => {
    array.push(weekdayLabelFormat(date))
    return array
  }, [])
}

export const getDays = ({
  year,
  month,
  firstDayOfWeek = 1,
  dayLabelFormat = (datec: Date) => format(datec, 'dd'),
}: GetDaysProps): CalendarDay[] => {
  const date = new Date(year, month)

  const monthStart = startOfMonth(date)
  const monthStartDay = getDay(monthStart)
  const monthEnd = endOfMonth(date)

  const prevMonthDays = Array.from(
    Array(
      monthStartDay >= firstDayOfWeek
        ? monthStartDay - firstDayOfWeek
        : 6 - firstDayOfWeek + monthStartDay + 1,
    ).keys(),
  ).fill(0)
  const days = eachDay({ start: monthStart, end: monthEnd }).map((datec) => ({
    date: datec,
    dayLabel: dayLabelFormat(datec),
  }))

  return [...prevMonthDays, ...days]
}
