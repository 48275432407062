import * as React from 'react'

const TreePlus = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g id="TreeExpandPlus" transform="translate(-1100 -128)">
      <g
        id="box"
        transform="translate(1100 128)"
        fill="#fff"
        stroke="#809eb0"
        stroke-width="1"
      >
        <rect width="24" height="24" rx="3" stroke="none" />
        <rect x="0.5" y="0.5" width="23" height="23" rx="2.5" fill="none" />
      </g>
      <path
        id="jasmijn_web_ico_plus_02"
        d="M20115.668,18752.668v-5.332h-5.332a1.334,1.334,0,1,1,0-2.668h5.332v-5.332a1.334,1.334,0,1,1,2.668,0v5.332h5.332a1.334,1.334,0,0,1,0,2.668h-5.332v5.332a1.334,1.334,0,1,1-2.668,0Z"
        transform="translate(-19005 -18606)"
        fill="#809eb0"
      />
      <rect
        id="container"
        width="16"
        height="16"
        transform="translate(1104 132)"
        fill="none"
      />
      <rect
        id="jasmijn_web_ico_mines_01"
        width="16"
        height="2.667"
        rx="1.333"
        transform="translate(1104 138.666)"
        fill="#809eb0"
      />
    </g>
  </svg>
)

const MemoSvgComponent = React.memo(TreePlus)
export default MemoSvgComponent
