import * as React from 'react'

const SubmitTick = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    id="jasmijn_web_ico_ok_02"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <rect id="container" width="24" height="24" fill="none" />
    <path
      id="jasmijn_web_ico_ok_02-2"
      data-name="jasmijn_web_ico_ok_02"
      d="M7.636,68,1.909,62.277,0,64.186l7.636,7.636L24,55.459,22.091,53.55Z"
      transform="translate(0 -50.55)"
      fill="#52646f"
    />
  </svg>
)

const MemoSvgComponent = React.memo(SubmitTick)
export default MemoSvgComponent
