import * as React from 'react'

const TreeMines = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g id="TreeExpandMines" transform="translate(-1100 -128)">
      <g
        id="box"
        transform="translate(1100 128)"
        fill="#fff"
        stroke="#809eb0"
        stroke-width="1"
      >
        <rect width="24" height="24" rx="3" stroke="none" />
        <rect x="0.5" y="0.5" width="23" height="23" rx="2.5" fill="none" />
      </g>
      <rect
        id="container"
        width="16"
        height="16"
        transform="translate(1104 132)"
        fill="none"
      />
      <rect
        id="jasmijn_web_ico_mines_01"
        width="16"
        height="2.667"
        rx="1.333"
        transform="translate(1104 138.666)"
        fill="#809eb0"
      />
    </g>
  </svg>
)

const MemoSvgComponent = React.memo(TreeMines)
export default MemoSvgComponent
