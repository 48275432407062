import { Typography } from '@material-ui/core'
import LoadingAnimation from 'assets/animations/loading2.json'
import classNames from 'classnames'
import React from 'react'
import Lottie from 'react-lottie'
import useStyle from './SurLoading.style'

export interface ISurLoadingProps {
  isLoading?: boolean
  lable?: string
  speed?: number
  animationData?: any
  width?: number
  height?: number
  className?: string
  orientation?: 'horizontal' | 'vertical'
}

const SurLoading: React.FC<ISurLoadingProps> = ({
  lable,
  speed,
  animationData,
  width,
  height,
  className,
  children,
  orientation,
}) => {
  const classes = useStyle({ orientation })
  const defaultLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData ?? LoadingAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }
  return (
    <div className={classNames(classes.root, className)}>
      {children ? (
        children
      ) : (
        <>
          <Lottie
            options={defaultLottieOptions}
            speed={speed ?? 1.2}
            width={width ?? 200}
            height={height ?? 130}
            isClickToPauseDisabled
            isPaused={false}
          />
          <Typography component="h6">{lable}</Typography>
        </>
      )}
    </div>
  )
}

SurLoading.displayName = 'SurLoading'

export default SurLoading
