import React, { FC, useCallback, useState } from 'react'
import type { ISurDataGridActionsCell } from './SurDataGrid.types'
import { objectHasEmptyProperty } from 'utils/objectChecker'
import SurIconButton from 'components/SurIconButton'
import {GridSubmit as GridSubmitIcon} from '@sur-ui/icons'
import {GridCancel as GridCancelIcon} from '@sur-ui/icons'
import {GridEdit as GridEditIcon} from '@sur-ui/icons'
import {GridDelete as GridDeleteIcon} from '@sur-ui/icons'
import SurDialog from 'components/SurDialog'
import produce from 'immer'

const SurDataGridActionsCell: FC<ISurDataGridActionsCell> = ({
  idExp,
  row,
  editedRow,
  setEditedRow,
  customActions,
  onRowChange,
  onEdit,
  onDelete,
  // phrases,
  isInlineEdit,
  classes,
}) => {
  const changeEdit = useCallback(
    (item: any) => {
      if (isInlineEdit) {
        setEditedRow && setEditedRow(item)
      } else {
        onEdit(item)
      }
    },
    [onEdit, setEditedRow, isInlineEdit],
  )
  const handleEdit = () => {
    changeEdit(row?.original)
  }

  const handleDelete = () => {
    onDelete([(row.original as any)[idExp]])
  }

  // NOTE: Notice TS of course complaining about `editable` being missing
  // if (!column.editable || !editedRow?.id) return cell.value;

  const isEdited =
    isInlineEdit && editedRow?.[idExp] === (row.original as any)[idExp]

  const handleSubmit = useCallback(
    (data: any) => {
      if (data.isAdd) {
        const { [idExp]: _, isAdd, ...addRow } = data
        onRowChange(addRow, 'add')
      } else {
        onRowChange(data, 'edit')
      }
      changeEdit(undefined)
    },
    [idExp, onRowChange, changeEdit],
  )

  const handleDiscard = () => {
    changeEdit(undefined)
  }

  return (
    <div className={classes.root}>
      {isEdited ? (
        <IsEditActionsComponent
          disabled={!objectHasEmptyProperty(editedRow)}
          onSubmit={() => {
            handleSubmit(editedRow)
          }}
          onDiscard={handleDiscard}
        />
      ) : (
        <IsNotEditActionsComponent
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
      )}
      {customActions?.(editedRow, isEdited!)}
    </div>
  )
}

const IsEditActionsComponent = ({ disabled, onSubmit, onDiscard }: any) => {
  const [dialogState, setDialogState] = useState({
    isOpen: false,
    title: '',
    content: '',
    type: undefined,
    onAction: () => {},
  })
  return (
    <>
      <ConfirmDialog state={dialogState} onStateChange={setDialogState} />
      <SurIconButton
        {...{ disabled }}
        variant="text"
        onClick={() => {
          setDialogState(
            produce((draft) => {
              draft.isOpen = true
              draft.title = 'Submit'
              draft.content = 'Are you sure to submit changes?'
              draft.type = 'success' as any
              draft.onAction = onSubmit
            }),
          )
        }}
      >
        <GridSubmitIcon />
      </SurIconButton>
      <SurIconButton
        variant="text"
        onClick={() => {
          setDialogState(
            produce((draft) => {
              draft.isOpen = true
              draft.title = 'Cancel'
              draft.content = 'Are you sure to cancel changes?'
              draft.type = 'error' as any
              draft.onAction = onDiscard
            }),
          )
        }}
      >
        <GridCancelIcon />
      </SurIconButton>
    </>
  )
}
const IsNotEditActionsComponent = ({ onEdit, onDelete }: any) => {
  const [dialogState, setDialogState] = useState({
    isOpen: false,
    title: '',
    content: '',
    type: undefined,
    onAction: () => {},
  })

  return (
    <>
      <ConfirmDialog state={dialogState} onStateChange={setDialogState} />
      <SurIconButton name="DataGridEditBurron" variant="text" onClick={onEdit}>
        <GridEditIcon />
      </SurIconButton>
      <SurIconButton
        variant="text"
        name="DataGridDeleteBurron"
        onClick={() =>
          setDialogState(
            produce((draft) => {
              draft.isOpen = true
              draft.title = 'Delete'
              draft.content = 'Are you sure to delete row?'
              draft.type = 'error' as any
              draft.onAction = onDelete
            }),
          )
        }
      >
        <GridDeleteIcon />
      </SurIconButton>
    </>
  )
}

const ConfirmDialog = ({ state, onStateChange }: any) => (
  <SurDialog
    title={state.title}
    content={state.content}
    open={state.isOpen}
    onClose={() => {
      onStateChange(
        produce((draft) => {
          draft.isOpen = false
          draft.title = ''
        }),
      )
    }}
    onCancel={() => {
      onStateChange(
        produce((draft) => {
          draft.isOpen = false
          draft.content = ''
        }),
      )
    }}
    onConfirm={() => {
      onStateChange(
        produce((draft) => {
          draft.isOpen = false
          draft.type = undefined
        }),
      )
      state.onAction()
    }}
    type={state.type ? state.type : 'info'}
    okText="OK"
    cancelText="Cancel"
  />
)

export default SurDataGridActionsCell
