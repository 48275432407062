import React, { createContext, FC, useContext } from 'react'
import { ISurUploaderProps } from './SurFileUploader.types'

export const datepickerContextDefaultValue: ISurUploaderProps = {
  defaultImageAddress:
    'https://legionstories.com/wp-content/themes/fox/images/placeholder.jpg',
  onCancelDrops: () => {},
  onUpload: () => new Promise(() => {}),
}
const SurFileUploaderContext = createContext<ISurUploaderProps>(
  datepickerContextDefaultValue,
)
const SurFileUploaderProvider: FC<{ value: ISurUploaderProps }> = ({
  value,
  children,
}) => (
  <SurFileUploaderContext.Provider {...{ value }}>
    {children}
  </SurFileUploaderContext.Provider>
)

export const useSurFileUploaderContext = () =>
  useContext(SurFileUploaderContext)

export default SurFileUploaderProvider
