import { makeFloraMaidStyles } from 'layout/FloraMaidTheme'
import { ISurButtonVariant, ISurButtonRipple } from './SurButton'

interface IStyleProps {
  variant?: ISurButtonVariant
  ripple?: ISurButtonRipple
  classes?: any
}

export default makeFloraMaidStyles<IStyleProps>(
  ({
    palette: { neutral, secondary, primary },
    shape: { borderRadius },
    transitions: {
      easing: { easeInOut },
    },
  }) => ({
    root: {
      width: 'max-content',
      color: variant({ contained: '#fff' }),
      fontSize: '1rem',
      transition: 'all ease 0.2s',
      boxShadow: 'unset',
      backgroundPosition: ripple({ animated: 'right bottom' }),
      backgroundSize: ripple({ animated: '200% 100%' }),
      '& .MuiTouchRipple-root .MuiTouchRipple-child': { borderRadius },
      '& .MuiTouchRipple-root .MuiTouchRipple-ripple': {
        animation: ripple({
          animated: `MuiTouchRipple-keyframes-enter 550ms ${easeInOut}`,
          simple: 'unset',
        }),
      },
      '& svg path': { fill: '#fff' },
      '&:hover': {
        backgroundPosition: ripple({
          animated: 'left bottom',
          simple: 'unset',
        }),
        boxShadow: 'unset',
      },
    },

    containedNeutral: {
      backgroundImage: ripple({
        animated: `linear-gradient( to left, ${neutral?.[300]} 50%, ${neutral?.[50]} 50% )`,
        simple: neutral?.[300],
      }),
      border: variant({ outlined: `1px solid ${neutral?.[300]}` }),
      '&:hover': {
        backgroundColor: ripple({ simple: neutral?.[50] }),
      },
      '&.Mui-disabled': {
        background: neutral?.[50],
        color: neutral?.[200],
        '& svg path': { fill: neutral?.[200] },
      },
      '& .MuiTouchRipple-child': {
        backgroundColor: neutral?.[200],
        color: neutral?.[50],
      },
    },

    outlinedNeutral: {
      backgroundImage: ripple({
        animated: `linear-gradient( to left, transparent 50%, ${neutral?.[50]} 50% )`,
        simple: 'transparent',
      }),
      border: variant({ outlined: `1px solid ${neutral?.[300]}` }),
      '&:hover': { background: ripple({ simple: neutral?.[50] }) },
      '&.Mui-disabled': {
        background: 'transparent',
        color: neutral?.[100],
        borderColor: neutral?.[100],
        '& svg path': { fill: neutral?.[100] },
      },
      '& svg path': { fill: neutral?.main },
      '& .MuiTouchRipple-child': {
        backgroundColor: neutral?.[200],
        color: neutral?.[50],
      },
    },

    textNeutral: {
      backgroundImage: ripple({
        animated: `linear-gradient( to left, transparent 50%, ${neutral?.[50]} 50% )`,
        simple: 'transparent',
      }),
      '&:hover': { background: ripple({ simple: neutral?.[50] }) },
      '&.Mui-disabled': {
        background: 'transparent',
        color: neutral?.[100],
        '& svg path': { fill: neutral?.[100] },
      },
      '& svg path': { fill: neutral?.[300] },
      '& .MuiTouchRipple-child': {
        backgroundColor: neutral?.[200],
        color: neutral?.[50],
      },
    },

    containedSecondary: {
      backgroundImage: ripple({
        animated: `linear-gradient( to left, ${secondary.main} 50%, ${secondary[500]} 50% )`,
        simple: secondary.main,
      }),
      '&:hover': { background: ripple({ simple: secondary[500] }) },
      '&.Mui-disabled': {
        background: secondary[50],
        color: secondary[200],
        '& svg path': { fill: secondary[200] },
      },
      '& .MuiTouchRipple-child': {
        backgroundColor: secondary[200],
        color: secondary[500],
      },
    },

    outlinedSecondary: {
      backgroundImage: ripple({
        animated: `linear-gradient( to left, transparent 50%, ${secondary[50]} 50% )`,
        simple: 'transparent',
      }),
      border: variant({ outlined: `1px solid ${secondary.main}` }),
      '&:hover': { background: ripple({ simple: secondary[50] }) },
      '&.Mui-disabled': {
        background: 'transparent',
        color: secondary[100],
        borderColor: secondary[100],
        '& svg path': { fill: secondary[100] },
      },
      '& svg path': { fill: secondary.main },
      '& .MuiTouchRipple-child': {
        backgroundColor: secondary[200],
        color: secondary[500],
      },
    },

    textSecondary: {
      backgroundImage: ripple({
        simple: 'transparent',
        animated: `linear-gradient( to left, transparent 50%, ${secondary[50]} 50% )`,
      }),
      '&:hover': { background: ripple({ simple: secondary[50] }) },
      '&.Mui-disabled': {
        background: 'transparent',
        color: secondary[100],
        '& svg path': { fill: secondary[100] },
      },
      '& svg path': { fill: secondary.main },
      '& .MuiTouchRipple-child': {
        backgroundColor: secondary[200],
        color: secondary[500],
      },
    },

    containedPrimary: {
      backgroundImage: ripple({
        simple: primary.main,
        animated: `linear-gradient( to left, ${primary.main} 50%, ${primary[500]} 50% )`,
      }),
      border: variant({ outlined: `1px solid ${primary.main}` }),
      '&:hover': { background: ripple({ simple: primary[500] }) },
      '&.Mui-disabled': {
        background: primary[50],
        color: primary[200],
        '& svg path': { fill: primary[200] },
      },
      '& .MuiTouchRipple-child': {
        backgroundColor: primary[200],
        color: primary[500],
      },
    },
    outlinedPrimary: {
      backgroundImage: ripple({
        simple: 'transparent',
        animated: `linear-gradient( to left, transparent 50%, ${primary[50]} 50% )`,
      }),
      border: variant({ outlined: `1px solid ${primary.main}` }),
      '&:hover': { background: ripple({ simple: primary[50] }) },
      '&.Mui-disabled': {
        background: 'transparent',
        color: primary[100],
        '& svg path': { fill: primary[100] },
      },
      '& svg path': { fill: primary.main },
      '& .MuiTouchRipple-child': {
        backgroundColor: primary[200],
        color: primary[500],
      },
    },
    textPrimary: {
      backgroundImage: ripple({
        simple: 'transparent',
        animated: `linear-gradient( to left, transparent 50%, ${primary[50]} 50% )`,
      }),
      '&:hover': { background: ripple({ simple: primary[50] }) },
      '&.Mui-disabled': {
        background: 'transparent',
        color: primary[100],
        '& svg path': { fill: primary[100] },
      },
      '& svg path': { fill: primary.main },
      '& .MuiTouchRipple-child': {
        backgroundColor: primary[200],
        color: primary[500],
      },
    },
  }),
)

const selector = (objMap: any, key?: string) => {
  if (!key) {
    return undefined
  }
  return objMap[key]
}

const ripple = (obj: { [key in ISurButtonRipple]?: string }) => ({
  ripple,
}: IStyleProps) => selector(obj, ripple)

const variant = (obj: { [key in ISurButtonVariant]?: string }) => ({
  variant,
}: IStyleProps) => selector(obj, variant)
