import React, { useState, useEffect, FC } from 'react'

const SvgLoader: FC<{ src: string }> = ({ src }) => {
  const [svg, setSvg] = useState<any>(null)
  useEffect(() => {
    fetch(src)
      .then((response) => response.text())
      .then((image) => setSvg(image))
  }, [src])

  const createMarkup = () => ({ __html: svg })

  return <div dangerouslySetInnerHTML={createMarkup()} />
}

export default SvgLoader
