import Cleave from 'cleave.js/react'
import React, { FC } from 'react'
import makeStyles from './SurFormatInputBase.styles'
import { ISurFormatInputBaseProps } from './SurFormatInputBase.types'

const SurFormatInputBase: FC<ISurFormatInputBaseProps> = ({
  options,
  inputRef: ref,
  ...other
}) => {
  const { root: className } = makeStyles({})
  const { type, ...rest } = options

  return (
    <Cleave
      {...{ className, ref, ...(other as any) }}
      options={{ [type]: true, ...rest }}
    />
  )
}

SurFormatInputBase.displayName = 'SurFormatField'

export default SurFormatInputBase
