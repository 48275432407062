import * as React from 'react'

const CloseChip = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    {...props}
  >
    <g id="clear" transform="translate(-1151 -575)">
      <g id="jasmijn_web_ico_clear_01" transform="translate(1151 575)">
        <rect id="container" width="18" height="18" fill="none" />
        <path
          id="jasmijn_web_ico_clear_01-2"
          data-name="jasmijn_web_ico_clear_01"
          d="M9,0a9,9,0,1,0,9,9A9.01,9.01,0,0,0,9,0Zm3.64,11.58a.375.375,0,0,1,0,.53l-.53.53a.375.375,0,0,1-.53,0L9,10.061,6.42,12.64a.375.375,0,0,1-.53,0l-.53-.53a.375.375,0,0,1,0-.53L7.939,9,5.36,6.42a.375.375,0,0,1,0-.53l.53-.53a.375.375,0,0,1,.53,0L9,7.939l2.58-2.58a.375.375,0,0,1,.53,0l.53.53a.375.375,0,0,1,0,.53L10.061,9Z"
          fill="rgba(255,255,255,0.8)"
        />
      </g>
    </g>
  </svg>
)

const MemoSvgComponent = React.memo(CloseChip)
export default MemoSvgComponent
