import {DatePickerDay as DatePickerDayIcon} from '@sur-ui/icons'
import SurTimePicker from 'components/SurTimePicker'
import { getInputValue } from 'hooks/datePicker'
import React, { FC, useMemo } from 'react'

interface ISurDatePickerFooterValue {
  value: Date | null
  onChange(value: Date): void
  hasTime: boolean
  displayFormat: string
}
const SurDatePickerFooterValue: FC<ISurDatePickerFooterValue> = ({
  value,
  onChange,
  hasTime,
  displayFormat,
}) => {
  const dateString = useMemo(
    () => value && getInputValue(value, displayFormat, ''),
    // eslint-disable-next-line
    [+value!, displayFormat, getInputValue],
  )

  return (
    <div>
      <DatePickerDayIcon style={{ margin: '0 5px' }} />
      {dateString}
      {hasTime && <SurTimePicker is24 {...{ value, onChange }} />}
    </div>
  )
}

export default SurDatePickerFooterValue
