import * as React from 'react'

const DialogWarning = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    id="jasmijn_web_ico_attention_02"
    xmlns="http://www.w3.org/2000/svg"
    width="24.441"
    height="24"
    viewBox="0 0 24.441 24"
    {...props}
  >
    <rect id="container" width="24" height="24" fill="none" />
    <path
      id="jasmijn_web_ico_attention_02-2"
      data-name="jasmijn_web_ico_attention_02"
      d="M8308.767,4104.293a1.75,1.75,0,0,1-1.528-.886,1.76,1.76,0,0,1,0-1.772l10.232-17.75a1.762,1.762,0,0,1,3.057,0l4.337,7.525a1.081,1.081,0,1,1-1.908.989L8319,4085.5l-9.554,16.644h19.107l-1.113-1.938a1.082,1.082,0,1,1,1.806-1.152l.021-.012,1.492,2.589a1.779,1.779,0,0,1-.62,2.409,1.759,1.759,0,0,1-.908.25Zm8.646-5.133a1.313,1.313,0,1,1,1.313,1.313A1.312,1.312,0,0,1,8317.412,4099.16Zm0-3.222v-4.654a1.313,1.313,0,0,1,2.625,0v4.654a1.313,1.313,0,0,1-2.625,0Zm7.487-.318a1.241,1.241,0,1,1,1.241,1.241A1.241,1.241,0,0,1,8324.9,4095.62Z"
      transform="translate(-8306.557 -4082.043)"
      fill="#52646f"
    />
  </svg>
)

const MemoSvgComponent = React.memo(DialogWarning)
export default MemoSvgComponent
