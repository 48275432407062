import { makeFloraMaidStyles } from 'layout/FloraMaidTheme'

export default makeFloraMaidStyles(() => ({
  root: {
    display: 'flex',
    width: '100%',
    // justifyContent: 'center',
    alignItems: 'center',
    margin: '12px 0',
    '& .SurTimePickerContainer': {
      display: 'flex',
      // width: 156,
      justifyContent: 'center',
      alignItems: 'center',
      '& *': {
        fontSize: '1.125rem',
        fontFamily: 'Roboto',
      },
      '& .TimeDevider': {
        color: '#fff',
        fontSize: 18,
        margin: '0 3px',
      },
      '& > svg': {
        margin: '0 12px',
      },
    },
  },
}))
