import { makeFloraMaidStyles } from 'layout/FloraMaidTheme'

const useStyles = makeFloraMaidStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    margin: 0,
    width: 48,
    height: 38,
    marginBottom: 10,
    fontSize: '0.688rem',
    border: 0,
    color: theme.palette?.neutral?.[900],
    backgroundColor: 'transparent',
    cursor: 'pointer',
    right: 0,
    zIndex: 3,
    padding: 0,
    '& .dayChild': {
      borderRadius: 50,
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '&.today': {
        backgroundColor: theme.palette?.primary?.[50],
        '&:hover': {},
      },
      '&.dateHoliday': {
        color: theme.palette?.neutral?.[900],
        border: `1px solid ${theme.palette?.neutral?.[200]}`,
        '&:hover': {
          borderColor: theme.palette?.neutral?.[500],
        },
      },
      '&.dateDisabled': {
        color: theme.palette?.neutral?.[200],
        '&:hover': {
          color: theme.palette?.neutral?.[200],
          backgroundColor: 'transparent',
        },
      },
    },
    '&.dateSingleSelected': {
      '& .dayChild': {
        color: '#fff!important',
        backgroundColor: theme.palette?.secondary?.main,
        '&:hover': {
          color: '#fff!important',
          backgroundColor: `${theme.palette?.secondary?.main}!important`,
          border: 'unset!important',
        },
      },
    },
    '&.dateWithinHoverRange': {
      backgroundColor: theme.palette.secondary.A300,
      '& .dayChild': {
        backgroundColor: 'transparent!important',
        border: 'unset!important',
      },
    },
    '&.dateRangeSelected': {
      backgroundColor: theme.palette?.secondary?.main,
      border: 'unset!important',
      color: '#fff!important',
      '& .dayChild': {
        color: '#fff!important',
        backgroundColor: 'transparent!important',
        border: 'unset!important',
      },
      '&.isSameValues': {
        backgroundColor: 'transparent!important',
        '& .dayChild': {
          backgroundColor: `${theme.palette?.secondary?.main}!important`,
          borderRadius: 10,
        },
      },
    },
    '&.dateSelectedStart': {
      borderTopLeftRadius: 50,
      borderBottomLeftRadius: 50,
    },
    '&.dateSelectedEnd': {
      borderTopRightRadius: 50,
      borderBottomRightRadius: 50,
    },
  },
  Zoom: {
    backgroundColor: theme.palette?.secondary?.A300,
    width: '100%',
    height: '100%',
    borderRadius: 50,
    boxShadow: 'unset',
  },
  dayChild: {},
}))

export default useStyles
