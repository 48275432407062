import React, { FC, useState } from 'react'
import { Toolbar } from '@material-ui/core'
import SurIconButton from 'components/SurIconButton'
import type { ISurDataGridToolbarProps } from '../SurDataGrid.types'

import { GridTrash as GridToolbarTrashIcon } from '@sur-ui/icons'
import { GridExcel as GridToolbaExcelcon } from '@sur-ui/icons'
import { GridPrint as GridToolbarPrintIcon } from '@sur-ui/icons'
import { Add as GridToolbaAddIcon } from '@sur-ui/icons'
import { GridColumnChooser as GridToolbarColumnChooserIcon } from '@sur-ui/icons'
import { GridReferesh as GridToolbarRefershIcon } from '@sur-ui/icons'
import SurTooltip from 'components/SurTooltip'
import SurTextField from 'components/SurTextField'
import SurPopOver from 'components/SurPopOver'
import useStyle from './SurDataGridToolbar.styles'

const SurDataGridToolbar: FC<ISurDataGridToolbarProps> = ({
  numSelected,
  addHandler,
  deleteHandler,
  isAddState,
  hasSelection,
  onRefreshClick,
  onExportClick,
  onPrintClick,
  onSearchInputChange,
  // searchInputValue,
  // preGlobalFilteredRows,
  setGlobalFilter,
  globalFilter,
}) => {
  const classes = useStyle()

  // const count = preGlobalFilteredRows?.length;

  return (
    <Toolbar
      className={classes.root}
      // className={clsx(classes.root, {
      // 	[classes.highlight]: numSelected > 0,
      // })}
    >
      <SurDataGridColumnChooser
        classes={{
          root: classes.SurDataGridToolsSectionLeft,
          popper: classes.columnChooserPopper,
        }}
      />
      {/* <div className="SurDataGridGroupingSection"></div> */}
      <div className="SurDataGridToolsSection">
        <div className="SurDataGridToolsSectionMiddle">
          <SurTextField
            className="SurDataGridSearch"
            placeholder="Search here ..."
            value={onSearchInputChange ? undefined : globalFilter ?? ''}
            // disabled={normalDisabled}
            // value={}
            onClear={() => setGlobalFilter?.(undefined)}
            onChange={(e: any) => {
              if (onSearchInputChange) {
                onSearchInputChange(e.target.value)
              } else {
                setGlobalFilter && setGlobalFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
              }
            }}
          />
        </div>
        <div className="SurDataGridToolsSectionRight">
          {hasSelection && (
            <>
              <SurTooltip title="Delete" placement="bottom">
                <SurIconButton
                  variant="outlined"
                  disabled={numSelected <= 0}
                  aria-label="delete"
                  onClick={deleteHandler}
                >
                  <GridToolbarTrashIcon />
                </SurIconButton>
              </SurTooltip>
              <SurTooltip title="Export to Excel" placement="bottom">
                <SurIconButton
                  variant="outlined"
                  aria-label="excel"
                  onClick={onExportClick}
                >
                  <GridToolbaExcelcon />
                </SurIconButton>
              </SurTooltip>
              <SurTooltip title="Print" placement="bottom">
                <SurIconButton
                  variant="outlined"
                  aria-label="print"
                  onClick={onPrintClick}
                >
                  <GridToolbarPrintIcon />
                </SurIconButton>
              </SurTooltip>
            </>
          )}
          <SurTooltip title="Refresh Data" placement="bottom">
            <SurIconButton
              variant="outlined"
              aria-label="refersh"
              onClick={onRefreshClick}
            >
              <GridToolbarRefershIcon />
            </SurIconButton>
          </SurTooltip>
          <SurTooltip title="Add" placement="bottom">
            <SurIconButton
              variant="outlined"
              aria-label="add"
              disabled={isAddState}
              onClick={addHandler}
            >
              <GridToolbaAddIcon />
            </SurIconButton>
          </SurTooltip>
        </div>
      </div>
      {/* {numSelected > 0 ? (
				<Typography className={classes.title} color="inherit" variant="subtitle1">
				{numSelected} selected
				</Typography>
				) : (
					<Typography className={classes.title} variant="h6" id="tableTitle">
					Table Title
					</Typography>
				)} */}
    </Toolbar>
  )
}

const SurDataGridColumnChooser = ({
  classes,
}: {
  classes: Record<'root' | 'popper', string>
}) => {
  const [anchorEl, setAnchorEl] = useState<any>(null)
  const handleOpen = (event: React.MouseEvent<Element, MouseEvent>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => setAnchorEl(null)

  return (
    <div className={classes.root}>
      <SurTooltip title="Column Chooser" placement="bottom">
        <SurIconButton variant="outlined" aria-label="add" onClick={handleOpen}>
          <GridToolbarColumnChooserIcon />
        </SurIconButton>
      </SurTooltip>
      <SurPopOver
        id={`SurDataGridColumnCooser`}
        {...{
          anchorEl,
          onClose: handleClose,
          classes: {
            paper: classes.popper,
          },
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: -10,
          },
        }}
      >
        <div></div>
      </SurPopOver>
    </div>
  )
}

export default SurDataGridToolbar
