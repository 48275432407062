import * as React from 'react'

const GridColumnChooser = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.344"
    height="16"
    viewBox="0 0 14.344 16"
    {...props}
  >
    <path
      id="jasmijn_web_ico_columnchooser_01"
      d="M5.875,15.375A.625.625,0,0,1,5.25,16H2.5A2.5,2.5,0,0,1,0,13.5V2.5A2.5,2.5,0,0,1,2.5,0h7.684a2.5,2.5,0,0,1,2.5,2.5V8.469a.625.625,0,0,1-1.25,0V2.5a1.251,1.251,0,0,0-1.25-1.25H2.5A1.251,1.251,0,0,0,1.25,2.5v11A1.251,1.251,0,0,0,2.5,14.75H5.25A.625.625,0,0,1,5.875,15.375ZM9.559,3.75H3.122a.625.625,0,1,0,0,1.25H9.559a.625.625,0,0,0,0-1.25Zm.625,3.125a.625.625,0,0,0-.625-.625H3.122a.625.625,0,1,0,0,1.25H9.559A.625.625,0,0,0,10.184,6.875ZM3.122,8.75a.625.625,0,1,0,0,1.25H6.406a.625.625,0,0,0,0-1.25ZM14.23,13.328c-.022.031-.1.138-.144.2A8.8,8.8,0,0,1,12.7,14.949,4.286,4.286,0,0,1,10.031,16a4.286,4.286,0,0,1-2.674-1.051,8.793,8.793,0,0,1-1.381-1.424c-.047-.059-.122-.166-.144-.2a.625.625,0,0,1,0-.719c.022-.031.1-.138.144-.2a8.793,8.793,0,0,1,1.381-1.424,4.286,4.286,0,0,1,2.674-1.051A4.286,4.286,0,0,1,12.7,10.989a8.793,8.793,0,0,1,1.381,1.424c.047.059.122.166.144.2A.625.625,0,0,1,14.23,13.328Zm-1.3-.359a4.062,4.062,0,0,0-2.9-1.781,4.062,4.062,0,0,0-2.9,1.781,4.062,4.062,0,0,0,2.9,1.781A4.062,4.062,0,0,0,12.926,12.969Zm-2.864-1.187a1.188,1.188,0,1,0,1.188,1.188A1.187,1.187,0,0,0,10.063,11.781Zm0,0"
      fill="#de8165"
    />
  </svg>
)

const MemoSvgComponent = React.memo(GridColumnChooser)
export default MemoSvgComponent
