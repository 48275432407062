import React, { FC } from 'react'
import { Tabs, TabsProps } from '@material-ui/core'
import useStyle from './SurTabs.style'
import cs from 'classnames'

type IReqValue = 'centered' | 'orientation' | 'color' | 'value' | 'variant'

export interface ISurTabsProps extends Pick<TabsProps, IReqValue> {
  activeColor?: 'secondary' | 'primary'
  color: 'white' | 'secondary' | 'primary'
  classes?: ReturnType<typeof useStyle>
  onChange?: (event: React.ChangeEvent<object>, value: any) => void
}

const SurTabs: FC<ISurTabsProps> = ({
  children,
  centered,
  onChange,
  orientation,
  value,
  variant,
  classes: customClasses,
  color = 'secondary',
  activeColor = 'secondary',
}) => {
  const classes = useStyle({ color, activeColor, classes: customClasses })

  return (
    <Tabs
      {...{
        centered,
        onChange,
        orientation,
        value,
        variant,
        classes: {
          ...classes,
          root: cs(classes.root, customClasses?.root),
        },
      }}
    >
      {children}
    </Tabs>
  )
}
SurTabs.displayName = 'SurTabs'

export default SurTabs
