import { ISurDataGridPaginationProps } from '../SurDataGrid.types'
import { makeFloraMaidStyles } from 'layout/FloraMaidTheme'

const useStyle = makeFloraMaidStyles<ISurDataGridPaginationProps>((theme) => ({
  root: {
    display: 'flex',
    justifyContent: ({ hidePageRange }) =>
      hidePageRange ? 'flex-end' : 'space-between',
    width: '100%',
    height: 60,
    padding: theme.spacing(0, 2),

    '& .actions': {
      display: 'flex',
      alignItems: 'center',
      '& form': {
        '& .MuiTextField-root': {
          width: 100,
          margin: '0 10px',
        },
      },
      '& .per-page': {
        display: 'flex',
        alignItems: 'center',

        '& > label': {
          marginRight: 5,
        },
      },

      '& .seprator': {
        margin: '0 10px',
      },

      '& .MuiAutocomplete-root': {
        width: 56,
        height: 42,

        '& .SurTextField': {
          margin: 0,
        },
        '& .MuiAutocomplete-endAdornment': {
          right: 3,
        },
      },
    },

    '& .pagination': {
      alignItems: 'center',
      display: 'flex',
      '& .next-button svg': {
        transform: 'rotate(180deg)',
        width: 16,
        height: 16,
      },
      '& .prev-button svg': {
        width: 16,
        height: 16,
      },

      '& > .MuiIconButton-root': {
        marginLeft: 5,
        width: 42,
      },
    },
  },
}))

export default useStyle
