import { makeFloraMaidStyles } from 'layout/FloraMaidTheme'

export default makeFloraMaidStyles(({ palette: { neutral }, shadows }) => ({
  root: {
    flex: 1,
    width: '100%',
    '& .TextFieldContainerStory': {
      display: 'flex',
      width: '100%',
    },
    '& h5': {
      minWidth: 150,

    },
    '& .SurTextFieldWithValidation': {
      width: '100%',
    },
  },
  popper: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 35,
    width: '100%',
    listStyle: 'none',
    padding: 10,
    margin: 0,
    marginTop: -35,
    '&.isMulti': {
      marginTop: 10,
    },
    '& .SurAutoComplete-popper': {
      zIndex: 9999,
      backgroundColor: '#fff',
      borderRadius: 3,
      boxShadow: shadows[2],
      '&.SurAutoComplete-loading': {
        backgroundColor: '#fff',
        padding: 15,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
      },
    },
  },
  items: {
    padding: 7,
    transition: '0.3s all',
    cursor: 'pointer',
    '&:hover': { backgroundColor: neutral?.[50] },
  },
  popupIcon: { width: 25, height: 25, borderRadius: 50 },
}))
