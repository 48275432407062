import React, { FC } from 'react'
import { IconButton, IconButtonProps } from '@material-ui/core'
import makeStyles from './SurIconButton.style'
import { ISurButtonVariant } from '../SurButton/SurButton'
import cs from 'classnames'

export interface ISurIconButton extends IconButtonProps {
  variant?: ISurButtonVariant
  className?: string | undefined
  children?: React.ReactNode
  shape?: 'circle' | 'square'
  hasNeutral?: boolean
}

const selectNeutralVariant = (variant?: ISurButtonVariant, clss?: any) => {
  switch (variant) {
    case 'contained': {
      return clss.containedNeutral
    }
    case 'outlined': {
      return clss.outlinedNeutral
    }
    case 'text': {
      return clss.textNeutral
    }

    default:
      return clss.containedNeutral
  }
}

const SurIconButton: FC<ISurIconButton> = ({
  variant,
  hasNeutral,
  shape,
  className,
  color,
  children,
  ...props
}) => {
  const classes = makeStyles({ variant, shape })
  return (
    <IconButton
      type="submit"
      className={cs(
        'SurIconButton',
        className,
        hasNeutral && selectNeutralVariant(variant, classes),
      )}
      color={hasNeutral ? undefined : color}
      classes={{
        root: classes.root,
        colorSecondary: classes.colorSecondary,
        colorPrimary: classes.colorPrimary,
      }}
      {...props}
    >
      {children}
    </IconButton>
  )
}

SurIconButton.displayName = 'SurIconButton'
SurIconButton.defaultProps = {
  variant: 'contained',
  color: 'secondary',
  shape: 'square',
}

export default SurIconButton
