import * as React from 'react'

const GridSubmit = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="42"
    viewBox="0 0 42 42"
    {...props}
  >
    <g id="done" transform="translate(-252 -672)">
      <g id="cmp_web_btn_ico_03" transform="translate(252 672)">
        <g id="container" transform="translate(-730 -607)">
          <rect
            id="container-2"
            data-name="container"
            width="42"
            height="42"
            rx="3"
            transform="translate(730 607)"
            fill="none"
          />
        </g>
        <g id="ico" transform="translate(-339 -107)">
          <g id="jasmijn_web_ico_ok_03" transform="translate(352 120)">
            <rect
              id="container-3"
              data-name="container"
              width="16"
              height="16"
              transform="translate(0)"
              fill="none"
            />
            <path
              id="jasmijn_web_ico_ok_01"
              d="M7.242,17.2a1.858,1.858,0,0,1-2.627,0L.544,13.127A1.858,1.858,0,0,1,3.172,10.5L5.6,12.925a.47.47,0,0,0,.664,0l6.567-6.567a1.858,1.858,0,1,1,2.627,2.627Z"
              transform="translate(0 -3.814)"
              fill="#de8165"
            />
          </g>
          <g
            id="jasmijn_web_ico_ok_03-2"
            data-name="jasmijn_web_ico_ok_03"
            transform="translate(352 120)"
          >
            <rect
              id="container-4"
              data-name="container"
              width="16"
              height="16"
              transform="translate(0)"
              fill="none"
            />
            <path
              id="jasmijn_web_ico_ok_01-2"
              data-name="jasmijn_web_ico_ok_01"
              d="M7.242,17.2a1.858,1.858,0,0,1-2.627,0L.544,13.127A1.858,1.858,0,0,1,3.172,10.5L5.6,12.925a.47.47,0,0,0,.664,0l6.567-6.567a1.858,1.858,0,1,1,2.627,2.627Z"
              transform="translate(0 -3.814)"
              fill="#de8165"
            />
          </g>
          <g
            id="jasmijn_web_ico_ok_03-3"
            data-name="jasmijn_web_ico_ok_03"
            transform="translate(352 120)"
          >
            <rect
              id="container-5"
              data-name="container"
              width="16"
              height="16"
              transform="translate(0)"
              fill="none"
            />
            <path
              id="jasmijn_web_ico_ok_01-3"
              data-name="jasmijn_web_ico_ok_01"
              d="M7.242,17.2a1.858,1.858,0,0,1-2.627,0L.544,13.127A1.858,1.858,0,0,1,3.172,10.5L5.6,12.925a.47.47,0,0,0,.664,0l6.567-6.567a1.858,1.858,0,1,1,2.627,2.627Z"
              transform="translate(0 -3.814)"
              fill="#de8165"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const MemoSvgComponent = React.memo(GridSubmit)
export default MemoSvgComponent
