import React, { FC } from 'react'
import { Avatar, AvatarProps } from '@material-ui/core'

import useStyles from './SurAvatar.styles'
import classNames from 'classnames'
import {Avatar as Icon} from '@sur-ui/icons'

export type ISurAvatarSizes = 'small' | 'medium' | 'large'

type ICustomAvatarProps =
  | 'alt'
  | 'src'
  | 'variant'
  | 'style'
  | 'onClick'
  | 'classes'

export interface ISurAvatarProps extends Pick<AvatarProps, ICustomAvatarProps> {
  size?: ISurAvatarSizes
}

const SurAvatar: FC<ISurAvatarProps> = ({
  children,
  alt,
  size,
  src,
  variant,
  style,
  onClick,
  classes,
}) => {
  const customClasses = useStyles({ size })
  return (
    <Avatar
      {...{
        alt,
        src,
        variant,
        style,
        onClick,
      }}
      classes={{ root: classNames(customClasses.root, classes?.root) }}
    >
      {children || src ? children : <Icon />}
    </Avatar>
  )
}

SurAvatar.defaultProps = { variant: 'circle' }

export default SurAvatar
