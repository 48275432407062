import * as React from 'react'

const Avatar = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    id="jasmijn_web_ico_user_01"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 24 24"
    {...props}
  >
    <rect id="container" width="24" height="24" fill="none" />
    <path
      id="jasmijn_web_ico_user_01-2"
      data-name="jasmijn_web_ico_user_01"
      d="M20676,18796a12,12,0,1,1,12,12A12.013,12.013,0,0,1,20676,18796Zm4.75,7.576a10.448,10.448,0,0,0,14.5,0,17.862,17.862,0,0,0-14.5,0Zm15.551-1.172a10.5,10.5,0,1,0-16.6,0,19.371,19.371,0,0,1,16.6,0Zm-12.8-7.9a4.5,4.5,0,1,1,4.5,4.5A4.5,4.5,0,0,1,20683.5,18794.5Zm1.5,0a3,3,0,1,0,3-3A3,3,0,0,0,20685,18794.5Z"
      transform="translate(-20676 -18784)"
      fill="#ffffff"
    />
  </svg>
)

const MemoSvgComponent = React.memo(Avatar)
export default MemoSvgComponent
