import * as React from 'react'

const ArrowPager = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    id="jasmijn_web_ico_arrow_pager_01"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <rect id="container" width="24" height="24" fill="none" />
    <path
      id="jasmijn_web_ico_arrow_pager_01-2"
      data-name="jasmijn_web_ico_arrow_pager_01"
      d="M11739.354,10777.32l-9.672-9.671a2.333,2.333,0,0,1,0-3.3l9.672-9.669a2.328,2.328,0,0,1,3.293,3.293l-5.693,5.694h9.109a2.33,2.33,0,1,1,0,4.659h-9.109l5.693,5.693a2.33,2.33,0,0,1-3.293,3.3Z"
      transform="translate(-11727 -10754)"
      fill="#52646f"
    />
  </svg>
)

const MemoSvgComponent = React.memo(ArrowPager)
export default MemoSvgComponent
