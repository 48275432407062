import React, { FC, useState, useEffect } from 'react'
import { splitEvery, indexOf } from 'ramda'
import type { IDatePickerModal } from '../SurDatePicker.types'
import { format } from 'date-fns'
import { ButtonBase } from '@material-ui/core'
import classnames from 'classnames'

const DatePickerModal: FC<IDatePickerModal> = ({
  data,
  itemPerPage,
  selectedValue,
  isOpen,
  dataFormat,
  onChildClick,
}) => {
  const [pageIndex, setPageIndex] = useState(0)
  const newData = splitEvery(itemPerPage, data)

  useEffect(() => {
    const todayWithFormat = format(new Date(), dataFormat)
    const currentPageIndex = (yearLabel: number) => {
      const yearIndex = indexOf(yearLabel, data)
      return (yearIndex * 10) / 100
    }
    if (isOpen) {
      setPageIndex(currentPageIndex(Number(todayWithFormat)))
    }
  }, [isOpen, setPageIndex, dataFormat, data])

  const handleNextPage = () => {
    if (pageIndex < data.length) {
      setPageIndex(pageIndex + 1)
    }
  }
  const handlePrevPage = () => {
    if (pageIndex !== 0 && pageIndex <= data.length) {
      setPageIndex(pageIndex - 1)
    }
  }

  return (
    <div
      className={classnames(
        'DatePickerModalContainer',
        isOpen && 'DatePickerModalActive',
      )}
    >
      <div className="DatePickerModal">
        {newData.length > 1 && (
          <ButtonBase
            className="DatePickerModalButton"
            disabled={pageIndex === 0}
            onClick={handlePrevPage}
          >
            ...
          </ButtonBase>
        )}
        {data.length > 0 &&
          newData?.[pageIndex ?? 0].map((item, index) => (
            <ButtonBase
              key={index}
              className={classnames(
                'DatePickerModalButton',
                selectedValue === item ? 'DatePickerModalCurrentButton' : '',
              )}
              onClick={() => {
                onChildClick(item, index)
              }}
            >
              {item}
            </ButtonBase>
          ))}
        {newData.length > 1 && (
          <ButtonBase
            className="DatePickerModalButton"
            disabled={pageIndex === newData.length - 1}
            onClick={handleNextPage}
          >
            ...
          </ButtonBase>
        )}
      </div>
    </div>
  )
}

export default DatePickerModal
