import isWithinInterval from 'date-fns/isWithinInterval'
import isSameDay from 'date-fns/isSameDay'
import eachDay from 'date-fns/eachDayOfInterval'
import isBefore from 'date-fns/isBefore'
import isAfter from 'date-fns/isAfter'
import getYear from 'date-fns/getYear'
import getMonth from 'date-fns/getMonth'
import startOfToday from 'date-fns/startOfToday'
import startOfMonth from 'date-fns/startOfMonth'
import addMonths from 'date-fns/addMonths'
import format from 'date-fns/format'
import addDays from 'date-fns/addDays'
import {
  HolidayDates,
  IsDateBlockedProps,
  MonthType,
  FormatFunction,
  CanSelectRangeProps,
  IsDateHoveredProps,
} from '../DatePicker.types'
import { START_DATE, END_DATE } from './useDatepicker'

export const isDateSelected = (
  date: Date,
  startDate: Date | null,
  endDate: Date | null,
) => {
  if (!startDate) {
    return false
  }

  return isWithinInterval(date.setHours(0, 0, 0, 0), {
    start: new Date(startDate).setHours(0, 0, 0, 0),
    end: new Date(endDate ?? startDate).setHours(0, 0, 0, 0),
  })
}

export const isInUnavailableDates = (
  unavailableDates: Date[] = [],
  date: Date,
) => unavailableDates.some((_date) => isSameDay(date, _date))

export const isStartDate = (date: Date, startDate: Date | null) =>
  !!(startDate && isSameDay(date, startDate))

export const isEndDate = (date: Date, endDate: Date | null) =>
  !!(endDate && isSameDay(date, endDate))

export const isInHolidayDatesList = (
  holidayDatesList: HolidayDates[] = [],
  date: Date,
) =>
  holidayDatesList.some((item) =>
    isSameDay(new Date(date), new Date(item.date)),
  )

export const isDateBlocked = ({
  date,
  minBookingDate,
  maxBookingDate,
  isDateBlockedFn,
  startDate,
  endDate,
  minBookingDays = 1,
  unavailableDates = [],
  holidayDatesList = [],
}: IsDateBlockedProps) => {
  const compareMinDate = minBookingDate
    ? new Date(
        minBookingDate.getFullYear(),
        minBookingDate.getMonth(),
        minBookingDate.getDate(),
        0,
        0,
        0,
      )
    : minBookingDate
  const compareMaxDate = maxBookingDate
    ? new Date(
        maxBookingDate.getFullYear(),
        maxBookingDate.getMonth(),
        maxBookingDate.getDate(),
        0,
        0,
        0,
      )
    : maxBookingDate

  return !!(
    isInUnavailableDates(unavailableDates, date) ??
    isInHolidayDatesList(holidayDatesList, date) ??
    (compareMinDate && isBefore(date, compareMinDate)) ??
    (compareMaxDate && isAfter(date, compareMaxDate)) ??
    (startDate &&
      !endDate &&
      minBookingDays > 1 &&
      isWithinInterval(date, {
        start: startDate,
        end: addDays(startDate, minBookingDays - 2),
      })) ??
    (isDateBlockedFn && isDateBlockedFn(date))
  )
}

export const isDateHovered = ({
  date,
  startDate,
  endDate,
  isDateBlocked: isDateBlockedFc,
  hoveredDate,
  minBookingDays,
  exactMinBookingDays,
}: IsDateHoveredProps) => {
  if (
    // exact min booking days
    hoveredDate &&
    minBookingDays > 1 &&
    exactMinBookingDays &&
    isWithinInterval(date, {
      start: hoveredDate,
      end: addDays(hoveredDate, minBookingDays - 1),
    })
  ) {
    return !eachDay({
      start: hoveredDate,
      end: addDays(hoveredDate, minBookingDays - 1),
    }).some((d) => isDateBlockedFc(d))
  } else if (
    // min booking days
    startDate &&
    !endDate &&
    hoveredDate &&
    isWithinInterval(date, {
      start: startDate,
      end: addDays(startDate, minBookingDays - 1),
    }) &&
    isSameDay(startDate, hoveredDate) &&
    minBookingDays > 1
  ) {
    return !eachDay({
      start: startDate,
      end: addDays(startDate, minBookingDays - 1),
    }).some((d) => isDateBlockedFc(d))
  } else if (
    // normal
    startDate &&
    !endDate &&
    hoveredDate &&
    !isBefore(hoveredDate, startDate) &&
    isWithinInterval(date, { start: startDate, end: hoveredDate })
  ) {
    return !eachDay({ start: startDate, end: hoveredDate }).some((d) =>
      isDateBlockedFc(d),
    )
  }

  return false
}

export const isFirstOrLastSelectedDate = (
  date: Date,
  startDate: Date | null,
  endDate: Date | null,
) => {
  if (startDate && isSameDay(date, startDate)) {
    return START_DATE
  } else if (endDate && isSameDay(date, endDate)) {
    return END_DATE
  }
  return null
}

export const getDateMonthAndYear = (date: Date): MonthType => {
  const today = startOfMonth(date)
  const year = getYear(today)
  const month = getMonth(today)
  return {
    year,
    month,
    date: today,
  }
}

export const getCurrentYearMonthAndDate = (): MonthType =>
  getDateMonthAndYear(startOfToday())

export const getInitialMonths = (
  numberOfMonths: number,
  startDate: Date | null,
): MonthType[] => {
  const firstMonth = startDate
    ? getDateMonthAndYear(startDate)
    : getCurrentYearMonthAndDate()
  let prevMonthDate = firstMonth.date
  let months = [firstMonth]

  if (numberOfMonths > 1) {
    months = Array.from(Array(numberOfMonths - 1).keys()).reduce(
      (m: MonthType[]) => {
        prevMonthDate = addMonths(m[m.length - 1].date, 1)
        return m.concat([getDateMonthAndYear(prevMonthDate)])
      },
      months,
    )
  }

  return months
}

export const getNextActiveMonth = (
  activeMonth: MonthType[],
  numberOfMonths: number,
  counter: number,
  step?: number,
): MonthType[] => {
  let prevMonth

  if (step) {
    prevMonth = counter > 0 ? 0 : activeMonth.length - step
  } else {
    prevMonth = counter > 0 ? activeMonth.length - 1 : 0
  }

  let prevMonthDate = activeMonth[prevMonth].date

  return Array.from(Array(numberOfMonths).keys()).reduce((m: MonthType[]) => {
    if (m.length === 0) {
      prevMonthDate = addMonths(prevMonthDate, counter)
    } else {
      prevMonthDate = addMonths(prevMonthDate, counter >= 0 ? 1 : -1)
    }

    return counter > 0
      ? m.concat([getDateMonthAndYear(prevMonthDate)])
      : [getDateMonthAndYear(prevMonthDate)].concat(m)
  }, [])
}

export const getInputValue = (
  date: Date | null,
  displayFormat: string | FormatFunction,
  defaultValue: string,
) => {
  if (date && typeof displayFormat === 'string') {
    return format(date, displayFormat)
  } else if (date && typeof displayFormat === 'function') {
    return displayFormat(date)
  } else {
    return defaultValue
  }
}

export const canSelectRange = ({
  startDate,
  endDate,
  isDateBlocked: isDateBlockedFc,
  minBookingDays,
  exactMinBookingDays,
  minBookingDate,
  maxBookingDate,
}: CanSelectRangeProps) => {
  const isStartDateAfterOrEqualMinDate = minBookingDate
    ? !isBefore(startDate, addDays(minBookingDate, -1))
    : true
  const isStartDateBeforeOrEqualMaxDate = maxBookingDate
    ? !isAfter(addDays(startDate, minBookingDays - 1), maxBookingDate)
    : true

  if (
    startDate &&
    minBookingDays === 1 &&
    !endDate &&
    !isDateBlockedFc(startDate)
  ) {
    return true
  } else if (
    (startDate && minBookingDays > 1 && !endDate && !exactMinBookingDays) ??
    (startDate &&
      minBookingDays > 0 &&
      exactMinBookingDays &&
      isStartDateAfterOrEqualMinDate &&
      isStartDateBeforeOrEqualMaxDate) ??
    (startDate &&
      minBookingDays > 0 &&
      exactMinBookingDays &&
      !minBookingDate &&
      !maxBookingDate)
  ) {
    return !eachDay({
      start: startDate,
      end: addDays(startDate, minBookingDays - 1),
    }).some((d) => isDateBlockedFc(d))
  } else if (startDate && endDate && !exactMinBookingDays) {
    const minBookingDaysDate = addDays(startDate, minBookingDays - 1)

    if (isBefore(endDate, minBookingDaysDate)) {
      return false
    }

    return !eachDay({ start: startDate, end: endDate }).some((d) =>
      isDateBlockedFc(d),
    )
  }

  return false
}
