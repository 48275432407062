import * as React from 'react'

const Sort = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12.002"
    viewBox="0 0 12 12.002"
    {...props}
  >
    <g id="sort" transform="translate(373 699) rotate(180)">
      <g
        id="jasmijn_web_ico_sort_01"
        transform="translate(373 699) rotate(180)"
      >
        <rect
          id="container"
          width="12"
          height="12"
          transform="translate(0 0.001)"
          fill="none"
        />
        <path
          id="jasmijn_web_ico_sort_01-2"
          data-name="jasmijn_web_ico_sort_01"
          d="M20677.813,18795.5v-.5a.5.5,0,1,1,1,0v.5a.5.5,0,1,1-1,0Zm-.023-2.52v-7.236l-.916.914a.511.511,0,0,1-.725-.721l1.789-1.789a.509.509,0,0,1,.721,0l1.789,1.789a.51.51,0,1,1-.721.721l-.918-.914v7.236a.51.51,0,1,1-1.02,0Z"
          transform="translate(-20672.313 -18784)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
)

const MemoSvgComponent = React.memo(Sort)
export default MemoSvgComponent
