import {Download as DownloadIcon} from '@sur-ui/icons'
import {Upload as FileUploaderLogo} from '@sur-ui/icons'
import clsx from 'classnames'
import { replace } from 'ramda'
import React, { FC } from 'react'
import type { DropzoneState } from 'react-dropzone'
import { byteConvertor } from 'utils/numbers'
import SurButton from '../SurButton'
import CircularProgressBar from './CircularProgressBar'
// import { ISurUploaderLayout } from './SurFileUploader.types'
import { useSurFileUploaderContext } from './SurFileUploader.context'
import useStyles from './SurFileUploader.style'

const SurUploaderLayout: FC<DropzoneState> = ({
  getRootProps,
  getInputProps,
  isDragActive,
  isFileDialogActive,
}) => {
  const classes = useStyles({})
  const {
    value,
    options,
    defaultImageAddress,
    localization,
    onCancelDrops,
  } = useSurFileUploaderContext()
  const valueCount = Object.keys(value).length
  return (
    <div
      className={clsx(
        classes.root,
        options && options.className,
        'SurFileUpload',
      )}
    >
      <div
        className={clsx(
          'SurUploaderZoon',
          isDragActive || (isFileDialogActive && 'SurFileUploaderDraged'),
        )}
        {...getRootProps()}
      >
        <section data-testid="DropZoon" style={{ cursor: 'pointer' }}>
          <div className="fileUploaderContainer">
            <FileUploaderLogo
              className={
                isDragActive || isFileDialogActive
                  ? 'SurFileUploaderIconDraged'
                  : ''
              }
              width={92}
              height={92}
            />
            <span className="fileUploaderText fileUploaderDragTexts">
              {options?.dragable && (
                <span>
                  {localization?.dragAndDropFileHere ??
                    'Drag and drop file here'}
                </span>
              )}
              {options?.clickable && options?.dragable && (
                <span>{localization?.or ?? 'OR'}</span>
              )}
            </span>
            {options?.clickable && (
              <SurButton
                className={
                  isDragActive || isFileDialogActive
                    ? 'SurFileUploaderButtonDraged'
                    : ''
                }
                style={{ width: 219 }}
              >
                {localization?.browseFile ?? 'Browse file'}
              </SurButton>
            )}
            {options && !options.noIntro && options && options.accept && (
              <span className="fileUploaderText fileUploaderSupprotedTexts">
                <span>
                  {localization?.supportedFiles ?? 'Supported files'}:
                  {typeof options.accept === 'string'
                    ? replace(',', '،', options.accept)
                    : options.accept.map((acp: string) => `${acp},`)}
                </span>
                {options.maxSize && (
                  <span>
                    {localization?.sizeLimited ?? 'Size Limited'}:{' '}
                    {`${byteConvertor(options.maxSize)} `}
                  </span>
                )}
                {options.maxFiles && (
                  <span
                    style={{
                      color: valueCount === options.maxFiles ? 'red' : 'unset',
                    }}
                  >
                    {localization?.limitFile ?? 'Limit File'}:{' '}
                    {`${valueCount} / ${options.maxFiles}`}
                  </span>
                )}
              </span>
            )}
          </div>
        </section>
        <input {...getInputProps()} />
      </div>
      <div className={classes.Preview}>
        {value && (
          <div className="SurUploaderPriviewChildsContainer">
            {Object.keys(value).map((keyName) => {
              const { id, name, progress, url } = value[keyName]
              return (
                <div key={keyName} className="SurUploaderChilds">
                  <div className="progressContainer">
                    <CircularProgressBar
                      strokeWidth={10}
                      sqSize={100}
                      loaded={progress?.loaded}
                      total={progress?.total}
                      {...{
                        url,
                        defaultImageAddress,
                        id,
                        onCancelDrops,
                      }}
                    />

                    {url && (
                      <a
                        className="downloadIcon"
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <DownloadIcon />
                      </a>
                    )}
                  </div>
                  <div className="previewContent">{name}</div>
                </div>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

export default SurUploaderLayout
