import { makeFloraMaidStyles } from 'layout/FloraMaidTheme'

const useStyle = makeFloraMaidStyles(({ spacing }) => ({
  root: {
    padding: 0,
    '& .SurDataGridToolsSection': {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      '& .SurDataGridToolsSectionMiddle': {
        flex: 1,
        '& .SurDataGridSearch': {
          '& .SurTextField': {
            margin: 0,
          },
        },
      },
      '& .SurDataGridToolsSectionRight': {
        '& button': {
          marginLeft: spacing(2),
        },
      },
    },
  },
  SurDataGridToolsSectionLeft: {
    '& button': {
      marginRight: spacing(2),
    },
  },
  title: {
    flex: '1 1 100%',
  },
  columnChooserPopper: {
    width: 324,
    minHeight: 100,
    position: 'relative',
    overflow: 'inherit',
    '&::after': {
      position: 'absolute',
      content: "''",
      backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='36.4' height='46' viewBox='0 0 36.4 46'%3E%3Cdefs%3E%3Cfilter id='shape' x='0' y='0' width='36.4' height='46' filterUnits='userSpaceOnUse'%3E%3CfeOffset dy='5' input='SourceAlpha'/%3E%3CfeGaussianBlur stdDeviation='5' result='blur'/%3E%3CfeFlood flood-opacity='0.2'/%3E%3CfeComposite operator='in' in2='blur'/%3E%3CfeComposite in='SourceGraphic'/%3E%3C/filter%3E%3C/defs%3E%3Cg transform='matrix(1, 0, 0, 1, 0, 0)' filter='url(%23shape)'%3E%3Cpath id='shape-2' data-name='shape' d='M15.7.211A1.181,1.181,0,0,0,15,0H1A1.181,1.181,0,0,0,.3.211a.608.608,0,0,0-.3.5.607.607,0,0,0,.3.5l7,4.978a1.276,1.276,0,0,0,1.406,0l7-4.978a.608.608,0,0,0,.3-.5A.608.608,0,0,0,15.7.211Z' transform='translate(21.4 10) rotate(90)' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E%0A")`,
      width: 20,
      height: 30,
      left: -20,
      top: 5,
    },
  },
}))

export default useStyle
