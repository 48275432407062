import { makeFloraMaidStyles } from 'layout/FloraMaidTheme'
import { ISurLoadingProps } from './SurLoading'

interface IStyleProps extends Pick<ISurLoadingProps, 'orientation'> {}
const useStyle = makeFloraMaidStyles<IStyleProps>(
  ({ spacing }) => ({
    root: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      margin: spacing(10, 0),
      flexDirection: ({ orientation }) =>
        orientation === 'horizontal' ? 'column' : 'row',
    },
  }),
  { name: 'SurLoading' },
)

export default useStyle
