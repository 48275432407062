import * as React from 'react'

const GridEdit = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="42"
    viewBox="0 0 42 42"
    {...props}
  >
    <g id="edit" transform="translate(-252 -672)">
      <g id="cmp_web_btn_ico_03" transform="translate(252 672)">
        <g id="container" transform="translate(-730 -607)">
          <rect
            id="container-2"
            data-name="container"
            width="42"
            height="42"
            rx="3"
            transform="translate(730 607)"
            fill="none"
          />
        </g>
        <g id="ico" transform="translate(-339 -107)">
          <g id="jasmijn_web_ico_edit_01" transform="translate(352 120)">
            <rect
              id="container-3"
              data-name="container"
              width="16"
              height="16"
              transform="translate(0 0)"
              fill="none"
            />
            <path
              id="jasmijn_web_ico_edit_01-2"
              data-name="jasmijn_web_ico_edit_01"
              d="M9.95,3.959,13.2,7.229,4.966,15.508,1.713,12.238Zm5.724-.789L14.222,1.712a1.435,1.435,0,0,0-2.035,0l-1.39,1.4,3.255,3.271,1.622-1.63A1.119,1.119,0,0,0,15.674,3.17ZM.01,16.835a.371.371,0,0,0,.448.443l3.627-.884L.832,13.123Z"
              transform="translate(0 -1.289)"
              fill="#de8165"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const MemoSvgComponent = React.memo(GridEdit)
export default MemoSvgComponent
