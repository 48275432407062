import React, { forwardRef } from 'react'
import useStyles from './SurInputBase.styles'
import { InputBase, InputProps } from '@material-ui/core'

export interface ISurInput extends InputProps {
  value?: string | number
  placeholder?: string
  disabled?: boolean
  onChange?(
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ): void
}

const SurInputBase = forwardRef<any, ISurInput>(
  ({ value, onChange, placeholder, ...other }, ref) => {
    const classes = useStyles({})

    return (
      <InputBase
        disabled
        className={[classes.root, 'SurInputBase'].join(' ')}
        data-testid={`${other.id}-inputBase`}
        {...{ value, onChange, placeholder, ref, ...other }}
      />
    )
  },
)

SurInputBase.displayName = 'SurInput'

export default SurInputBase
