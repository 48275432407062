import {
  AlertClose as CloseIcon,
  AlertError as ErrorIcon,
  AlertInfo as InfoIcon,
  AlertSuccess as SuccessIcon,
  AlertWarning as WarningIcon,
} from '@sur-ui/icons'
import SurIconButton from 'components/SurIconButton'
import React, { ReactNode } from 'react'
import {
  toast,
  ToastContainer,
  ToastPosition,
  ToastProps,
} from 'react-toastify'
// import 'react-toastify/dist/ReactToastify.css' remove from nextJs
import useStyle from './SurToast.style'

type IRequiredProps =
  | 'closeOnClick'
  | 'autoClose'
  | 'delay'
  | 'onClick'
  | 'hideProgressBar'
  | 'pauseOnHover'
  | 'closeButton'

type IToastType = 'success' | 'info' | 'warn' | 'error'

export interface ISurToastProps extends Pick<ToastProps, IRequiredProps> {
  title?: React.ReactNode | string
  content?: string | React.ReactNode
  hideProgressBar?: boolean
  type?: IToastType
  textCloseButton?: string
  position?: ToastPosition
}
let classes: any = null
export const SurToastContainer = () => {
  classes = useStyle({})
  return <ToastContainer style={{ width: 450 }} />
}
export const SurToast = (
  content: string | ReactNode,
  {
    autoClose = 4000,
    textCloseButton,
    closeOnClick,
    hideProgressBar,
    pauseOnHover,
    position = 'top-right',
    type = 'info',
    title,
    onClick,
    closeButton,
  }: ISurToastProps = {},
) => {
  const getCloseButton =
    closeButton === false ? (
      false
    ) : (
      <SurIconButton
        variant="text"
        hasNeutral
        shape={textCloseButton ? 'square' : 'circle'}
      >
        {textCloseButton ? textCloseButton : <CloseIcon />}
      </SurIconButton>
    )
  toast[type](<Container title={title} content={content} type={type} />, {
    className: classes?.root,
    position,
    closeOnClick,
    autoClose,
    onClick,
    hideProgressBar,
    pauseOnHover,
    closeButton: getCloseButton,
  })
}

const IconMap: Record<IToastType, ReactNode> = {
  error: <ErrorIcon />,
  info: <InfoIcon />,
  success: <SuccessIcon />,
  warn: <WarningIcon />,
}
const Container = ({ title, content, type }: ISurToastProps) => {
  const icon = type && IconMap[type]

  return (
    <div className="body-toast" data-testid="SurToast">
      <div>{icon}</div>
      <div className="content-toast">
        {title && <label>{title}</label>}
        <p
          style={{
            marginTop: !title ? 4 : 0,
            textAlign: 'justify',
            width: 'calc(100% - 60px)',
          }}
        >
          {content}
        </p>
      </div>
    </div>
  )
}
