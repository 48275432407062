import React, { FC } from 'react'
import { useField } from 'formik'
import SurTextField, {
  ISurTextField,
} from 'components/SurTextField/SurTextField'

type IReqType = 'error' | 'value' | 'onChange' | 'onClear' | 'name'

interface ISurTextFormFieldProps extends Omit<ISurTextField, IReqType> {
  name: string
  hasClear?: boolean
}
const SurTextFormField: FC<ISurTextFormFieldProps> = ({
  hasClear,
  ...other
}) => {
  const [field, meta, { setValue }] = useField(other.name)
  return (
    <SurTextField
      {...field}
      {...other}
      error={meta.touched && meta.error}
      onClear={hasClear ? () => setValue('') : undefined}
    />
  )
}

export default SurTextFormField
