import React, { FC } from 'react'
import { useField } from 'formik'
import SurRadio, { ISurRadioProps } from 'components/SurRadio/SurRadio'

type IReqType = 'error' | 'value' | 'onChange' | 'name'

interface ISurRadioFieldProps extends Omit<ISurRadioProps, IReqType> {
  name: string
}

const SurRadioField: FC<ISurRadioFieldProps> = (props) => {
  const [field, meta] = useField(props.name)
  return <SurRadio {...props} {...field} error={meta.touched && meta.error} />
}

export default SurRadioField
