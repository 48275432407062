import * as React from 'react'

const DialogError = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    id="jasmijn_web_ico_error_01"
    xmlns="http://www.w3.org/2000/svg"
    width="24.002"
    height="24.004"
    viewBox="0 0 24.002 24.004"
    {...props}
  >
    <rect id="container" width="24" height="24" fill="none" />
    <path
      id="jasmijn_web_ico_error_01-2"
      data-name="jasmijn_web_ico_error_01"
      d="M20685.584,18807.76a11.915,11.915,0,0,1-4.293-1.807,12.033,12.033,0,0,1-3.242-3.24,11.925,11.925,0,0,1-1.807-4.291,12.106,12.106,0,0,1,0-4.838,11.892,11.892,0,0,1,.7-2.252,12.035,12.035,0,0,1,2.572-3.814,12.177,12.177,0,0,1,1.777-1.465,12.007,12.007,0,0,1,4.293-1.807,12.2,12.2,0,0,1,4.838,0,12.007,12.007,0,0,1,4.293,1.807,12.033,12.033,0,0,1,3.242,3.24,12.046,12.046,0,0,1,1.107,2.039c.154.365.35.9.473,1.291a.995.995,0,1,1-1.861.7h0a9.938,9.938,0,0,0-1.35-2.924,10.05,10.05,0,0,0-2.711-2.709,9.91,9.91,0,0,0-1.7-.924,10.028,10.028,0,0,0-1.885-.586,10.17,10.17,0,0,0-4.045,0,10.043,10.043,0,0,0-3.59,1.51,10.348,10.348,0,0,0-1.484,1.225,10.059,10.059,0,0,0-2.15,3.189,10.005,10.005,0,0,0,.926,9.512,10.11,10.11,0,0,0,1.225,1.486,10.3,10.3,0,0,0,1.484,1.225,10.063,10.063,0,0,0,1.705.924,9.838,9.838,0,0,0,1.885.584,10.075,10.075,0,0,0,4.045,0,9.881,9.881,0,0,0,1.885-.584,10.108,10.108,0,0,0,1.7-.924,10.02,10.02,0,0,0,2.711-2.711,9.915,9.915,0,0,0,.754-1.322l.014.008a.925.925,0,0,1,.086-.129.975.975,0,0,1,1.641,1.037l.006,0a12.273,12.273,0,0,1-.867,1.506,12.024,12.024,0,0,1-12.373,5.047Zm5.9-7.3-3.406-3.344-3.406,3.344a.807.807,0,0,1-1.121,0,.768.768,0,0,1,0-1.1l3.406-3.344-3.406-3.342a.768.768,0,0,1,0-1.1.8.8,0,0,1,1.121,0l3.406,3.342,3.406-3.342a.8.8,0,0,1,1.123,0,.765.765,0,0,1,0,1.1l-3.406,3.344,3.406,3.344a.768.768,0,0,1,0,1.1.8.8,0,0,1-1.123,0Zm6.691-2.594a1.089,1.089,0,0,1-.193-1.551,1.138,1.138,0,0,1,1.578-.191,1.093,1.093,0,0,1,.2,1.551,1.139,1.139,0,0,1-1.58.191Z"
      transform="translate(-20675.996 -18784.002)"
      fill="#52646f"
    />
  </svg>
)

const MemoSvgComponent = React.memo(DialogError)
export default MemoSvgComponent
