import * as React from 'react'

const Prev = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    {...props}
  >
    <g id="prv" transform="translate(-1332 -237)">
      <g id="cmp_web_btn_inline_ico_01" transform="translate(1332 237)">
        <g id="container" transform="translate(-811 -615)">
          <g id="normal" transform="translate(-14 8)">
            <rect
              id="normal-2"
              data-name="normal"
              width="28"
              height="28"
              rx="14"
              transform="translate(825 607)"
              fill="none"
            />
          </g>
        </g>
        <g id="ico" transform="translate(6 6)">
          <g id="jasmijn_web_ico_seprator_01" transform="translate(0 0)">
            <rect
              id="container-2"
              data-name="container"
              width="16"
              height="16"
              fill="none"
            />
            <path
              id="jasmijn_web_ico_seprator_01-2"
              data-name="jasmijn_web_ico_seprator_01"
              d="M15.8,8.471a.666.666,0,0,1-.943,0L8,1.609,1.138,8.471A.667.667,0,0,1,.2,7.529L7.529.2a.666.666,0,0,1,.943,0L15.8,7.529A.666.666,0,0,1,15.8,8.471Z"
              transform="translate(3.667 16) rotate(-90)"
              fill="#3f4d56"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const MemoSvgComponent = React.memo(Prev)
export default MemoSvgComponent
