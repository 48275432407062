import * as React from 'react'

const DialogInfo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    id="jasmijn_web_ico_info_02"
    xmlns="http://www.w3.org/2000/svg"
    width="24.002"
    height="24.004"
    viewBox="0 0 24.002 24.004"
    {...props}
  >
    <rect id="container" width="24" height="24" fill="none" />
    <path
      id="jasmijn_web_ico_info_02-2"
      data-name="jasmijn_web_ico_info_02"
      d="M20594.586,18665.76a12,12,0,0,1-6.07-20.24,12.19,12.19,0,0,1,1.777-1.467,12.036,12.036,0,0,1,2.039-1.105,11.911,11.911,0,0,1,2.254-.7,12.115,12.115,0,0,1,4.84,0,12,12,0,0,1,4.293,1.8,12.279,12.279,0,0,1,1.775,1.467,11.97,11.97,0,0,1,2.572,3.813c.156.365.35.9.473,1.291a.994.994,0,1,1-1.859.7h0a10.027,10.027,0,0,0-1.352-2.924,10.116,10.116,0,0,0-1.225-1.484,10.257,10.257,0,0,0-1.486-1.225,9.838,9.838,0,0,0-1.7-.924,9.984,9.984,0,0,0-1.885-.586,10.17,10.17,0,0,0-4.045,0,10.014,10.014,0,0,0-3.588,1.51,10.05,10.05,0,0,0-2.711,2.709,10.016,10.016,0,0,0,0,11.219,10.05,10.05,0,0,0,2.711,2.709,10.014,10.014,0,0,0,3.588,1.51,10.17,10.17,0,0,0,4.045,0,10.014,10.014,0,0,0,3.588-1.51,10.213,10.213,0,0,0,1.486-1.225,10.094,10.094,0,0,0,1.225-1.484,10.3,10.3,0,0,0,.756-1.324l.014.008a1.122,1.122,0,0,1,.084-.129.976.976,0,0,1,1.643,1.037l0,0a11.874,11.874,0,0,1-.865,1.506,12.027,12.027,0,0,1-12.375,5.047Zm1.3-8.252v-4.141a1.19,1.19,0,0,1,2.379,0v4.141a1.19,1.19,0,0,1-2.379,0Zm11.3-1.641a1.091,1.091,0,0,1-.193-1.551,1.139,1.139,0,0,1,1.58-.189,1.092,1.092,0,0,1,.193,1.551,1.141,1.141,0,0,1-1.58.189Zm-11.3-5.367a1.19,1.19,0,1,1,1.189,1.168A1.178,1.178,0,0,1,20595.883,18650.5Z"
      transform="translate(-20584.998 -18642.002)"
      fill="#52646f"
    />
  </svg>
)

const MemoSvgComponent = React.memo(DialogInfo)
export default MemoSvgComponent
