import React, { FC } from 'react'
import { useField } from 'formik'
import { ISurAutocomplete } from 'components/SurAutocomplete/SurAutocomplete.types'
import SurAutocomplete from 'components/SurAutocomplete'

type IReqType = 'error' | 'value' | 'onChange'

interface ISurAutoCompleteFieldProps extends Omit<ISurAutocomplete, IReqType> {
  hasClear?: boolean
  name: string
  onCustomChange?(
    value: any,
    callBack: (value: any, shouldValidate?: boolean | undefined) => void,
  ): void
}
const SurAutoCompleteField: FC<ISurAutoCompleteFieldProps> = ({
  hasClear,
  inputProps,
  onCustomChange,
  ...other
}) => {
  const [field, meta, { setValue }] = useField(other.name)
  const inputProperties = inputProps

  return (
    <SurAutocomplete
      {...field}
      {...other}
      id={other.id}
      inputProps={{
        error: meta.touched && meta.error,
        ...inputProperties,
        onClear: () => hasClear && setValue(meta.initialValue),
      }}
      onChange={(value) => {
        if (!onCustomChange) {
          setValue(value)
        }
        onCustomChange && onCustomChange(value, setValue)
      }}
      data-testid={field.name}
    />
  )
}

export default SurAutoCompleteField
