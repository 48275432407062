import { makeFloraMaidStyles } from 'layout/FloraMaidTheme'

interface IStyleProps {
  isFocused?: boolean
  color?: 'black' | 'white' | string
}

export default makeFloraMaidStyles<IStyleProps>(
  ({ palette: { primary } }) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: 46,
      height: 76,
      '& .pickerButtonContainer': {
        height: 12,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& button': {
          display: ({ isFocused: isInside }) => (isInside ? 'block' : 'none'),
          padding: 0,
          border: 'unset',
          height: '100%',
          lineHeight: 0,
          overflowY: 'hidden',
          flex: 1,
          backgroundColor: 'transparent',
          transition: '0.5s all',
        },
      },
      '& .currentContainer': {
        transition: '300ms all',
        backgroundColor: ({ isFocused: isInside }) =>
          isInside ? primary?.[700] : 'transparent',
        boxShadow: ({ isFocused: isInside }) =>
          isInside && '0 0 20px rgba(0,0,0,0.2)',
        borderRadius: 3,
        margin: '4px 0',
        width: 46,
        height: 46,
        '& .counter': {
          display: 'inline-flex',
          position: 'relative',
          justifyContent: 'center',
          flex: 1,
          borderRadius: 3,
          width: '100%',
          '& input,& span': {
            width: '100%',
            height: 46,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            color: ({ color }) =>
              color
                ? color === 'black'
                  ? '#000'
                  : color === 'white'
                    ? '#fff'
                    : color
                : '#000',
            transition: '0.5s all',
          },
          '& input': {
            '&:focus': {
              color: '#fff',
              outlineWidth: 0,
            },
            '&::-webkit-outer-spin-button,&::-webkit-inner-spin-button': {
              '-webkit-appearance': 'none!important',
              margin: 0,
            },
          },
          '& .previous': {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '-webkit-touch-callout': 'none' /* iOS Safari */,
            '-webkit-user-select': 'none' /* Safari */,
            '-khtml-user-select': 'none' /* Konqueror HTML */,
            '-moz-user-select': 'none' /* Old versions of Firefox */,
            '-ms-user-select': 'none' /* Internet Explorer/Edge */,
            'user-select': 'none' /* Non-prefixed version, currently*/,
          },
          '&.animating-up': {
            '& .previous': {
              animation: '$fadeOutUp 300ms',
            },
            '& .current': {
              animation: '$fadeInUp 300ms',
            },
          },

          '&.animating-down': {
            '& .previous': {
              animation: '$fadeOutDown 300ms',
            },
            '& .current': {
              animation: '$fadeInDown 300ms',
            },
          },
        },
      },
    },
    [`@keyframes fadeOutUp`]: {
      from: {
        opacity: 1,
      },
      to: {
        opacity: 0,
        transform: 'translate3d(0,-1.5rem,0)',
      },
    },
    [`@keyframes fadeInUp`]: {
      from: {
        opacity: 0,
        transform: 'translate3d(0, 1.5rem,0)',
      },

      to: {
        opacity: 1,
      },
    },
    [`@keyframes fadeOutDown`]: {
      from: {
        opacity: 1,
      },

      to: {
        opacity: 0,
        transform: 'translate3d(0,1.5rem,0)',
      },
    },
    [`@keyframes fadeInDown`]: {
      from: {
        opacity: 0,
        transform: 'translate3d(0, -1.5rem,0)',
      },

      to: {
        opacity: 1,
      },
    },
  }),
  { name: 'Sur' },
)
