import React, { FC } from 'react'
import {
  dayLabelFormat as dayLabelFormatFn,
  weekdayLabelFormat as weekdayLabelFormatFn,
  monthLabelFormat as monthLabelFormatFn,
} from 'hooks/datePicker'

import SurDatePickerContainer from './components/SurDatePickerContainer'
import DatepickerContextProvider from './SurDatePicker.context'
import { ISurDatePicker } from './SurDatePicker.types.d'
import SurDatePickerFooter from './components/SurDatePickerFooter'

const SurDatepicker: FC<ISurDatePicker> = ({
  customFooter,
  phrases,
  minBookingDays = 1,
  dayLabelFormat = dayLabelFormatFn,
  weekdayLabelFormat = weekdayLabelFormatFn,
  monthLabelFormat = monthLabelFormatFn,
  mode = 'single',
  displayFormat = 'MM/dd/yyyy',
  isDateBlocked = () => false,
  unavailableDates = [],
  holidayDatesList = [],
  ...rest
}) => {
  const exactMinBookingDays = !['range-time', 'range'].includes(mode)
  const hasTime = ['single-time', 'range-time'].includes(mode)
  return (
    <DatepickerContextProvider
      {...{
        phrases,
        minBookingDays,
        isDateBlocked,
        exactMinBookingDays,
        unavailableDates,
        holidayDatesList,
        dayLabelFormat,
        weekdayLabelFormat,
        monthLabelFormat,
        hasTime,
        ...rest,
      }}
    >
      <SurDatePickerContainer {...{ phrases }} />
      <SurDatePickerFooter
        {...{ displayFormat, customFooter, holidayDatesList }}
      />
    </DatepickerContextProvider>
  )
}

export default SurDatepicker
