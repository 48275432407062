import { makeFloraMaidStyles } from 'layout/FloraMaidTheme'

export default makeFloraMaidStyles(
  ({ palette: { neutral, primary }, spacing }) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
    },
    container: {
      position: 'relative',
      minWidth: 400,
      width: '100%',
      overflow: 'hidden',
      borderRadius: 2,
      backgroundColor: '#fff',
      border: `1px solid ${neutral?.[500]}`,
    },
    inputContainer: {
      display: 'flex',
      alignItems: 'center',
      borderRadius: 2,
    },
    inputBase: {
      width: '100%',
      height: 42,
      padding: '0 16px',
      borderRadius: 5,
      border: 'unset',
      '&:active': {
        outline: 'unset',
      },
      '&:focus': {
        outline: 'unset',
      },
      '&:hover': {
        outline: 'unset',
      },
    },
    inputInput: {
      fontSize: '0.875rem',
      '&::placeholder': {
        color: `${neutral?.[500]}`,
      },
    },
    listContainer: {
      marginBottom: '36px',
    },
    groupedOptions: {
      overflowY: 'auto',
      maxHeight: 296,
    },
    groupedOptionsItem: {
      listStyle: 'none',
      padding: '0',
      margin: '0',
      paddingLeft: '36px',
      height: 42,
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      justifyContent: 'space-between',
      userSelect: 'none',
      '&:hover': {
        backgroundColor: neutral?.[50],
      },
    },
    devider: {
      border: 'none',
      borderTop: `1px dashed ${primary?.[50]}`,
      color: '#fff',
      backgroundColor: '#fff',
      height: 1,
      width: '95%',
      margin: 0,
    },
    itemLabel: {
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        marginRight: spacing(2),
      },
    },
    searchBtnRoot: {
      minWidth: 18,
      minHeight: 18,
      borderRadius: 99,
      margin: '0 5px',
      padding: 5,
    },
    loading: {
      height: 42,
      margin: '0!important',
      flexDirection: 'row',
      alignItems: 'center!important',
      justifyContent: 'flex-start!important',
      paddingLeft: 28,
      '& div': {
        margin: '0!important',
      },
      '& p': {
        fontSize: '0.875rem',
      },
    },
  }),
  { name: 'SurSearch' },
)
