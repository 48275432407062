import * as React from 'react'

const DatePickerDay = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    id="jasmijn_web_ico_calendar_02"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    {...props}
  >
    <rect
      id="container"
      width="15.999"
      height="15.999"
      transform="translate(0.001 0.001)"
      fill="none"
    />
    <path
      id="jasmijn_web_ico_calendar_02-2"
      data-name="jasmijn_web_ico_calendar_02"
      d="M20677.877,18800a1.876,1.876,0,0,1-1.873-1.875v-11a1.875,1.875,0,0,1,1.873-1.875h.75V18784h1.25v1.25h8.25V18784h1.25v1.25h.75a1.877,1.877,0,0,1,1.875,1.875v11a1.878,1.878,0,0,1-1.875,1.875Zm-.623-1.875a.623.623,0,0,0,.623.625h12.25a.624.624,0,0,0,.625-.625v-8.25h-13.5Zm0-11v1.5h13.5v-1.5a.623.623,0,0,0-.625-.625h-.75v1.25h-1.25v-1.25h-8.25v1.25h-1.25v-1.25h-.75A.621.621,0,0,0,20677.254,18787.129Zm8.623,10.311v-1.25h1.25v1.25Zm-2.5,0v-1.25h1.25v1.25Zm-2.5,0v-1.25h1.25v1.25Zm-2.5,0v-1.25h1.25v1.25Zm10-2.5v-1.25h1.25v1.25Zm-2.5,0v-1.25h1.25v1.25Zm-2.5,0v-1.25h1.25v1.25Zm-2.5,0v-1.25h1.25v1.25Zm-2.5,0v-1.25h1.25v1.25Zm10-2.5v-1.25h1.25v1.25Zm-2.5,0v-1.25h1.25v1.25Zm-2.5,0v-1.25h1.25v1.25Zm-2.5,0v-1.25h1.25v1.25Zm-2.5,0v-1.25h1.25v1.25Z"
      transform="translate(-20676.004 -18784.004)"
      fill="#fff"
    />
  </svg>
)

const MemoSvgComponent = React.memo(DatePickerDay)
export default MemoSvgComponent
