import * as React from 'react'

const GridPrint = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16.002"
    viewBox="0 0 16 16.002"
    {...props}
  >
    <g id="ico" transform="translate(-352 -120)">
      <g id="jasmijn_web_ico_print_01" transform="translate(352 120)">
        <rect id="container" width="16" height="16" fill="none" />
        <path
          id="jasmijn_web_ico_print_01-2"
          data-name="jasmijn_web_ico_print_01"
          d="M19314.635,18845a.939.939,0,0,1-.941-.937v-5.094h8.58v5.094a.937.937,0,0,1-.939.938Zm1.627-2.5a.467.467,0,0,0,.471.469h2.5a.469.469,0,0,0,0-.937h-2.5A.469.469,0,0,0,19316.262,18842.5Zm0-2a.467.467,0,0,0,.471.469h2.5a.469.469,0,0,0,0-.937h-2.5A.469.469,0,0,0,19316.262,18840.5Zm6.951.969v-2.5h.281a.469.469,0,1,0,0-.937h-11.021a.469.469,0,0,0,0,.938h.283v2.5h-.408a2.346,2.346,0,0,1-2.348-2.344v-3.75a2.35,2.35,0,0,1,2.348-2.344h11.3a2.351,2.351,0,0,1,2.35,2.344v3.75a2.347,2.347,0,0,1-2.35,2.344ZM19312,18835.5a.466.466,0,0,0,.469.469h1.5a.469.469,0,0,0,0-.937h-1.5A.469.469,0,0,0,19312,18835.5Zm.752-3.406v-.75a2.35,2.35,0,0,1,2.348-2.344h5.762a2.35,2.35,0,0,1,2.348,2.344v.75Z"
          transform="translate(-19310 -18828.994)"
          fill="#de8165"
        />
      </g>
    </g>
  </svg>
)

const MemoSvgComponent = React.memo(GridPrint)
export default MemoSvgComponent
