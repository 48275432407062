import React, { FC } from 'react'
import { Tab, TabProps } from '@material-ui/core'

import useStyle from './SurTabs.style'

type IReqProps = 'disabled' | 'classes' | 'icon' | 'label' | 'value' | 'wrapped'
export interface ISurTab extends Pick<TabProps, IReqProps> {
  hasHorizental?: boolean
}

export const SurTab: FC<ISurTab> = ({
  hasHorizental,
  icon,
  label,
  ...props
}) => {
  const classes = useStyle({ color: 'white' })

  return (
    <Tab
      icon={!hasHorizental ? icon : undefined}
      label={handleLable({
        icon,
        label,
        hasHorizental,
        style: classes.tabLabel,
      })}
      {...props}
    />
  )
}

interface Ilabel extends Pick<ISurTab, 'icon' | 'label' | 'hasHorizental'> {
  style: any
}
const handleLable = ({ icon, label, hasHorizental, style }: Ilabel) => {
  if (!hasHorizental) {
    return label
  }

  return (
    <div className={style}>
      {icon}
      <label>{label}</label>
    </div>
  )
}

export default SurTab
