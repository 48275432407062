import React from 'react'
import { useDay } from 'hooks/datePicker'
import { useSurDatePickerContext } from '../SurDatePicker.context'
import classNames from 'classnames'
import { isSameDay } from 'date-fns'
import type { ISurDatePickerDayProps } from '../SurDatePicker.types'
import { Paper, Zoom } from '@material-ui/core'
import useStyles from './SurDatePickerDay.styles'

const Day: React.FC<ISurDatePickerDayProps> = ({ dayLabel, date }) => {
  const {
    value,
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    isHoliday,
    onDateSelect,
    onDateFocus,
    onDateHover,
    hoveredDate,
    exactMinBookingDays,
  } = useSurDatePickerContext()

  const {
    isSelected,
    isSelectedStartOrEnd,
    isWithinHoverRange,
    disabledDate,
    holidayDate,
    onClick,
    onKeyDown,
    onMouseEnter,
    tabIndex,
  } = useDay({
    date,
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isHolidaied: isHoliday,
    isFirstOrLastSelectedDate,
    onDateFocus,
    onDateSelect,
    onDateHover,
  })

  const classes = useStyles()

  if (!dayLabel) {
    return <div />
  }
  const isToday = isSameDay(date, new Date())
  const isHover = () =>
    isSameDay(date, hoveredDate as Date) &&
    !(isSelected && !exactMinBookingDays)
  const isSameValues = () => {
    if (value?.startDate && value?.endDate) {
      return isSameDay(value.startDate, date) && isSameDay(value.endDate, date)
    }
  }

  return (
    <button
      className={classNames(
        classes.root,
        isSelected && exactMinBookingDays && 'dateSingleSelected',
        isSelected && !exactMinBookingDays && 'dateRangeSelected',
        !exactMinBookingDays && isSameValues() && 'isSameValues',
        !exactMinBookingDays &&
          isSelectedStartOrEnd === 'startDate' &&
          'dateSelectedStart',
        !exactMinBookingDays &&
          isSelectedStartOrEnd === 'endDate' &&
          'dateSelectedEnd',
        !exactMinBookingDays && isWithinHoverRange && 'dateWithinHoverRange',
      )}
      {...{
        onMouseEnter,
        onKeyDown,
        onClick,
        tabIndex,
      }}
      onMouseLeave={() => {
        onDateHover(null)
      }}
      type="button"
    >
      <div style={{ width: 38, height: 38, position: 'relative' }}>
        <Zoom in={isHover()}>
          <Paper classes={{ root: classes.Zoom }}></Paper>
        </Zoom>
        <div
          className={classNames(
            classes.dayChild,
            'dayChild',
            isToday && 'today',
            disabledDate && 'dateDisabled',
            holidayDate && 'dateHoliday',
          )}
        >
          {dayLabel}
        </div>
      </div>
    </button>
  )
}

export default Day
