import { makeFloraMaidStyles } from 'layout/FloraMaidTheme'

interface IStyleProps {
  color?: 'primary' | 'secondary'
}

export default makeFloraMaidStyles<IStyleProps>((theme) => ({
  root: {
    '&+label': {
      fontSize: 50,
    },
  },
  label: {
    '&:not(:first-child)': {
      paddingLeft: 4,
    },
  },
  readOnly: {
    '& span:not(:first-child)': {
      paddingLeft: 4,
    },
  },
  iconFilled: {
    '& g': {
      fill: ({ color }: IStyleProps) =>
        color === 'primary'
          ? theme.palette?.primary?.[400]
          : theme.palette?.secondary?.main,
      '& path': {
        stroke: ({ color }) =>
          color === 'primary'
            ? theme.palette?.primary?.[400]
            : theme.palette?.secondary?.main,
      },
    },
  },
  iconHover: {
    color: ({ color }) =>
      color === 'primary'
        ? theme.palette?.primary?.[400]
        : theme.palette?.secondary?.main,
  },
  iconEmpty: {
    '& g': {
      '& path': {
        stroke: theme.palette?.neutral?.[500],
        strokeWidth: 0.4,
      },
    },
  },
}))
