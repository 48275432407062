import React, { FC } from 'react'
// import { Box, Typography } from "@material-ui/core";
import { Rating } from '@material-ui/lab'
import {EmptyStar as EmptyIcon} from '@sur-ui/icons'
import {FillStar as FillIcon} from '@sur-ui/icons'
import useStyles from './SurRating.style'

export interface ISurRating {
  max?: number
  name?: string
  onChange?: (event: React.ChangeEvent<object>, value: number | null) => void
  value?: number | null
  className?: string
  defaultValue?: number
  disabled?: boolean
  readOnly?: boolean
  emptyIcon?: React.ReactNode
  fillIcon?: React.ReactNode
  size?: 'small' | 'medium' | 'large'
  hasVertical?: boolean
  label?: string | React.ReactNode
  color?: 'primary' | 'secondary'
}

const SurRating: FC<ISurRating> = ({
  onChange,
  value,
  defaultValue,
  readOnly,
  hasVertical,
  disabled,
  className,
  name,
  max,
  label,
  size,
  emptyIcon = <EmptyIcon />,
  fillIcon = <FillIcon />,
  color = 'primary',
}) => {
  SurRating.displayName = 'SurRating'
  const classes = useStyles({ color })
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: hasVertical ? 'column' : 'row',
        alignItems: hasVertical ? 'flex-start' : 'center',
        justifyContent: 'space-between',
      }}
    >
      <label
        style={{
          color: '#809EB0',
          fontFamily: 'Avenir',
          marginBottom: hasVertical ? 12 : '',
        }}
      >
        {label}
      </label>
      <Rating
        classes={classes}
        className={className}
        value={value}
        onChange={onChange}
        readOnly={readOnly}
        disabled={disabled}
        max={max}
        name={name}
        defaultValue={defaultValue}
        emptyIcon={emptyIcon}
        icon={fillIcon}
        size={size}
      />
    </div>
  )
}

export default SurRating
