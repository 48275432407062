import {
  complement,
  isEmpty,
  isNil,
  both,
  or,
  reject,
  ifElse,
  always,
} from 'ramda'

export const isNotEmpty = complement(isEmpty)
export const isNotNill = complement(isNil)

export const isNotNilAndEmpty = both(isNotNill, isNotEmpty)
export const isNotNilOrEmpty = or(isNotNill, isNotEmpty)

export const isNilAndEmpty = both(isNil, isEmpty)
export const isNilOrEmpty = or(isNil, isEmpty)

export const rejectEmptyString = ifElse(
  isNotNill,
  reject((prop: any) => {
    if (typeof prop === 'string' && prop.trim() === '') {
      return true
    }
    return false
  }),
  always(undefined),
)

export const rejectNullAndUndefined = ifElse(
  isNotNill,
  reject(isNil),
  always(undefined),
)

export const secureRandomNumber = () => {
  var array = new Uint32Array(1)
  return window.crypto.getRandomValues(array)
}
