import * as React from 'react'

const AlertSuccess = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    id="jasmijn_web_ico_ok_01"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <rect id="container" width="24" height="24" fill="none" />
    <path
      id="jasmijn_web_ico_ok_01-2"
      data-name="jasmijn_web_ico_ok_01"
      d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.853,8.561-8.646,8.646a1,1,0,0,1-1.414,0L4.147,12.561a.5.5,0,0,1,0-.707l.707-.707a.5.5,0,0,1,.707,0L9.5,15.086l7.939-7.939a.5.5,0,0,1,.707,0l.707.707A.5.5,0,0,1,18.853,8.561Z"
      fill="#46765D"
    />
  </svg>
)

const MemoSvgComponent = React.memo(AlertSuccess)
export default MemoSvgComponent
