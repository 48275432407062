import * as React from 'react'

const GridTrash = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16.001"
    viewBox="0 0 16 16.001"
    {...props}
  >
    <g id="ico" transform="translate(-352 -120)">
      <g id="jasmijn_web_ico_delete_01" transform="translate(352 120)">
        <rect
          id="container"
          width="16"
          height="16"
          transform="translate(0 0.001)"
          fill="none"
        />
        <path
          id="jasmijn_web_ico_delete_01-2"
          data-name="jasmijn_web_ico_delete_01"
          d="M12460.2,20029a.358.358,0,0,1-.357-.359v-11.516h9.73v11.516a.356.356,0,0,1-.355.359Zm6.508-2.164h1.332v-7.75h-1.332Zm-2.668,0h1.336v-7.75h-1.336Zm-2.664,0h1.332v-7.75h-1.332Zm8.2-10.15h-10.344a.36.36,0,0,1-.357-.357v-.895a.356.356,0,0,1,.357-.357h2.482v-1.725a.356.356,0,0,1,.359-.352h5.275a.354.354,0,0,1,.357.352v1.725h2.484a.355.355,0,0,1,.355.357v.895a.358.358,0,0,1-.355.357Zm-6.529-2.057v.371h3.328v-.668h-3.328Z"
          transform="translate(-12456.875 -20013.004)"
          fill="#de8165"
        />
      </g>
    </g>
  </svg>
)

const MemoSvgComponent = React.memo(GridTrash)
export default MemoSvgComponent
