import { ButtonBase } from '@material-ui/core'
import { Next as NextIcon, Prev as PrevIcon } from '@sur-ui/icons'
import React, { FC } from 'react'
import { useSurDatePickerContext } from '../SurDatePicker.context'
import { ISurDatePickerMonthHeader } from '../SurDatePicker.types'
import DatePickerModal from './SurDatePickerModal'

export const SurDatePickerMonthHeader: FC<ISurDatePickerMonthHeader> = ({
  monthLabel,
  yearLabel,
  month,
  year,
  phrases,
}) => {
  const {
    classes,
    isMonthModalOpen,
    isYearModalOpen,
    onResetModals,
    onDateSelect,
    exactMinBookingDays,
    goToPreviousMonths,
    goToNextMonths,
    onMonthModalToggle,
    onYearModalToggle,
  } = useSurDatePickerContext()

  const handleTodayClick = () => {
    onDateSelect(new Date(new Date().setHours(0, 0, 0, 0)))
    onResetModals && onResetModals()
  }

  const goToDate = (y: number, m: number) => {
    onDateSelect(new Date(Date.UTC(y, m)))
  }

  return (
    <div className={classes?.monthHeader}>
      <DatePickerModal
        data={phrases?.monthList ?? []}
        itemPerPage={12}
        isOpen={!!isMonthModalOpen}
        dataFormat="MMMM"
        selectedValue={monthLabel}
        onChildClick={(item: any, index: number) => {
          onMonthModalToggle && onMonthModalToggle()
          goToDate(year, Number(index))
        }}
      />
      <DatePickerModal
        data={phrases?.yearList ?? []}
        itemPerPage={10}
        isOpen={!!isYearModalOpen}
        dataFormat="yyyy"
        selectedValue={year}
        onChildClick={(item: any) => {
          onYearModalToggle && onYearModalToggle()
          goToDate(item, month)
        }}
      />
      <header>
        <div className="datePickerHeaderLeft">
          <ButtonBase
            className="datePickerHeaderLeftBtns"
            onClick={onMonthModalToggle}
            disableRipple
          >
            <h5>{monthLabel}</h5>
          </ButtonBase>
          <ButtonBase
            className="datePickerHeaderLeftBtns"
            onClick={onYearModalToggle}
            disableRipple
          >
            <h5>{yearLabel}</h5>
          </ButtonBase>
          {!exactMinBookingDays && (
            <>
              <ButtonBase
                className="datePickerHeaderLeftBtns"
                onClick={goToPreviousMonths}
              >
                <PrevIcon />
              </ButtonBase>
              <ButtonBase
                className="datePickerHeaderLeftBtns"
                onClick={goToNextMonths}
              >
                <NextIcon />
              </ButtonBase>
            </>
          )}
        </div>
        <div className="datePickerHeaderRight">
          <ButtonBase
            className="datePickerHeaderRightBtn"
            onClick={handleTodayClick}
          >
            Today
          </ButtonBase>
        </div>
      </header>
    </div>
  )
}

export default SurDatePickerMonthHeader
