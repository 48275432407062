import { Box, Paper } from '@material-ui/core'
import LoadingAnimation from 'assets/animations/loaing.json'
import SurLoading from 'components/SurLoading'
import React, { FC } from 'react'
import { IPaperComponentProps } from './SurAutocomplete.types'

const SurAutocompletePaper: FC<IPaperComponentProps> = ({
  params,
  classes,
  isLoading,
  multiple,
}) => {
  return (
    <Paper
      data-testid="SurAutocompletePaper"
      component="div"
      className={[classes.popper, params.className, multiple && 'isMulti'].join(
        ' ',
      )}
    >
      {isLoading ? (
        <Box
          component="div"
          className="SurAutoComplete-popper SurAutoComplete-loading"
        >
          <SurLoading
            animationData={LoadingAnimation}
            speed={1.2}
            width={200}
            height={130}
            lable="Loading..."
            orientation="horizontal"
          />
        </Box>
      ) : (
        <Box
          component="div"
          className="SurAutoComplete-popper"
          {...(params as any)}
        />
      )}
    </Paper>
  )
}

export default SurAutocompletePaper
