import { ButtonBase, TextField, TextFieldProps } from '@material-ui/core'
import { ClearText } from '@sur-ui/icons'
import { isNil } from 'ramda'
import React, { FC, ReactNode, useMemo } from 'react'
import SurFormatInputBase, {
  ISurFormatInputBaseOptions,
} from './SurFormatInputBase'
import SurInputBase from './SurInputBase'

export interface ISurTextField extends Omit<TextFieldProps, 'error' | 'type'> {
  type?: HTMLInputType
  error?: ReactNode
  maxLength?: number
  rightPrefix?: ReactNode | Element
  leftPrefix?: ReactNode | Element
  maskOptions?: ISurFormatInputBaseOptions
  onClear?: () => void
}

const SurTextField: FC<ISurTextField> = ({
  label,
  helperText: helper,
  error,
  maxLength,
  rightPrefix,
  leftPrefix,
  maskOptions,
  InputProps: InputProperties,
  inputProps: inputProperties,
  className,
  onClear,
  value,
  disabled,
  ...other
}) => {
  const valueLength = `${value}`.length
  const helperText = useMemo(
    () => (
      <>
        <span>{error ? error : helper}</span>
        {maxLength && (
          <span>
            {value ? valueLength : 0}/{maxLength}
          </span>
        )}
      </>
    ),
    [error, helper, maxLength, value, valueLength],
  )
  const InputProps = {
    inputComponent: maskOptions ? SurFormatInputBase : SurInputBase,
    endAdornment: (
      <div className="endAdornmentContainer">
        {disabled || valueLength === 0 || isNil(onClear) ? null : (
          <ButtonBase className="endAdornmentElement" onClick={onClear}>
            <ClearText />
          </ButtonBase>
        )}
        {rightPrefix}
      </div>
    ),
    startAdornment: leftPrefix && (
      <div className="SurTextFieldLeftPrefix">{leftPrefix}</div>
    ),
    ...InputProperties,
  }
  const inputProps = {
    options: maskOptions,
    ...inputProperties,
  }
  return (
    <TextField
      variant="outlined"
      {...{
        helperText,
        label,
        error: !!error,
        value,
        InputProps,
        inputProps,
        disabled,
        ...(other as any),
      }}
    />
  )
}

SurTextField.displayName = 'SurTextField'
export default SurTextField
