import React, { FC } from 'react'
import { useSurDatePickerContext } from '../SurDatePicker.context'
import { useMonth } from 'hooks/datePicker'
import { CalendarDay } from 'hooks/datePicker/DatePicker.types'
import Day from '../SurDatePickerDay'
import { SurDatePickerMonthHeader } from './SurDatePickerMonthHeader'
import { SurDatePickerWeekLabel } from './SurDatePickerWeekLabel'
import { ISurDatePickerMonth } from '../SurDatePicker.types'

const SurDatePickerMonth: FC<ISurDatePickerMonth> = ({
  month,
  year,
  phrases,
}) => {
  const {
    firstDayOfWeek,
    classes,
    dayLabelFormat,
    weekdayLabelFormat,
    monthLabelFormat,
  } = useSurDatePickerContext()

  const { days, weekdayLabels, yearLabel, monthLabel } = useMonth({
    dayLabelFormat,
    monthLabelFormat,
    weekdayLabelFormat,
    year,
    month,
    firstDayOfWeek,
  })

  return (
    <div className={classes?.month}>
      <SurDatePickerMonthHeader
        {...{
          monthLabel,
          yearLabel,
          month,
          year,
          phrases,
        }}
      />
      <main className={classes?.monthMain}>
        <div className="datePickerWeekLabelsContainer">
          {weekdayLabels.map((label: string) => (
            <SurDatePickerWeekLabel key={label} {...{ label, month }} />
          ))}
        </div>
        <div className="datePickerDaysContainer">
          {days.map((day: CalendarDay, index: number) => {
            if (typeof day === 'object') {
              return <Day date={day.date} key={index} dayLabel={day.dayLabel} />
            }

            return <div key={index} />
          })}
        </div>
      </main>
    </div>
  )
}

export default SurDatePickerMonth
