import React, { FC, useCallback } from 'react'
import { useSurDatePickerContext } from '../SurDatePicker.context'
import { ISurDatePickerFooterProps } from '../SurDatePicker.types'
import { find, propEq } from 'ramda'
import { HolidayDates } from 'hooks/datePicker/DatePicker.types'
import SurDatePickerFooterValue from './SurDatePickerFooterValue'

const SurDatePickerFooter: FC<ISurDatePickerFooterProps> = ({
  displayFormat,
  customFooter,
  holidayDatesList = [],
}) => {
  const {
    hoveredDate,
    value,
    classes,
    exactMinBookingDays,
    onDatesChange,
    hasTime,
  } = useSurDatePickerContext()

  const holidayValues: HolidayDates = find(propEq('date', hoveredDate))(
    holidayDatesList,
  ) as HolidayDates

  const handleChangeTimes = useCallback(
    (date: Date, dateFormat: 'startDate' | 'endDate') => {
      onDatesChange({
        ...value,
        [dateFormat]: date,
      })
    },
    [onDatesChange, value],
  )

  return (
    <footer className={classes?.footer}>
      <div className="datePickerFooterHolidayes">
        {holidayValues && (
          <div className="datePickerFooterHoliday">
            {typeof holidayValues?.icon === 'string' ? (
              <img
                width={20}
                height={20}
                src={hoveredDate ? holidayValues.icon : ''}
                alt=""
              />
            ) : (
              holidayValues?.icon
            )}
            {holidayValues?.text}
          </div>
        )}
      </div>
      {(value?.startDate || value?.endDate) && (
        <div className="datePickerFooterDay">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {value?.startDate && (
              <SurDatePickerFooterValue
                value={value.startDate}
                onChange={(time) => handleChangeTimes(time, 'startDate')}
                {...{ hasTime, displayFormat }}
              />
            )}
            {!exactMinBookingDays && value?.endDate && (
              <SurDatePickerFooterValue
                value={value.endDate}
                onChange={(time) => handleChangeTimes(time, 'endDate')}
                {...{ hasTime, displayFormat }}
              />
            )}
          </div>
          {customFooter}
        </div>
      )}
    </footer>
  )
}

export default SurDatePickerFooter
