import { Box, Popper, PopperProps, Typography } from '@material-ui/core'
import {
  AutocompleteGetTagProps,
  AutocompleteRenderInputParams,
  createFilterOptions,
} from '@material-ui/lab'
import { CloseChip as ChipClose, SubmitTick } from '@sur-ui/icons'
import SurCheckbox from 'components/SurCheckbox'
import SurChip from 'components/SurChip'
import SurTextField from 'components/SurTextField'
import React from 'react'
import getPathValue from 'utils/finder'
import { isNotNilAndEmpty } from 'utils/nullish'
import useStyles from './SurAutocomplete.styles'
import {
  IUseSurAutocompleteProps,
  IUseSurAutocompleteResultProps,
} from './SurAutocomplete.types'

const normalFilterOptions = (normalFilterOption: any) => normalFilterOption

const chipIconFn = (
  iconExp: string | undefined,
  value: string | undefined,
  option: any,
  labelExp: string,
): any => {
  if (iconExp && value) {
    if (!value.includes('http')) {
      return (
        <Typography
          variant="h6"
          component="h6"
          style={{ margin: '0 10px', minWidth: 'auto' }}
        >
          {value}
        </Typography>
      )
    } else {
      return (
        <Typography
          component="img"
          style={{ borderRadius: 50 }}
          src={getPathValue(option, iconExp)}
          alt={getPathValue(option, labelExp)}
        />
      )
    }
  }
  return undefined
}

const leftPrefixFn = (
  multiple: boolean | undefined = false,
  iconExp: string | undefined,
  value: any,
  getValueIcon: any,
  startAdornment: React.ReactNode,
): any => {
  return !multiple ? (
    iconExp && isNotNilAndEmpty(value) ? (
      !getValueIcon?.includes('http') ? (
        <Typography
          variant="h6"
          component="h6"
          style={{ margin: '0 10px', minWidth: 'auto' }}
        >
          {getValueIcon}
        </Typography>
      ) : (
        <Typography
          component="img"
          data-testid="SurAutocompleteLeftPrefix"
          style={{ height: 16, margin: '0 10px' }}
          src={getValueIcon}
          alt="sonbol"
        />
      )
    ) : undefined
  ) : (
    startAdornment
  )
}

const getValueIconFn = (
  value: any,
  data: any[] | undefined,
  valueExp: string,
  iconExp: string | undefined,
): any => {
  if (isNotNilAndEmpty(value)) {
    const objectValue = data?.find(
      (item) => getPathValue(item, valueExp) === getPathValue(value, valueExp),
    )
    return iconExp && getPathValue(objectValue, iconExp)
  }
  return undefined
}

const getOptionDisabledFn = (disableExp: string | undefined) => {
  return (option: any) => disableExp && getPathValue(option, disableExp)
}

const getOptionLabelFn = (labelExp: string) => {
  return (option?: object) =>
    isNotNilAndEmpty(option) && getPathValue(option, labelExp)
}

const getOptionSelectedFn = (valueExp: string) => {
  return (option: any, selectedValue: any) =>
    getPathValue(option, valueExp) === getPathValue(selectedValue, valueExp)
}

const useSurAutocomplete = ({
  options,
  inputProps,
  data,
  value,
}: IUseSurAutocompleteProps): IUseSurAutocompleteResultProps => {
  const {
    labelExp,
    iconExp,
    multiple,
    hasAutoComplete,
    valueExp,
    disableExp,
    hasCheckbox,
  } = options
  const classes = useStyles()
  const autoSelectFilterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option: any) => getPathValue(option, labelExp) as any,
  })
  const getOptionSelected = getOptionSelectedFn(valueExp)
  const getOptionLabel = getOptionLabelFn(labelExp)
  const getOptionDisabled = getOptionDisabledFn(disableExp)
  const getValueIcon: any = getValueIconFn(value, data, valueExp, iconExp)
  const PopperComponent = (params: PopperProps) => (
    <Popper
      {...params}
      className={[classes.popper, params.className].join(' ')}
    />
  )
  const renderInput = ({
    InputProps: { startAdornment, ...InputProps },
    ...params
  }: AutocompleteRenderInputParams) => (
    <SurTextField
      {...{ InputProps, ...inputProps, ...params }}
      leftPrefix={leftPrefixFn(
        multiple,
        iconExp,
        value,
        getValueIcon,
        startAdornment,
      )}
    />
  )

  const renderOption = (option: any, { selected }: any) => {
    const icon: any = iconExp && getPathValue(option, iconExp)
    const leftSide = () => {
      if (hasCheckbox) {
        return (
          <SurCheckbox
            data-testid={`${inputProps?.name}-checkBox`}
            style={{ marginRight: 8, marginBottom: 0 }}
            checked={selected}
          />
        )
      }
      return (
        <Box
          component="span"
          style={{
            visibility: !selected ? 'hidden' : 'visible',
            margin: '0 3px',
          }}
        >
          <SubmitTick width={16} style={{ margin: '0 6px' }} />
        </Box>
      )
    }
    return (
      <>
        {leftSide()}
        <Box
          component="div"
          data-testid={getPathValue(option, labelExp)}
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          {chipIconFn(iconExp, icon, option, labelExp)}
          {getPathValue(option, labelExp)}
        </Box>
      </>
    )
  }

  const renderTags = (
    tagValue: any,
    getTagProps: AutocompleteGetTagProps,
  ): React.ReactNode =>
    tagValue.map((option: any, index: number) => {
      const chipProps: any = getTagProps({ index })
      const icon = iconExp && getPathValue(option, iconExp)
      return (
        <SurChip
          icon={chipIconFn(iconExp, icon, option, labelExp)}
          key={index}
          color="primary"
          label={getPathValue(option, labelExp)}
          deleteIcon={<ChipClose data-testid="CloseChip" />}
          onDelete={option.disabled ? undefined : () => chipProps?.onDelete()}
        />
      )
    })

  const filterOptions =
    !hasAutoComplete && !multiple
      ? normalFilterOptions
      : autoSelectFilterOptions

  return {
    classes,
    filterOptions,
    getOptionSelected,
    getOptionLabel,
    getOptionDisabled,
    PopperComponent,
    renderInput,
    renderOption,
    renderTags,
  }
}

export default useSurAutocomplete
