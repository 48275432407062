import React, { ReactNode } from 'react'
import { Button, ButtonProps } from '@material-ui/core'
import cs from 'classnames'

import useStyle from './SurButton.style'

export type ISurButtonVariant = 'text' | 'outlined' | 'contained'
export type ISurButtonColor = 'primary' | 'secondary' | 'default' | 'inherit'
export type ISurButtonRipple = 'animated' | 'simple'
export interface ISurButtonProps extends ButtonProps {
  variant?: ISurButtonVariant
  color?: ISurButtonColor
  disabled?: boolean
  endIcon?: ReactNode
  startIcon?: ReactNode
  children?: ReactNode
  disableRipple?: boolean
  className?: string
  ripple?: ISurButtonRipple
  hasNeutral?: boolean
  style?: Record<string, unknown>
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const SurButton: React.FC<ISurButtonProps> = ({
  variant,
  hasNeutral,
  ripple,
  color,
  children,
  className,
  classes,
  ...props
}) => {
  const styles = useStyle({ variant, ripple, classes })
  const isNeutral = !!hasNeutral && !!variant
  const neutralClassName = styles[`${variant}Neutral`]

  return (
    <Button
      className={cs(className, {
        [neutralClassName]: isNeutral,
      })}
      color={hasNeutral ? undefined : color}
      {...{
        classes: styles,
        variant,
      }}
      {...props}
    >
      {children}
    </Button>
  )
}

SurButton.displayName = 'SurButton'
SurButton.defaultProps = {
  variant: 'contained',
  color: 'secondary',
  ripple: 'animated',
}

export default SurButton
