import { LinearProgress } from '@material-ui/core'
import React, { FC } from 'react'
import useSurPageLoadingStyle from './SurPageLoading.style'

interface ISurPageLoadingProps {
  visible: boolean
}
const SurPageLoading: FC<ISurPageLoadingProps> = ({ visible }) => {
  const classes = useSurPageLoadingStyle({ visible })

  return (
    <LinearProgress
      {...{
        classes,
        variant: 'indeterminate',
        color: 'secondary',
      }}
    />
  )
}

export default SurPageLoading
