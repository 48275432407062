import * as React from 'react'

const GridReferesh = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="15.997"
    viewBox="0 0 16 15.997"
    {...props}
  >
    <g id="ico" transform="translate(-352 -120)">
      <g id="jasmijn_web_ico_refresh_01" transform="translate(352 120)">
        <rect
          id="container"
          width="15.997"
          height="15.997"
          transform="translate(0.003)"
          fill="none"
        />
        <path
          id="jasmijn_web_ico_refresh_01-2"
          data-name="jasmijn_web_ico_refresh_01"
          d="M19315.014,18844.424a7.924,7.924,0,0,1-2.535-1.635l-1.344,1.344a.658.658,0,0,1-.939,0,.649.649,0,0,1-.2-.469V18839a.677.677,0,0,1,.668-.666h4.664a.681.681,0,0,1,.67.666.637.637,0,0,1-.2.469l-1.424,1.428a5.373,5.373,0,0,0,1.674,1.063,5.317,5.317,0,0,0,6.49-2.168,11.929,11.929,0,0,0,.551-1.219.311.311,0,0,1,.313-.238h2a.32.32,0,0,1,.234.1.344.344,0,0,1,.1.234.154.154,0,0,1-.014.074,8.109,8.109,0,0,1-2.789,4.523,7.631,7.631,0,0,1-4.979,1.734A7.776,7.776,0,0,1,19315.014,18844.424Zm5.648-8.76a.639.639,0,0,1-.469-.2.658.658,0,0,1,0-.936l1.438-1.437a5.178,5.178,0,0,0-3.635-1.426,5.3,5.3,0,0,0-4.541,2.539,13.234,13.234,0,0,0-.553,1.219.309.309,0,0,1-.312.238h-2.07a.319.319,0,0,1-.234-.1.323.323,0,0,1-.1-.234v-.074a8.136,8.136,0,0,1,2.814-4.523,7.685,7.685,0,0,1,5-1.734,7.822,7.822,0,0,1,2.955.578,8.127,8.127,0,0,1,2.551,1.629l1.355-1.344a.66.66,0,0,1,.938,0,.639.639,0,0,1,.2.469V18835a.674.674,0,0,1-.666.666Z"
          transform="translate(-19309.996 -18829)"
          fill="#de8165"
        />
      </g>
    </g>
  </svg>
)

const MemoSvgComponent = React.memo(GridReferesh)
export default MemoSvgComponent
