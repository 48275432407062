import { makeFloraMaidStyles } from 'layout/FloraMaidTheme'

const useStyle = makeFloraMaidStyles((theme) => ({
  root: {
    '&.Toastify__toast': {
      backgroundColor: '#FFFFFF',
      // color: theme.palette.neutral?.[900],
      color: '#3F4D56',
      fontFamily: 'Roboto',
      padding: '12px 12px 12px 18px',
      width: 450,
      boxShadow: '0 0 10px rgba(0,0,0,.2)',
      alignItems: 'center',

      '& .body-toast': {
        display: 'flex',
        // flexDirection: 'column'

        '& .content-toast': {
          marginLeft: 12,
          flex: 1,
          '& > label': {
            fontSize: 18,
            margin: '0 0 9px 0',
          },
          '& > p': {
            fontSize: 14,
            margin: 0,
          },
        },
      },
      '& .Toastify__progress-bar': {
        width: 6,
        height: '100%',
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
        bottom: 'unset',
        top: 0,
        transformOrigin: 'top',

        '&.Toastify__progress-bar--warning': {
          backgroundColor: '#F3AF29',
        },

        '&.Toastify__progress-bar--info': {
          backgroundColor: '#33A4E1',
        },

        '&.Toastify__progress-bar--error': {
          backgroundColor: theme.palette.error?.[600],
        },

        '&.Toastify__progress-bar--success': {
          backgroundColor: theme.palette.primary?.[500],
        },

        '&.Toastify__progress-bar--animated': {
          animation: 'Toastify__trackProgress2 linear 1 forwards',
        },
      },
      '& .SurIconButton': {
        height: 38,
        position: 'absolute',
        right: 0,
        top: 9,
      },
    },
  },
  '@global': {
    '@keyframes Toastify__trackProgress2': {
      '0%': {
        transform: 'scaleY(0)',
      },
      '100%': {
        transform: 'scaleY(1)',
      },
    },
  },
}))

export default useStyle
