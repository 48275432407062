import * as React from 'react'

const AlertClose = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22.627"
    height="22.627"
    viewBox="0 0 22.627 22.627"
    {...props}
  >
    <g id="ico" transform="translate(-939 -617.686)">
      <g
        id="jasmijn_web_ico_plus_01"
        transform="translate(950.313 617.686) rotate(45)"
      >
        <rect id="container" width="16" height="16" fill="none" />
        <path
          id="jasmijn_web_ico_plus_01-2"
          data-name="jasmijn_web_ico_plus_01"
          d="M15.2,7.2H8.8V.8A.8.8,0,0,0,7.2.8V7.2H.8a.8.8,0,0,0,0,1.6H7.2v6.4a.8.8,0,1,0,1.6,0V8.8h6.4a.8.8,0,1,0,0-1.6Zm0,0"
          fill="#52646F"
        />
      </g>
    </g>
  </svg>
)

const MemoSvgComponent = React.memo(AlertClose)
export default MemoSvgComponent
