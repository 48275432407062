import React, { FC } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@material-ui/core'
import SurButton from 'components/SurButton'
import {DialogClose as CloseIcon} from '@sur-ui/icons'
import {DialogError as ErrorIcon} from '@sur-ui/icons'
import {DialogInfo as InfoIcon} from '@sur-ui/icons'
import {DialogSuccess as SuccessIcon} from '@sur-ui/icons'
import {DialogWarning as WarningIcon} from '@sur-ui/icons'
import useStyles from './SurDialog.style'
import SurIconButton from 'components/SurIconButton'

export interface ISurDialog {
  disableBackdropClick?: boolean
  disableEscapeKeyDown?: boolean
  onClose?: () => void
  onEnter?: (node: HTMLElement, isAppearing: boolean) => void
  onExit?: (node: HTMLElement) => void
  open: boolean
  okText?: string | React.ReactNode
  cancelText?: string | React.ReactNode
  optionalText?: string | React.ReactNode
  onCancel?: () => void
  onConfirm?: () => void
  onOptional?: () => void
  type?: 'success' | 'error' | 'info' | 'warning'
  content: string | React.ReactNode
  title?: string | React.ReactNode
}

const SurDialog: FC<ISurDialog> = ({
  onEnter,
  onClose,
  onExit,
  disableBackdropClick,
  disableEscapeKeyDown,
  open,
  okText,
  cancelText,
  optionalText,
  content,
  onCancel,
  onConfirm,
  onOptional,
  type = 'info',
  title,
}) => {
  const classes = useStyles({ okText, type })

  const pickIcon = () => {
    switch (type) {
      case 'error': {
        return <ErrorIcon />
      }
      case 'success': {
        return <SuccessIcon />
      }
      case 'warning': {
        return <WarningIcon />
      }
      default: {
        return <InfoIcon />
      }
    }
  }

  return (
    <Dialog
      {...{
        open,
        onClose,
        disableBackdropClick,
        disableEscapeKeyDown,
        onEnter,
        onExit,
        classes,
      }}
      className={`${type}-SurDialog`}
    >
      <DialogTitle>
        <SurIconButton
          variant="text"
          hasNeutral
          shape="circle"
          onClick={onClose}
          data-testid="DialogCloseButton"
        >
          <CloseIcon />
        </SurIconButton>
        {pickIcon()}
        <label>{title ? title : type}</label>
      </DialogTitle>
      <DialogContent>{content}</DialogContent>
      {okText && (
        <DialogActions>
          <SurButton
            data-testid="DialogSubmitButton"
            hasNeutral
            variant="text"
            onClick={onConfirm}
          >
            {okText}
          </SurButton>
          {cancelText && (
            <SurButton
              hasNeutral
              data-testid="DialogCancelButton"
              variant="text"
              onClick={onCancel}
            >
              {cancelText}
            </SurButton>
          )}
          {optionalText && (
            <SurButton hasNeutral variant="text" onClick={onOptional}>
              {optionalText}
            </SurButton>
          )}
        </DialogActions>
      )}
    </Dialog>
  )
}

SurDialog.displayName = 'SurDialog'
export default SurDialog
