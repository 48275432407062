import * as React from 'react'

const GridMore = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="42"
    viewBox="0 0 42 42"
    {...props}
  >
    <g id="more" transform="translate(-252 -672)">
      <g id="cmp_web_btn_ico_03" transform="translate(252 672)">
        <g id="container" transform="translate(-730 -607)">
          <rect
            id="container-2"
            data-name="container"
            width="42"
            height="42"
            rx="3"
            transform="translate(730 607)"
            fill="none"
          />
        </g>
        <g id="ico" transform="translate(-339 -107)">
          <g id="jasmijn_web_ico_more_01" transform="translate(352 120)">
            <rect
              id="container-3"
              data-name="container"
              width="16"
              height="16"
              fill="none"
            />
            <circle
              id="_01"
              data-name="01"
              cx="1.333"
              cy="1.333"
              r="1.333"
              transform="translate(1.333 6.667)"
              fill="#de8165"
            />
            <circle
              id="_02"
              data-name="02"
              cx="1.333"
              cy="1.333"
              r="1.333"
              transform="translate(6.667 6.667)"
              fill="#de8165"
            />
            <circle
              id="_03"
              data-name="03"
              cx="1.333"
              cy="1.333"
              r="1.333"
              transform="translate(12 6.667)"
              fill="#de8165"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const MemoSvgComponent = React.memo(GridMore)
export default MemoSvgComponent
