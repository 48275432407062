import React, { createContext, FC, useContext } from 'react'
import { ISurSearch } from './SurSearch'

interface ISurSearchContext extends ISurSearch {
  open: boolean
  history: any[]
  // value?: any
  loading?: boolean
  data: any[]
  labelExp: string
  onOpen(): void
  onClose(): void
  onChange(value?: any): void
  onItemClick(option: any): void
  onHistoryItemRemove?(item: any, filterHistory: any[]): void
  onSubmit?(value?: any): void
}

export const defaultValue: ISurSearchContext = {
  history: [],
  data: [],
  loading: false,
  labelExp: '',
  open: false,
  onOpen: () => {},
  onClose: () => {},
  onChange: () => {},
  onItemClick: () => {},
  onHistoryItemRemove: () => {},
  onSubmit: () => {},
}
const SurSearchContext = createContext<ISurSearchContext>(defaultValue)

const SurSearchProvider: FC<ISurSearchContext> = ({ children, ...props }) => {
  return (
    <SurSearchContext.Provider value={{ ...props }}>
      {children}
    </SurSearchContext.Provider>
  )
}

export const useSurSearchContext = () => useContext(SurSearchContext)
export default SurSearchProvider
