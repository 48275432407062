import { Chip, ChipProps } from '@material-ui/core'
import {CloseChip as ChipClose} from '@sur-ui/icons'
import React from 'react'
import useStyle from './SurChip.style'

export type ISurChipProps = ChipProps
const SurChip: React.FC<ISurChipProps> = ({
  classes,
  size = 'small', // it's important to keep it this way :)
  deleteIcon = <ChipClose />,
  ...rest
}) => {
  const classNames = useStyle({ classes })

  return (
    <Chip
      data-testid="SurChipTestID"
      {...{
        ...rest,
        deleteIcon,
        size,
        classes: classNames,
      }}
    />
  )
}

SurChip.displayName = 'SurChip'

export default SurChip
