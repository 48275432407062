import React, { FC } from 'react'
import { CardHeader, MenuItem } from '@material-ui/core'
import SurPopOver from 'components/SurPopOver'
import SurIconButton from 'components/SurIconButton'
import { More as MoreIcon } from '@sur-ui/icons'
import { ISurCardHeader } from './SurCard'

export interface ISurCardHeaderComponent extends ISurCardHeader {
  className?: string
}

const SurCardHeader: FC<ISurCardHeaderComponent> = ({
  title,
  subheader,
  action,
  avatar,
  headerComponent,
  className,
}) => (
  <div className="SurCardHeaderRoot">
    {headerComponent ? (
      headerComponent
    ) : (
      <CardHeader
        avatar={avatar}
        action={
          action ? (
            <>
              <SurPopOver
                id={action.popOverProps.id}
                anchorEl={action.popOverProps.anchorEl}
                onClose={() => action.popOverProps.onAnchorElChange(null)}
                classes={{ paper: className }}
              >
                <ul>
                  {action.options.map((option) => (
                    <MenuItem
                      key={option.value}
                      onClick={() => action.optionAction(option)}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </ul>
              </SurPopOver>
              <SurIconButton
                variant="text"
                shape="circle"
                hasNeutral
                onClick={(event) =>
                  action.popOverProps.onAnchorElChange(event.currentTarget)
                }
              >
                <MoreIcon />
              </SurIconButton>
            </>
          ) : undefined
        }
        title={title}
        subheader={subheader}
      />
    )}
  </div>
)

export default SurCardHeader
