import React from 'react'

export interface ISurTabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
  classes?: {
    root: string
    content?: string
  }
}

const SurTabPanel = (props: ISurTabPanelProps) => {
  const { children, value, index, classes, ...rest } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      className={classes?.root}
      {...rest}
    >
      {value === index && <div className={classes?.content}>{children}</div>}
    </div>
  )
}

export default SurTabPanel
