import React, { memo } from 'react'
import SurIconButton from 'components/SurIconButton'
import {ArrowPager as Arrow} from '@sur-ui/icons'
import { ISurDataGridPaginationProps } from '../SurDataGrid.types'

type IReqProps =
  | 'currentPage'
  | 'hideArrow'
  | 'onChangePage'
  | 'pageSize'
  | 'total'
  | 'gotoPage'
  | 'boundaryCount'
  | 'itemsBeforeActive'
  | 'itemsAfterActive'

const SurDataGridPaginationPageRange = ({
  currentPage,
  hideArrow,
  onChangePage,
  pageSize,
  total,
  gotoPage,
  boundaryCount,
  itemsBeforeActive,
  itemsAfterActive,
}: Pick<ISurDataGridPaginationProps, IReqProps>) => {
  const items: any[] = []

  const handleArrowClick = (arrow: string) => {
    if (!gotoPage) {
      return
    }
    const lastItemShow = currentPage! + itemsAfterActive!
    const firstItemShow = currentPage! - itemsBeforeActive!
    if (arrow === 'before') {
      onChangePage!(firstItemShow - itemsAfterActive! - 2, pageSize!)
      gotoPage(firstItemShow - itemsAfterActive! - 2)
    } else {
      onChangePage!(lastItemShow + itemsBeforeActive!, pageSize!)
      gotoPage(lastItemShow + itemsBeforeActive!)
    }
  }

  const totalPage = Math.ceil(total! / pageSize!)
  for (let page = 1; page <= totalPage; page += 1) {
    if (
      page <= boundaryCount! ||
      page >= totalPage - (boundaryCount! - 1) ||
      (currentPage! + itemsAfterActive! >= page &&
        currentPage! - itemsBeforeActive! <= page) ||
      totalPage <= 4
      //||
      // (page <= 5 && currentPage! < 5) ||
      // (page >= totalPage - 4 && currentPage! > totalPage - 4)
    ) {
      items.push(
        <SurIconButton
          onClick={() => {
            onChangePage!(page, pageSize!)
            gotoPage && gotoPage(page - 1)
          }}
          color="primary"
          variant={currentPage === page ? 'contained' : 'outlined'}
          key={page.toString()}
        >
          {page}
        </SurIconButton>,
      )
    } else if (
      page === boundaryCount! + 1 ||
      page === totalPage - boundaryCount!
    ) {
      items.push(
        <SurIconButton
          color="primary"
          variant={currentPage === page ? 'contained' : 'outlined'}
          key={page.toString()}
          onClick={() =>
            handleArrowClick(page === boundaryCount! + 1 ? 'before' : 'after')
          }
        >
          ...
        </SurIconButton>,
      )
    }
  }

  return (
    <div className="pagination">
      {!hideArrow && (
        <SurIconButton
          onClick={() => {
            onChangePage &&
              onChangePage(Number(currentPage) - 1, Number(pageSize))
            gotoPage && gotoPage(Number(currentPage) - 2)
          }}
          color="primary"
          variant="outlined"
          className="prev-button"
          disabled={currentPage === 1}
        >
          <Arrow />
        </SurIconButton>
      )}
      {items}
      {!hideArrow && (
        <SurIconButton
          onClick={() => {
            onChangePage &&
              onChangePage(Number(currentPage) + 1, Number(pageSize))
            gotoPage && gotoPage(Number(currentPage))
          }}
          color="primary"
          variant="outlined"
          className="next-button"
          disabled={currentPage === Math.ceil(Number(total) / Number(pageSize))}
        >
          <Arrow />
        </SurIconButton>
      )}
    </div>
  )
}

export default memo(SurDataGridPaginationPageRange)
