import React, { FC, ReactElement, cloneElement } from 'react'
import { makeFloraMaidStyles } from 'layout/FloraMaidTheme'
import SurAvatar from '../SurAvatar/SurAvatar'

export interface ISurAvatarGroupProps {
  max?: number
  size?: 'small' | 'medium' | 'large'
  children: ReactElement[]
}

const useStyle = makeFloraMaidStyles(() => ({
  root: { display: 'flex' },
  avatar: {
    marginLeft: -8,
  },
}))
const SurAvatarGroup: FC<ISurAvatarGroupProps> = ({
  children,
  max = 3,
  size,
}) => {
  const classes = useStyle()
  const renderChilds = () =>
    children.slice(0, max).map((child, index) =>
      cloneElement(child, {
        style: {
          zIndex: children.length - index,
          marginLeft: index === 0 ? undefined : -8,
          border: '2px solid #ffffff',
        },
        size,
      }),
    )

  return (
    <div className={classes.root}>
      {renderChilds()}
      {children.length > max && (
        <SurAvatar classes={{ root: classes.avatar }} {...{ size }}>
          +{children.length - max}
        </SurAvatar>
      )}
    </div>
  )
}

SurAvatarGroup.displayName = 'SurAvatarGroup'
export default SurAvatarGroup
