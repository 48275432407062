import SurSwitch, { ISurSwitchProps } from 'components/SurSwitch/SurSwitch'
import { useField } from 'formik'
import React, { FC } from 'react'

type IReqType = 'error' | 'checked' | 'onChange' | 'name'

interface ISurSwitchFieldProps extends Omit<ISurSwitchProps, IReqType> {
  name: string
}

const SurSwitchField: FC<ISurSwitchFieldProps> = (props) => {
  const [{ value, ...field }, meta] = useField(props.name)
  return (
    <SurSwitch
      {...props}
      {...field}
      checked={value}
      error={meta.touched && meta.error}
    />
  )
}

export default SurSwitchField
