import * as React from 'react'

const InputArrowDown = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    {...props}
  >
    <g id="up" transform="translate(-1299 -332)">
      <g id="jasmijn_web_ico_arrow_01" transform="translate(1299 332)">
        <rect id="container" width="12" height="12" fill="none" />
        <path
          id="jasmijn_web_ico_arrow_01-2"
          data-name="jasmijn_web_ico_arrow_01"
          d="M11.854,117.483a.5.5,0,0,0-.707,0L6,122.629.854,117.483a.5.5,0,1,0-.707.707l5.5,5.5a.5.5,0,0,0,.707,0l5.5-5.5A.5.5,0,0,0,11.854,117.483Z"
          transform="translate(0 -114.336)"
          fill="#3f4d56"
        />
      </g>
    </g>
  </svg>
)

const MemoSvgComponent = React.memo(InputArrowDown)
export default MemoSvgComponent
