import { makeFloraMaidStyles } from 'layout/FloraMaidTheme'

interface ISurTreeViewStyleProps {
  type: 'checkable' | 'withIcon' | 'customArrow' | 'normal'
}

export default makeFloraMaidStyles<ISurTreeViewStyleProps>(
  ({ palette: { neutral } }) => ({
    root: {
      flexGrow: 1,
      maxWidth: 450,
      '& .MuiTreeItem-root': {
        '& .MuiTreeItem-iconContainer': {
          width: 20,
          marginRight: ({ type }) =>
            type === 'checkable' ? 4 : type === 'withIcon' ? 4 : 6,
          position: 'relative',
          left: ({ type }) => (type === 'checkable' ? 62 : 0),
          zIndex: 99,
        },
        '& .MuiFormControlLabel-root': {
          marginLeft: 0,
          marginRight: 0,
          color: neutral?.[500],
        },
        '&.Mui-selected > .MuiTreeItem-content': {
          '& .MuiTreeItem-label': {
            backgroundColor: ({ type }) =>
              type === 'checkable' ? 'unset' : neutral?.[100],
            '& .MuiFormControlLabel-label': {
              color: ({ type }) =>
                type === 'checkable' ? 'unset' : neutral?.[700],
            },
          },
          '& .SurTreeLabelContainer': {
            backgroundColor: neutral?.[100],
            color: neutral?.[700],
          },
        },
        '& .MuiTreeItem-label': {
          lineHeight: '42px',
          '&:hover': {
            backgroundColor: ({ type }) =>
              type === 'checkable' ? 'unset' : neutral?.[50],
          },
        },
        '& .SurTreeLabelContainer': {
          flex: 1,
          paddingLeft: 6,
          '&:hover': {
            backgroundColor: neutral?.[50],
          },
        },
        '& .MuiFormControlLabel-label::selection': {
          background: 'unset',
        },
        '& .MuiTreeItem-group': {
          position: 'relative',
          '&:after': {
            content: ({ type }) => (type === 'customArrow' ? "''" : ''),
            width: 1,
            backgroundImage: `linear-gradient(white 33%, ${neutral?.[500]} 0%)`,
            backgroundPosition: 'right',
            backgroundSize: '1px 8px',
            backgroundRepeat: 'repeat-y',
            position: 'absolute',
            left: -8,
            top: -5,
            bottom: 18,
          },
          '&:before': {
            content: ({ type }) => (type === 'customArrow' ? "''" : ''),
            width: '100%',
            height: 1,
            backgroundImage: `linear-gradient(white 33%, ${neutral?.[500]} 0%)`,
            backgroundPosition: 'left',
            backgroundSize: '8px 1px',
            backgroundRepeat: 'repeat-y',
            position: 'absolute',
            left: -7,
            // top: -5,
            bottom: 18,
          },
        },
      },
      '& .MuiTreeItem-root:focus > .MuiTreeItem-content .MuiTreeItem-label': {
        backgroundColor: 'unset',
      },
      '& .MuiTreeItem-root:focus > .MuiTreeItem-content .MuiTreeItem-label .SurTreeLabelContainer': {
        backgroundColor: neutral?.[100],
      },
      '& .treeLabel': {
        paddingLeft: 2,
        '& .SurTreeIconContainer': {
          display: 'flex',
          alignItems: 'center',
          '& .SurTreeIcon': {
            marginRight: 10,
          },
        },
        '& .SurTreeCheckBoxContainer': {
          display: 'flex',
        },
      },
      '& .treeExpanded': {
        '&:focus': {
          // backgroundColor: "red",
        },
      },
    },
    checkbox: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  }),
  { name: 'SurTreeView' },
)
