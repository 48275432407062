import { makeFloraMaidStyles } from 'layout/FloraMaidTheme'

interface ISurDialogStyleProps {
  okText?: string | React.ReactNode
  type?: 'success' | 'error' | 'info' | 'warning'
}

export default makeFloraMaidStyles<ISurDialogStyleProps>(
  ({ palette: { neutral, error, primary } }) => ({
    root: {
      '& .MuiPaper-root': {
        width: 292,
        borderBottom: '6px solid',
        borderBottomColor: ({ type }) =>
          type === 'error'
            ? error?.[900]
            : type === 'info'
              ? '#39B2F0'
              : type === 'success'
                ? primary?.[400]
                : '#F3AF15',
      },
      '& .MuiDialogTitle-root': {
        padding: '16px 12px 11px 12px',
        '& .MuiTypography-h6': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: 28,
          '& .SurIconButton': {
            position: 'absolute',
            top: 12,
            right: 12,
            width: 28,
            height: 28,
            padding: 0,
            '& svg': {
              width: 16,
              height: 16,
              transform: 'rotate(45deg)',
            },
          },

          '& > svg': {
            width: 36,
            height: 36,

            '& > path': {
              fill: ({ type }: ISurDialogStyleProps) =>
                type === 'error'
                  ? error?.[900]
                  : type === 'info'
                    ? '#39B2F0'
                    : type === 'success'
                      ? primary?.main
                      : '#F3AF15',
            },
          },
          '& > label': {
            fontSize: 18,
            fontFamily: 'Roboto',
            color: ({ type }) =>
              type === 'error'
                ? error?.[900]
                : type === 'info'
                  ? '#39B2F0'
                  : type === 'success'
                    ? primary?.main
                    : '#F3AF15',
          },
        },
      },
      '& .MuiDialogContent-root': {
        padding: ({ okText }) =>
          okText ? '0 24px 6px 24px' : '0 24px 46px 24px',
        textAlign: 'center',
        color: neutral?.[500],
      },
      '& .MuiDialogActions-root': {
        '& .SurButton': {
          color: neutral?.[900],
          fontSize: 14,
          fontFamily: 'Roboto',
          width: '100%',
          height: 28,
          marginRight: 'unset',
        },
      },
    },
  }),
  { name: 'SurDialog' },
)
