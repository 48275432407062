import * as React from 'react'

const SearchHistory = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    {...props}
  >
    <g id="ico" transform="translate(-443 -415)">
      <g id="jasmijn_web_ico_history_01" transform="translate(443 415)">
        <rect id="container" width="18" height="18" fill="none" />
        <path
          id="jasmijn_web_ico_history_01-2"
          data-name="jasmijn_web_ico_history_01"
          d="M9.45,12.75a8.4,8.4,0,0,0-7.38,4.32L0,15v5.85H5.85L3.33,18.33a6.786,6.786,0,1,1-.27,5.22H1.17a8.564,8.564,0,1,0,8.28-10.8ZM8.1,17.25v4.59l4.23,2.52.72-1.17-3.6-2.16V17.25Z"
          transform="translate(0 -12)"
          fill="#a9becb"
        />
      </g>
    </g>
  </svg>
)

const MemoSvgComponent = React.memo(SearchHistory)
export default MemoSvgComponent
