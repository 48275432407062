import React, { ReactNode, FC } from 'react'
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Collapse,
} from '@material-ui/core'

import makeStyles from './SurCard.styles'
import SurCardHeader from './SurCardHeader'
import SurCardActions from './SurCardActions'
import { isNotNilAndEmpty } from 'utils/nullish'

export interface ISurCard {
  varient?: 'default' | 'outlined'
  header?: ISurCardHeader
  actions?: ISurCardActions
  url?: string
  children?: ReactNode | Element | string
  noContentText?: string
  style?: any
}

export interface ISurCardActions {
  isExpanded?: boolean
  actionButtons?: IActionButtons[]
  collapseComponent?: ReactNode
  onExpandedChange?(isExpanded?: boolean): void
  actionsComponent?(isExpanded?: boolean): ReactNode
}
interface IActionButtons {
  content: ReactNode | Element | string
  variant?: 'text' | 'outlined' | 'contained' | undefined
  onClick(): void
}

export interface ISurCardHeader {
  title: string
  subheader?: string
  avatar?: ReactNode
  action?: {
    options: SurOption[]
    popOverProps: {
      id: string
      anchorEl: null | Element | ((element: Element) => Element)
      onAnchorElChange(prevState: any): void
    }
    optionAction(option: any): void
  }
  headerComponent?: ReactNode
}

const SurCard: FC<ISurCard> = ({
  varient,
  header,
  url,
  children,
  actions,
  noContentText,
  style,
}) => {
  SurCard.displayName = 'SurCard'
  const classes = makeStyles({
    varient,
    hasAvatar: !!header?.avatar,
    hasExpand: isNotNilAndEmpty(actions?.isExpanded),
  })
  return (
    <Card className={classes.root} {...{ style }}>
      {header && <SurCardHeader {...header} className={classes.paper} />}
      {url && (
        <CardMedia className={classes.media} image={url} title="Paella dish" />
      )}
      <CardContent>
        {children ? (
          typeof children === 'string' ? (
            <Typography variant="body2" color="textSecondary" component="p">
              {children}
            </Typography>
          ) : (
            <>{children}</>
          )
        ) : (
          <Typography variant="body2" color="textSecondary" component="p">
            {noContentText ? noContentText : 'No Content'}
          </Typography>
        )}
      </CardContent>
      {!!actions && (
        <>
          <SurCardActions {...actions} className={classes.cardActions} />
          {actions.collapseComponent && (
            <Collapse in={actions?.isExpanded} timeout="auto" unmountOnExit>
              <CardContent>{actions.collapseComponent}</CardContent>
            </Collapse>
          )}
        </>
      )}
    </Card>
  )
}

export default SurCard
