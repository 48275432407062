import { Collapse } from '@material-ui/core'
import { Alert, AlertProps, AlertTitle } from '@material-ui/lab'
import {
  AlertError as ErrorIcon,
  AlertInfo as InfoIcon,
  AlertSuccess as SuccessIcon,
  AlertWarning as WarningIcon,
  CloseChip,
} from '@sur-ui/icons'
import SurButton from 'components/SurButton'
import SurIconButton from 'components/SurIconButton'
import React, { FC, ReactNode, useMemo } from 'react'
import useStyle from './SurAlert.style'

export interface ISurAlertProps
  extends Pick<AlertProps, 'severity' | 'variant' | 'classes'> {
  content?: string
  title?: string
  actionTitle?: ReactNode
  isOpen: boolean
  closable?: boolean
  onActionClick?: () => void
}

const iconMapping = {
  error: <ErrorIcon data-testid="ErrorIcon" />,
  success: <SuccessIcon data-testid="SuccessIcon" />,
  warning: <WarningIcon data-testid="WarningIcon" />,
  info: <InfoIcon data-testid="InfoIcon" />,
}

const SurAlert: FC<ISurAlertProps> = ({
  children,
  classes,
  closable,
  isOpen,
  onActionClick,
  severity,
  title,
  actionTitle,
  variant,
}) => {
  const classNames = useStyle({ classes })
  const action = useMemo(() => {
    if (actionTitle) {
      return (
        <SurButton
          hasNeutral
          variant="text"
          ripple="simple"
          onClick={onActionClick}
        >
          {actionTitle}
        </SurButton>
      )
    }

    return (
      !closable || (
        <SurIconButton
          hasNeutral
          shape="circle"
          variant="text"
          onClick={onActionClick}
          data-testid="alertCloseAction"
        >
          <CloseChip />
        </SurIconButton>
      )
    )
  }, [actionTitle, closable, onActionClick])

  if (!isOpen) {
    return null
  }
  return (
    <Collapse in data-testid="SurAlert">
      <Alert
        {...{ classes: classNames, severity, variant, iconMapping, action }}
      >
        <AlertTitle>{title}</AlertTitle>
        {children}
      </Alert>
    </Collapse>
  )
}

SurAlert.defaultProps = { closable: true }
SurAlert.displayName = 'SurAlert'

export default SurAlert
