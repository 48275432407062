import BackdropComponent from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import Modal from '@material-ui/core/Modal'
import {CloseChip as CloseIcon} from '@sur-ui/icons'
import React, { FC, ReactNode } from 'react'
import useSurModalStyle from './SurModal.style'
import classNames from 'classnames'
import { Box, ButtonBase } from '@material-ui/core'

interface ISurModalProps {
  open: boolean
  options?: {
    width?: number | string
    height?: number | string
  }
  classes?: {
    root: string
  }
  title?: ReactNode
  setOpen(open: boolean): void
}

const SurModal: FC<ISurModalProps> = ({
  open,
  setOpen,
  options,
  title,
  children,
  classes: customClasses,
  ...props
}) => {
  const classes = useSurModalStyle({ options })

  const handleClose = () => {
    setOpen(false)
  }
  return (
    <Modal
      className={classNames(classes.modal, customClasses?.root)}
      onClose={handleClose}
      BackdropProps={{
        timeout: 500,
      }}
      {...{
        open,
        BackdropComponent,
        closeAfterTransition: true,
        ...props,
      }}
    >
      <Fade in={open}>
        <Box
          data-testid="SurModalPaper"
          className={classes.paper}
          style={{
            width: options?.width ?? 500,
            height: options?.height ?? 500,
          }}
        >
          <Box component="header" className={classes.header}>
            <h5 data-testid="SurModalTitle">{title}</h5>
            <ButtonBase
              data-testid="SurModalCloseBtn"
              onClick={handleClose}
              className={classes.closeBtn}
            >
              <CloseIcon className={classes.closeIcon} width={34} height={34} />
            </ButtonBase>
          </Box>
          <Box
            component="main"
            className={classes.content}
            data-testid="SurModalMain"
          >
            {children}
          </Box>
        </Box>
      </Fade>
    </Modal>
  )
}

export default SurModal
