import { makeFloraMaidStyles } from 'layout/FloraMaidTheme'

interface IUseSurModalStyleProps {
  visible: boolean
}
const useSurModalStyle = makeFloraMaidStyles<IUseSurModalStyleProps>(() => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    visibility: ({ visible }) => (visible ? 'visible' : 'hidden'),
    height: 6,
  },
}))

export default useSurModalStyle
