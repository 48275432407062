import * as React from 'react'

const DialogSuccess = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    id="jasmijn_web_ico_ok_04"
    xmlns="http://www.w3.org/2000/svg"
    width="24.004"
    height="24.004"
    viewBox="0 0 24.004 24.004"
    {...props}
  >
    <rect id="container" width="24" height="24" fill="none" />
    <path
      id="jasmijn_web_ico_ok_04-2"
      data-name="jasmijn_web_ico_ok_04"
      d="M20425.586,18417.758a11.838,11.838,0,0,1-2.252-.7,11.959,11.959,0,0,1-2.041-1.1,12.317,12.317,0,0,1-1.777-1.467,12.01,12.01,0,0,1-1.465-1.777,11.624,11.624,0,0,1-1.107-2.039,12.031,12.031,0,0,1-.7-7.086,12.033,12.033,0,0,1,7.092-8.641,11.7,11.7,0,0,1,2.252-.7,12,12,0,0,1,4.836,0,11.784,11.784,0,0,1,2.256.7,11.91,11.91,0,0,1,2.039,1.107,12.063,12.063,0,0,1,3.242,3.238,12.338,12.338,0,0,1,1.109,2.039c.152.367.348.9.469,1.293a.991.991,0,0,1-1.52,1.178.97.97,0,0,1-.34-.484l0,0a9.2,9.2,0,0,0-.424-1.221,9.919,9.919,0,0,0-.926-1.7,10.036,10.036,0,0,0-1.225-1.484,10,10,0,0,0-5.074-2.736,10.277,10.277,0,0,0-4.047,0,10.285,10.285,0,0,0-1.881.586,10.126,10.126,0,0,0-1.705.924,10.393,10.393,0,0,0-1.488,1.227,10.023,10.023,0,0,0-1.223,1.484,9.787,9.787,0,0,0-.926,1.7,10.053,10.053,0,0,0,0,7.811,9.689,9.689,0,0,0,.926,1.7,10.141,10.141,0,0,0,1.223,1.486,10.347,10.347,0,0,0,1.488,1.227,10.126,10.126,0,0,0,1.705.924,10.027,10.027,0,0,0,11-2.15,10.03,10.03,0,0,0,1.225-1.486,9.37,9.37,0,0,0,.754-1.32l.016.008a.7.7,0,0,1,.084-.129.976.976,0,0,1,1.643,1.035l0,0a12.162,12.162,0,0,1-2.332,3.281,12.424,12.424,0,0,1-1.777,1.467,12.015,12.015,0,0,1-2.039,1.1,12.018,12.018,0,0,1-7.092.7Zm.916-8.75-2.932-2.877a.714.714,0,0,1,0-1.02.75.75,0,0,1,1.037,0l2.412,2.369,4.607-4.523a.74.74,0,0,1,1.035,0,.7.7,0,0,1,0,1.016l-5.125,5.035a.745.745,0,0,1-1.035,0Zm11.68-1.141a1.091,1.091,0,0,1-.2-1.551,1.137,1.137,0,0,1,1.578-.191,1.091,1.091,0,0,1,.2,1.551,1.129,1.129,0,0,1-.889.426A1.141,1.141,0,0,1,20438.182,18407.867Z"
      transform="translate(-20415.996 -18394)"
      fill="#52646f"
    />
  </svg>
)

const MemoSvgComponent = React.memo(DialogSuccess)
export default MemoSvgComponent
