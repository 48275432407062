import { Breadcrumbs, BreadcrumbsProps } from '@material-ui/core'
import React, { FC } from 'react'
import useStyles from './SurBreadcrumbs.styles'

export type ISurBreadcrumbs = BreadcrumbsProps

const SurBreadcrumbs: FC<ISurBreadcrumbs> = (props) => {
  const classes = useStyles({ classes: props.classes })

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      {...{ ...props, classes }}
      maxItems={2}
    />
  )
}

SurBreadcrumbs.displayName = 'SurBreadcrumbs'

export default SurBreadcrumbs
