import * as React from 'react'

const CheckboxTick = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g id="control" transform="translate(-1100 -128)">
      <rect
        id="box"
        width="24"
        height="24"
        rx="3"
        transform="translate(1100 128)"
        fill="#578871"
      />
      <g id="done" transform="translate(0 70)">
        <g id="jasmijn_web_ico_ok_01" transform="translate(1103 61)">
          <rect
            id="container"
            width="18"
            height="18"
            transform="translate(0)"
            fill="none"
          />
          <path
            id="jasmijn_web_ico_ok_01-2"
            data-name="jasmijn_web_ico_ok_01"
            d="M8.148,18.621a2.09,2.09,0,0,1-2.956,0l-4.58-4.58a2.09,2.09,0,0,1,2.956-2.955L6.3,13.814a.529.529,0,0,0,.747,0l7.388-7.388a2.09,2.09,0,1,1,2.956,2.955Z"
            transform="translate(0 -3.564)"
            fill="#fff"
          />
        </g>
      </g>
    </g>
  </svg>
)

const MemoSvgComponent = React.memo(CheckboxTick)
export default MemoSvgComponent
