import * as React from 'react'

const GridCancel = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="42"
    viewBox="0 0 42 42"
    {...props}
  >
    <g id="cancle" transform="translate(-252 -672)">
      <g id="cmp_web_btn_ico_03" transform="translate(252 672)">
        <g id="container" transform="translate(-730 -607)">
          <rect
            id="container-2"
            data-name="container"
            width="42"
            height="42"
            rx="3"
            transform="translate(730 607)"
            fill="none"
          />
        </g>
        <g id="ico" transform="translate(-339 -107)">
          <path
            id="ico-2"
            data-name="ico"
            d="M14.571,6.571H9.714a.286.286,0,0,1-.286-.286V1.429a1.429,1.429,0,1,0-2.857,0V6.286a.286.286,0,0,1-.286.286H1.429a1.429,1.429,0,1,0,0,2.857H6.286a.286.286,0,0,1,.286.286v4.857a1.429,1.429,0,0,0,2.857,0V9.714a.286.286,0,0,1,.286-.286h4.857a1.429,1.429,0,0,0,0-2.857Zm0,0"
            transform="translate(360 116.686) rotate(45)"
            fill="#de8165"
          />
        </g>
      </g>
    </g>
  </svg>
)

const MemoSvgComponent = React.memo(GridCancel)
export default MemoSvgComponent
