import { TimeIcon } from '@sur-ui/icons'
import React, { useMemo } from 'react'
import SurScrollableInput from '../SurScrollableInput'
import useStyle from './SurTimePicker.styles'

export interface ISurTimePicker {
  is24?: boolean
  hasSecond?: boolean
  value: Date | null
  onChange(value: Date): void
}

export interface TimeValues {
  hour: number
  minute: number
  second: number
  meridian?: 'AM' | 'PM'
}

const meridianOptions = [
  { label: 'AM', value: 'AM' },
  { label: 'PM', value: 'PM' },
]
const SurTimePicker: React.FC<ISurTimePicker> = ({
  is24,
  value,
  onChange,
  hasSecond,
}) => {
  const classes = useStyle({})

  const [hour, minute, second, meridian] = useMemo(() => {
    if (!value) {
      return [0, 0, 0]
    }
    if (is24) {
      return [value.getHours(), value.getMinutes(), value.getSeconds()]
    }
    /// 0-23 => 1 -12
    const h = value.getHours()
    // console.log(value, h % 12)

    return [
      h % 12,
      value.getMinutes(),
      value.getSeconds(),
      h >= 12 ? 'PM' : 'AM',
    ]
    // eslint-disable-next-line
  }, [+value!, is24])

  const handelHourChange: (value: any) => void = (newHour) =>
    value && onChange(new Date(value.setHours(newHour)))

  const handleMinuteChange: (value: any) => void = (newMinute) =>
    value && onChange(new Date(value.setMinutes(newMinute)))

  const handleSecondChange: (value: any) => void = (newSecond) =>
    value && onChange(new Date(value.setSeconds(newSecond)))

  const handleMeridianChange: (value: any) => void = (newMeridian) => {
    if (!value || is24 || meridian === newMeridian) {
      return
    }
    const newHours = hour + (newMeridian === 'PM' ? 11 : 0)
    onChange(new Date(value.setHours(newHours)))
  }

  return (
    <div className={classes.root}>
      <div className="SurTimePickerContainer">
        <TimeIcon />
        <SurScrollableInput
          color="White"
          range={[is24 ? 23 : 12, is24 ? 0 : 1]}
          value={hour}
          padStart={2}
          onChange={handelHourChange}
        />
        <span className="TimeDevider">:</span>
        <SurScrollableInput
          color="White"
          value={minute}
          padStart={2}
          range={[59]}
          onChange={handleMinuteChange}
        />
        {hasSecond && (
          <>
            <span className="TimeDevider">:</span>
            <SurScrollableInput
              color="White"
              range={[59]}
              value={second}
              padStart={2}
              onChange={handleSecondChange}
            />
          </>
        )}
        {!is24 && (
          <SurScrollableInput
            color="White"
            options={meridianOptions}
            value={meridian}
            onChange={handleMeridianChange}
          />
        )}
      </div>
    </div>
  )
}

SurTimePicker.displayName = 'SurTimePicker'
export default SurTimePicker
