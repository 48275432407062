import * as React from 'react'

const GridExcel = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    {...props}
  >
    <g id="ico" transform="translate(-352 -120)">
      <g id="jasmijn_web_ico_xls_01" transform="translate(352 120)">
        <rect id="container" width="16" height="16" fill="none" />
        <path
          id="jasmijn_web_ico_xls_01-2"
          data-name="jasmijn_web_ico_xls_01"
          d="M19361.832,18845a1.832,1.832,0,0,1-1.832-1.83v-12.334a1.831,1.831,0,0,1,1.832-1.832h5.953a5.834,5.834,0,0,0,5.547,10.037v4.129a1.833,1.833,0,0,1-1.836,1.83Zm6.836-1.83a.5.5,0,0,0,.5.5h.969a1.251,1.251,0,0,0,0-2.5h-.273a.256.256,0,0,1,0-.5h.969a.5.5,0,1,0,0-1h-.969a1.249,1.249,0,0,0,0,2.5h.273a.26.26,0,0,1,0,.5h-.969A.5.5,0,0,0,19368.668,18843.168Zm-3.336-3v3a.5.5,0,0,0,.5.5h1.668a.5.5,0,1,0,0-1h-1.168v-2.5a.5.5,0,0,0-1,0Zm-1.6,3.238a.49.49,0,0,0,.438.258.529.529,0,0,0,.242-.061.507.507,0,0,0,.2-.682l-.7-1.258.7-1.256a.5.5,0,0,0-.875-.484l-.4.715-.395-.715a.5.5,0,0,0-.879.484l.7,1.256-.7,1.258a.5.5,0,0,0,.188.682.533.533,0,0,0,.246.061.5.5,0,0,0,.438-.258l.395-.711Zm3.27-9.906a4.5,4.5,0,1,1,4.5,4.5A4.5,4.5,0,0,1,19367,18833.5Zm2.027-.469a.67.67,0,0,0,0,.941l1.332,1.336a.687.687,0,0,0,.473.193h.023a.677.677,0,0,0,.477-.229l2.336-2.668a.667.667,0,1,0-1.008-.875l-1.859,2.129-.828-.828a.672.672,0,0,0-.477-.2A.641.641,0,0,0,19369.027,18833.031Z"
          transform="translate(-19360 -18829.002)"
          fill="#de8165"
        />
      </g>
    </g>
  </svg>
)

const MemoSvgComponent = React.memo(GridExcel)
export default MemoSvgComponent
