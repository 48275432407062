import { useState, useEffect, useCallback } from 'react'
import isBefore from 'date-fns/isBefore'
import isAfter from 'date-fns/isAfter'
import addDays from 'date-fns/addDays'
import isWithinInterval from 'date-fns/isWithinInterval'
import isSameDay from 'date-fns/isSameDay'
import isSameMonth from 'date-fns/isSameDay'
import {
  getInitialMonths,
  getNextActiveMonth,
  isDateSelected as isDateSelectedFn,
  isDateBlocked as isDateBlockedFn,
  isFirstOrLastSelectedDate as isFirstOrLastSelectedDateFn,
  isEndDate as isEndDateFn,
  isStartDate as isStartDateFn,
  canSelectRange,
  isDateHovered as isDateHoveredFn,
  isInUnavailableDates,
  isInHolidayDatesList,
} from './useDatepicker.utils'

import { isNullOrUndefined } from 'util'
import { UseDatepickerProps, UseDatePickerResult } from '../DatePicker.types'

export const START_DATE = 'startDate'
export const END_DATE = 'endDate'

export const useDatepicker = ({
  startDate,
  endDate,
  focusedInput,
  minBookingDate,
  maxBookingDate,
  onDatesChange,
  initialVisibleMonth,
  exactMinBookingDays = false,
  minBookingDays = 1,
  numberOfMonths = 2,
  firstDayOfWeek = 1,
  isDateBlocked: isDateBlockedProps = () => false,
  unavailableDates = [],
  holidayDatesList = [],
}: UseDatepickerProps): UseDatePickerResult => {
  const [activeMonths, setActiveMonths] = useState(() =>
    startDate
      ? getInitialMonths(numberOfMonths, startDate)
      : getInitialMonths(numberOfMonths, initialVisibleMonth ?? null),
  )

  const [hoveredDate, setHoveredDate] = useState<Date | null>(null)
  const [focusedDate, setFocusedDate] = useState<Date | null>(startDate)

  const disabledDatesByUser = (date: Date) =>
    isInUnavailableDates(unavailableDates, date) ?? isDateBlockedProps(date)

  const holidayDatesByUser = (date: Date) =>
    isInHolidayDatesList(holidayDatesList, date)

  const onDateFocus = useCallback(
    (date: Date) => {
      setFocusedDate(date)

      if (!focusedDate ?? (focusedDate && !isSameMonth(date, focusedDate))) {
        setActiveMonths(getInitialMonths(numberOfMonths, date))
      }
    },
    [setFocusedDate, focusedDate, setActiveMonths, numberOfMonths],
  )

  const isDateSelected = (date: Date) =>
    isDateSelectedFn(date, startDate, endDate)

  const isFirstOrLastSelectedDate = (date: Date) =>
    isFirstOrLastSelectedDateFn(date, startDate, endDate)
  const isHoliday = (date: Date) => holidayDatesByUser(date)

  const isStartDate = (date: Date) => isStartDateFn(date, startDate)

  const isEndDate = (date: Date) => isEndDateFn(date, endDate)

  const isDateBlocked = (date: Date) =>
    isDateBlockedFn({
      date,
      minBookingDate,
      maxBookingDate,
      startDate,
      endDate,
      minBookingDays,
      isDateBlockedFn: disabledDatesByUser,
    })

  const isDateFocused = (date: Date) =>
    focusedDate ? isSameDay(date, focusedDate) : false

  const isDateHovered = (date: Date) =>
    isDateHoveredFn({
      date,
      hoveredDate,
      startDate,
      endDate,
      minBookingDays,
      exactMinBookingDays,
      isDateBlocked: disabledDatesByUser,
    })

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (
        (e.key === 'ArrowRight' ??
          e.key === 'ArrowLeft' ??
          e.key === 'ArrowDown' ??
          e.key === 'ArrowUp') &&
        !focusedDate
      ) {
        const activeMonth = activeMonths[0]
        onDateFocus(activeMonth.date)
        setActiveMonths(getInitialMonths(numberOfMonths, activeMonth.date))
      }
    }
    if (typeof window !== 'undefined') {
      window.addEventListener('keydown', handleKeyDown)
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [activeMonths, focusedDate, numberOfMonths, onDateFocus])

  const onResetDates = () => {
    onDatesChange({
      startDate: null,
      endDate: null,
      focusedInput: START_DATE,
    })
  }

  const onDateSelect = (date: Date) => {
    if (
      (focusedInput === END_DATE ?? focusedInput === START_DATE) &&
      minBookingDays > 0 &&
      exactMinBookingDays &&
      canSelectRange({
        minBookingDays,
        exactMinBookingDays,
        minBookingDate,
        maxBookingDate,
        isDateBlocked: disabledDatesByUser,
        startDate: date,
        endDate: null,
      })
    ) {
      onDatesChange({
        startDate: date,
        endDate: addDays(date, minBookingDays - 1),
        focusedInput: null,
      })
    } else if (
      ((focusedInput === END_DATE && startDate && isBefore(date, startDate)) ??
        (focusedInput === START_DATE && endDate && isAfter(date, endDate))) &&
      !exactMinBookingDays &&
      canSelectRange({
        minBookingDays,
        isDateBlocked: disabledDatesByUser,
        startDate: date,
        endDate: null,
      })
    ) {
      onDatesChange({
        endDate: null,
        startDate: date,
        focusedInput: END_DATE,
      })
    } else if (
      focusedInput === START_DATE &&
      !exactMinBookingDays &&
      canSelectRange({
        minBookingDays,
        isDateBlocked: disabledDatesByUser,
        endDate,
        startDate: date,
      })
    ) {
      onDatesChange({
        endDate,
        startDate: date,
        focusedInput: END_DATE,
      })
    } else if (
      focusedInput === START_DATE &&
      !exactMinBookingDays &&
      canSelectRange({
        minBookingDays,
        isDateBlocked: disabledDatesByUser,
        endDate: null,
        startDate: date,
      })
    ) {
      onDatesChange({
        endDate: null,
        startDate: date,
        focusedInput: END_DATE,
      })
    } else if (
      focusedInput === END_DATE &&
      startDate &&
      !isBefore(date, startDate) &&
      !exactMinBookingDays &&
      canSelectRange({
        minBookingDays,
        isDateBlocked: disabledDatesByUser,
        startDate,
        endDate: date,
      })
    ) {
      onDatesChange({
        startDate,
        endDate: date,
        focusedInput: null,
      })
    }

    if (
      focusedInput !== END_DATE &&
      (!focusedDate ?? (focusedDate && !isSameMonth(date, focusedDate)))
    ) {
      setActiveMonths(getInitialMonths(numberOfMonths, date))
    }
    // setActiveDate(date)
  }

  const onDateHover = (date: Date | null) => {
    if (isNullOrUndefined(date)) {
      setHoveredDate(null)
    } else if (date) {
      const isNotBlocked =
        !isDateBlocked(date) ?? (startDate && isSameDay(date, startDate))
      const isHoveredDateAfterOrEqualMinDate = minBookingDate
        ? !isBefore(date, addDays(minBookingDate, -1))
        : true
      const isHoveredDateBeforeOrEqualMaxDate = maxBookingDate
        ? !isAfter(date, maxBookingDate)
        : true

      // Exact minimal booking days
      const potentialEndDate = addDays(date, minBookingDays - 1)
      const isPotentialEndDateAfterOrEqualMinDate = minBookingDate
        ? !isBefore(potentialEndDate, minBookingDate)
        : true
      const isPotentialEndDateBeforeOrEqualMaxDate = maxBookingDate
        ? !isAfter(potentialEndDate, maxBookingDate)
        : true
      const isExactAndInRange =
        exactMinBookingDays &&
        minBookingDays > 1 &&
        isHoveredDateAfterOrEqualMinDate &&
        isHoveredDateBeforeOrEqualMaxDate &&
        isPotentialEndDateAfterOrEqualMinDate &&
        isPotentialEndDateBeforeOrEqualMaxDate

      // Is date in range
      const isInRange =
        startDate &&
        !endDate &&
        !exactMinBookingDays &&
        isHoveredDateAfterOrEqualMinDate &&
        isHoveredDateBeforeOrEqualMaxDate

      // Is start date hovered and in range
      const isMinBookingDaysInRange =
        minBookingDays > 1 && startDate
          ? isWithinInterval(date, {
            start: startDate,
            end: addDays(startDate, minBookingDays - 2),
          })
          : true
      const isStartDateHoveredAndInRange =
        startDate && isSameDay(date, startDate) && isMinBookingDaysInRange
      const isHolidayDate = holidayDatesByUser(date)
      if (isHolidayDate) {
        setHoveredDate(date)
      } else if (
        isNotBlocked &&
        (!isHolidayDate ??
          isExactAndInRange ??
          isInRange ??
          isStartDateHoveredAndInRange)
      ) {
        setHoveredDate(date)
      } else if (hoveredDate !== null) {
        setHoveredDate(null)
      }
    }
  }

  const goToPreviousMonths = () => {
    setActiveMonths(getNextActiveMonth(activeMonths, numberOfMonths, -1))
    setFocusedDate(null)
  }

  const goToPreviousMonthsByOneMonth = () => {
    setActiveMonths(getNextActiveMonth(activeMonths, numberOfMonths, -1, 1))
    setFocusedDate(null)
  }

  const goToNextMonths = () => {
    setActiveMonths(getNextActiveMonth(activeMonths, numberOfMonths, 1))
    setFocusedDate(null)
  }

  const goToNextMonthsByOneMonth = () => {
    setActiveMonths(getNextActiveMonth(activeMonths, numberOfMonths, 1, 1))
    setFocusedDate(null)
  }

  const goToDate = (date: Date) => {
    setActiveMonths(getInitialMonths(numberOfMonths, date))
    setFocusedDate(null)
  }

  const goToPreviousYear = (numYears = 1) => {
    setActiveMonths(
      getNextActiveMonth(
        activeMonths,
        numberOfMonths,
        -(numYears * 12 - numberOfMonths + 1),
      ),
    )
    setFocusedDate(null)
  }

  const goToNextYear = (numYears = 1) => {
    setActiveMonths(
      getNextActiveMonth(
        activeMonths,
        numberOfMonths,
        numYears * 12 - numberOfMonths + 1,
      ),
    )
    setFocusedDate(null)
  }

  return {
    firstDayOfWeek,
    activeMonths,
    isDateSelected,
    isDateHovered,
    isFirstOrLastSelectedDate,
    isStartDate,
    isEndDate,
    isDateBlocked,
    numberOfMonths,
    isDateFocused,
    focusedDate,
    hoveredDate,
    isHoliday,
    onResetDates,
    onDateHover,
    onDateSelect,
    onDateFocus,
    goToPreviousMonths,
    goToPreviousMonthsByOneMonth,
    goToNextMonths,
    goToNextMonthsByOneMonth,
    goToDate,
    goToPreviousYear,
    goToNextYear,
  }
}
