import { Box, InputBase } from '@material-ui/core'
import {Search as SearchIcon} from '@sur-ui/icons'
import {CloseChip as CloseIcon} from '@sur-ui/icons'
import React, { FC } from 'react'
import { useSurSearchContext } from './SurSearch.context'
import useStyles from './SurSearch.style'
import SurIconButton from 'components/SurIconButton'

const SurSearchInput: FC = () => {
  const {
    data,
    value,
    onChange,
    onOpen,
    onClose,
    onSubmit,
  } = useSurSearchContext()
  const classes = useStyles()
  const onInputChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>): void => onChange(value)

  const onFocus = () => onOpen()

  const handleSubmit = () => {
    const hasValue = data.find((item: any) => item.name === value)
    if (hasValue) {
      onSubmit && onSubmit(hasValue)
    }
  }
  return (
    <Box className={classes.inputContainer}>
      <InputBase
        inputProps={{
          'data-testid': 'SurSearchInput',
        }}
        {...{
          value,
          classes: { input: classes.inputInput, root: classes.inputBase },
          placeholder: 'Search',
          onChange: onInputChange,
          onFocus,
        }}
      />
      {value.length > 0 && (
        <SurIconButton
          data-testid="SurSearchClearBtn"
          hasNeutral
          variant="text"
          shape="circle"
          onClick={() => {
            onChange('')
            onClose()
          }}
        >
          <CloseIcon />
        </SurIconButton>
      )}

      {onSubmit && value.length > 0 && (
        <SurIconButton
          hasNeutral
          variant="text"
          shape="circle"
          // className={classes.searchBtnRoot}
          onClick={handleSubmit}
          data-testid="SurSearchSubmitBtn"
        >
          <SearchIcon width={16} height={16} />
        </SurIconButton>
      )}
    </Box>
  )
}

export default SurSearchInput
