import { makeFloraMaidStyles } from 'layout/FloraMaidTheme'
import type { ISurAlertProps } from './SurAlert'

type IStyleProps = Pick<ISurAlertProps, 'classes'>
export const useStyle = makeFloraMaidStyles<IStyleProps>((theme) => ({
  root: {
    position: 'relative',
    '& .MuiIconButton-root svg path': {
      fill: '#52646F',
    },
    '&::before': {
      content: '""',
      height: '100%',
      left: 0,
      top: 0,
      display: 'block',
      width: 6,
      position: 'absolute',
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
    },
    '& .MuiAlertTitle-root': {
      fontSize: 18,
      fontFamily: 'Avenir',
    },
    '& .MuiButton-label': {
      fontFamily: 'Avenir',
      color: theme.palette?.neutral?.[900],
    },
  },
  standardSuccess: {
    backgroundColor: theme.palette?.primary?.[50],
    '&::before': {
      backgroundColor: theme.palette?.primary?.[500],
    },
  },
  standardError: {
    backgroundColor: '#FFEBEF',
    '&::before': {
      backgroundColor: theme.palette?.error?.[600],
    },
  },
  standardInfo: {
    backgroundColor: '#E3F6FD',
    '&::before': {
      backgroundColor: '#33A4E1',
    },
  },
  standardWarning: {
    backgroundColor: '#FBEAB4',
    '&::before': {
      backgroundColor: '#F3AF29',
    },
  },
  message: {
    fontFamily: 'Avenir',
  },
}))

export default useStyle
