import { makeFloraMaidStyles } from 'layout/FloraMaidTheme'

export default makeFloraMaidStyles(
  ({ palette: { secondary, neutral } }) => ({
    root: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      margin: 10,
      '& > div:first-child': {
        // width: "100%",
      },
      '& .SurUploaderZoon': {
        width: 450,
        height: 418,
        backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23809EB0FF' stroke-width='4' stroke-dasharray='10' stroke-dashoffset='21' stroke-linecap='square'/%3e%3c/svg%3e")`,
        padding: '48px 10px',
        color: neutral?.[500],
        transition: '0.3s all',
        borderRadius: 4,
        marginRight: 24,
        '& .fileUploaderContainer': {
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          alignItems: 'center',
          cursor: 'default',
          // "& img": {
          // 	margin: "48px 0 35px 0",
          // 	maxWidth: 100,
          // },
          '& > svg': {
            transition: '0.3s all',
            '& > path': {
              fill: neutral?.[400],
            },
            '&.SurFileUploaderIconDraged': {
              '& > path': {
                fill: '#fff',
              },
            },
          },
          '& button': {
            transition: '0.3s all',
          },
          '& .SurFileUploaderButtonDraged': {
            background: secondary?.[50],
            color: secondary?.main,
          },
          '& .fileUploaderText': {
            marginTop: 24,
            textAlign: 'center',
            fontSize: 14,
            display: 'flex',
            flexDirection: 'column',
            '&.fileUploaderDragTexts': {
              '& span': {
                marginBottom: 18,
              },
            },
            '&.fileUploaderSupprotedTexts': {
              '& span': {
                fontSize: 12,
                // marginBottom: 11,
              },
            },
          },
        },
        '&.SurFileUploaderDraged': {
          backgroundColor: neutral?.[100],
          color: '#fff!important',
        },
      },
    },

    Preview: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',

      '& .SurUploaderPriviewChildsContainer': {
        width: 450,
        height: 420,
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        '& .SurUploaderChilds': {
          display: 'flex',
          flexDirection: 'column',
          height: 200,
          borderRadius: 8,
          padding: 10,
          margin: 10,
          alignItems: 'center',
          marginBottom: 0,
          overflow: 'hidden',
          transition: '0.3s all',
          cursor: 'default',
          '&:hover': {
            '& .progressContainer': {
              boxShadow: '0 0 5px rgba(0,0,0,0.3)',
              transition: '.5s all',
            },
          },
          '& .progressContainer': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 144,
            height: 144,
            boxShadow: '0 0 3px rgba(0,0,0,0.2)',
            borderRadius: 12,
            overflow: 'hidden',
            position: 'relative',
            '& .downloadIcon': {
              position: 'absolute',
              left: 0,
              bottom: 0,
              padding: '2px 7px',
              cursor: 'pointer',
            },
          },
          '& .previewContent': {
            padding: 5,
            width: 144,
            textAlign: 'center',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: '0.75rem',
          },
        },
      },
    },
  }),
  { name: 'SurUploader' },
)
