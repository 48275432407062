import * as React from 'react'

const Download = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27"
    height="27"
    viewBox="0 0 27 27"
    {...props}
  >
    <g id="uploaderDownloadIcon" transform="translate(0.19 0.265)">
      <rect
        id="container"
        width="27"
        height="27"
        transform="translate(-0.19 -0.265)"
        fill="none"
      />
      <path
        id="jasmijn_web_ico_upload_01"
        d="M20004.605,18226.234a1.928,1.928,0,0,1-1.926-1.93v-1.648a.824.824,0,1,1,1.648,0v1.648a.276.276,0,0,0,.277.277h7.137a.272.272,0,0,0,.27-.277v-1.648a.824.824,0,1,1,1.648,0v1.648a1.926,1.926,0,0,1-1.918,1.93Zm8.762-6.609a.824.824,0,0,1,0-1.648h1.246a5.027,5.027,0,0,0,.309-10.047.828.828,0,0,1-.7-.469,6.7,6.7,0,0,0-12.668,1.867.821.821,0,0,1-.816.711,3.97,3.97,0,1,0-.082,7.938h2.3a.824.824,0,0,1,0,1.648h-2.3a5.618,5.618,0,0,1-.605-11.207,8.351,8.351,0,0,1,15.445-2.09,6.674,6.674,0,0,1-.883,13.3Z"
        transform="translate(-19994.902 -18200.803)"
        fill="#809eb0"
      />
      <path
        id="jasmijn_web_ico_upload_01-2"
        data-name="jasmijn_web_ico_upload_01"
        d="M2.468,9.09V2.823L1.4,3.89a.811.811,0,0,1-1.161,0,.824.824,0,0,1,0-1.168L2.713.244a.811.811,0,0,1,1.161,0l2.47,2.477a.827.827,0,0,1,0,1.168.816.816,0,0,1-1.164,0L4.112,2.821V9.09a.822.822,0,1,1-1.644,0Z"
        transform="translate(16.564 20.999) rotate(180)"
        fill="#809eb0"
      />
    </g>
  </svg>
)

const MemoSvgComponent = React.memo(Download)
export default MemoSvgComponent
