import React, { FC } from 'react'
import { Badge, BadgeProps } from '@material-ui/core'

const SurBadge: FC<BadgeProps> = (props) => (
  <Badge data-testid="SurBadge" {...props} />
)

SurBadge.displayName = 'SurBadge'
SurBadge.defaultProps = { variant: 'standard', overlap: 'circle' }
export default SurBadge
