import { makeFloraMaidStyles } from 'layout/FloraMaidTheme'
import { ISurButtonVariant } from '../SurButton/SurButton'

interface IStyleProps {
  variant?: ISurButtonVariant
  shape?: 'circle' | 'square'
}

const hasVariantOrEqualTo = (
  variant?: ISurButtonVariant,
  targetVariant?: ISurButtonVariant,
) => !variant || variant === targetVariant

const isVariantNotNilAndEqualToContained = (variant?: ISurButtonVariant) =>
  hasVariantOrEqualTo(variant, 'contained')

const isVariantEqualToOutlined = (variant?: ISurButtonVariant) =>
  variant === 'outlined'

const transition = 'all ease 0.8s'
const boxShadow = 'unset'

export default makeFloraMaidStyles<IStyleProps>(({ palette }) => {
  return {
    root: {
      boxShadow,
      width: 'max-content',
      color: ({ variant }) =>
        isVariantNotNilAndEqualToContained(variant) ? '#fff' : '',
      fontSize: 16,
      height: 42,
      borderRadius: ({ shape }) => (shape === 'square' ? '3px' : '50%'),
    },

    containedNeutral: {
      background: palette?.neutral?.[300],
      transition,

      '& svg path': {
        fill: '#fff',
      },

      '&:hover': {
        background: palette?.neutral?.[500],
        boxShadow,
      },

      '&.Mui-disabled': {
        background: palette?.neutral?.[50],
        color: palette?.neutral?.[200],

        '& svg path': {
          fill: palette?.neutral?.[200],
        },
      },

      '& .MuiTouchRipple-root .MuiTouchRipple-ripple': {
        animation: 'unset',
        '& .MuiTouchRipple-child': {
          backgroundColor: palette?.neutral?.[200],
          color: palette?.neutral?.[500],
          borderRadius: ({ shape }) => (shape === 'square' ? '3px' : '50%'),
        },
      },
    },
    outlinedNeutral: {
      background: 'transparent',
      transition,
      border: ({ variant }) =>
        isVariantEqualToOutlined(variant)
          ? `1px solid ${palette?.neutral?.[300]}`
          : '',

      '&:hover': {
        background: palette?.neutral?.[50],
        boxShadow,
      },

      '&.Mui-disabled': {
        background: 'transparent',
        color: palette?.neutral?.[100],
        borderColor: palette?.neutral?.[100],

        '& svg path': {
          fill: palette?.neutral?.[100],
        },
      },

      '& svg path': {
        fill: palette?.neutral?.[300],
      },

      '& .MuiTouchRipple-root .MuiTouchRipple-ripple': {
        animation: 'unset',
        '& .MuiTouchRipple-child': {
          backgroundColor: palette?.neutral?.[200],
          color: palette?.neutral?.[500],
          borderRadius: ({ shape }) => (shape === 'square' ? '3px' : '50%'),
        },
      },
    },

    textNeutral: {
      background: 'transparent',
      transition,

      '&:hover': {
        background: palette?.neutral?.[50],
        boxShadow,
      },

      '&.Mui-disabled': {
        background: 'transparent',
        color: palette?.neutral?.[100],

        '& svg path': {
          fill: palette?.neutral?.[100],
        },
      },

      '& svg path': {
        fill: palette?.neutral?.[300],
      },

      '& .MuiTouchRipple-root .MuiTouchRipple-ripple': {
        animation: 'unset',
        '& .MuiTouchRipple-child': {
          backgroundColor: palette?.neutral?.[200],
          color: palette?.neutral?.[500],
          borderRadius: ({ shape }) => (shape === 'square' ? '3px' : '50%'),
        },
      },
    },

    colorSecondary: {
      color: ({ variant }) =>
        isVariantNotNilAndEqualToContained(variant) ? '#fff' : '',
      background: ({ variant }) =>
        isVariantNotNilAndEqualToContained(variant)
          ? palette?.secondary?.main
          : 'transparent',
      border: ({ variant }) =>
        isVariantEqualToOutlined(variant)
          ? `1px solid ${palette?.secondary?.main}`
          : '',
      transition,
      fontSize: 16,
      boxShadow,

      '&:hover': {
        background: ({ variant }) =>
          isVariantNotNilAndEqualToContained(variant)
            ? palette?.secondary?.[500]
            : palette?.secondary?.[50],
        boxShadow,
      },

      '& svg path': {
        fill: ({ variant }: IStyleProps) =>
          isVariantNotNilAndEqualToContained(variant)
            ? '#fff'
            : palette?.secondary?.main,
      },

      '&.Mui-disabled': {
        background: ({ variant }) =>
          isVariantNotNilAndEqualToContained(variant)
            ? palette?.secondary?.[50]
            : 'transparent',
        color: ({ variant }) =>
          isVariantNotNilAndEqualToContained(variant)
            ? palette?.secondary?.[200]
            : palette?.secondary?.[100],
        borderColor: ({ variant }) =>
          isVariantEqualToOutlined(variant) ? palette?.secondary?.[100] : '',

        '& svg path': {
          fill: ({ variant }: IStyleProps) =>
            isVariantNotNilAndEqualToContained(variant)
              ? palette?.secondary?.[200]
              : palette?.secondary?.[100],
        },
      },

      '& .MuiTouchRipple-root .MuiTouchRipple-ripple': {
        animation: 'unset',
        '& .MuiTouchRipple-child': {
          backgroundColor: palette?.secondary?.[200],
          color: palette?.secondary?.[500],
          borderRadius: ({ shape }) => (shape === 'square' ? '3px' : '50%'),
        },
      },
    },

    colorPrimary: {
      color: ({ variant }) =>
        isVariantNotNilAndEqualToContained(variant) ? '#fff' : '',
      background: ({ variant }) =>
        isVariantNotNilAndEqualToContained(variant)
          ? palette?.primary?.main
          : 'transparent',
      border: ({ variant }) =>
        isVariantEqualToOutlined(variant)
          ? `1px solid ${palette?.primary?.main}`
          : '',
      transition,
      fontSize: 16,
      boxShadow,

      '&:hover': {
        background: ({ variant }) =>
          isVariantNotNilAndEqualToContained(variant)
            ? palette?.primary?.[500]
            : palette?.primary?.[50],
        boxShadow,
      },

      '& svg path': {
        fill: ({ variant }: IStyleProps) =>
          isVariantNotNilAndEqualToContained(variant)
            ? '#fff'
            : palette?.primary?.main,
      },

      '&.Mui-disabled': {
        background: ({ variant }) =>
          isVariantNotNilAndEqualToContained(variant)
            ? palette?.primary?.[50]
            : 'transparent',
        color: ({ variant }) =>
          isVariantNotNilAndEqualToContained(variant)
            ? palette?.primary?.[200]
            : palette?.primary?.[100],
        borderColor: ({ variant }) =>
          isVariantEqualToOutlined(variant) ? palette?.primary?.[100] : '',

        '& svg path': {
          fill: ({ variant }: IStyleProps) =>
            isVariantNotNilAndEqualToContained(variant)
              ? palette?.primary?.[200]
              : palette?.primary?.[100],
        },
      },

      '& .MuiTouchRipple-root .MuiTouchRipple-ripple': {
        animation: 'unset',
        '& .MuiTouchRipple-child': {
          backgroundColor: palette?.primary?.[200],
          color: palette?.primary?.[500],
          borderRadius: ({ shape }) => (shape === 'square' ? '3px' : '50%'),
        },
      },
    },
  }
})
