import * as React from 'react'

const AlertClose = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    id="jasmijn_web_ico_clear_01"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <rect id="container" width="24" height="24" fill="none" />
    <path
      id="jasmijn_web_ico_clear_01-2"
      data-name="jasmijn_web_ico_clear_01"
      d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm4.853,15.439a.5.5,0,0,1,0,.707l-.707.707a.5.5,0,0,1-.707,0L12,13.414,8.561,16.853a.5.5,0,0,1-.707,0l-.707-.707a.5.5,0,0,1,0-.707L10.586,12,7.147,8.561a.5.5,0,0,1,0-.707l.707-.707a.5.5,0,0,1,.707,0L12,10.586l3.439-3.439a.5.5,0,0,1,.707,0l.707.707a.5.5,0,0,1,0,.707L13.414,12Z"
      fill="#ED3238"
    />
  </svg>
)

const MemoSvgComponent = React.memo(AlertClose)
export default MemoSvgComponent
