import { makeFloraMaidStyles } from 'layout/FloraMaidTheme'

type IActiveColor = 'secondary' | 'primary'
type IColor = 'white' | 'secondary' | 'primary'
interface IStyleProps {
  activeColor?: IActiveColor
  color: IColor
  classes?: ReturnType<typeof useStyle>
}
const useStyle = makeFloraMaidStyles<IStyleProps>(
  ({ palette: { primary, secondary, neutral }, spacing }) => ({
    root: {
      minHeight: 56,
      '& .MuiTab-root': {
        opacity: 1,
        fontFamily: 'Avenir',
        flex: 1,
        minHeight: 56,
        backgroundColor: color({
          primary: primary.main,
          secondary: secondary.main,
          white: '#fff',
        }),
        backgroundImage: color({
          primary: primary[400],
          secondary: secondary[400],
          white: neutral[100],
        }),
        backgroundPosition: '50% 50%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '0% 100%',
        transition: 'background-size .1s',
        color: color({ white: neutral[500] }, neutral[50]),
        borderBottom: color({ white: `1px solid ${neutral[200]}` }),
        '& svg, & svg path': { fill: 'currentColor' },
        '&:hover': { backgroundSize: '100% 100%' },
        '&.Mui-selected': {
          color: activeColor({
            secondary: secondary[500],
            primary: primary[500],
          }),
          backgroundImage: 'unset',
        },
        '&.Mui-disabled': {
          color: color({
            white: neutral[200],
            primary: primary[100],
            secondary: secondary[100],
          }),
        },
        '&::before': {
          content: 'some content',
          display: 'block',
          height: 60,
          marginTop: -60,
        },
      },
    },
    indicator: {
      height: 4,
      backgroundColor: activeColor({
        secondary: secondary[500],
        primary: primary[500],
      }),
    },
    scrollButtons: {
      opacity: 1,
      fontSize: 25,
      color: color({ white: neutral[500] }, neutral[50]),
      backgroundColor: color({
        primary: primary.main,
        secondary: secondary.main,
        white: '#fff',
      }),
      backgroundImage: color({
        primary: primary[400],
        secondary: secondary[400],
        white: neutral[100],
      }),
      borderBottom: color({ white: `1px solid ${neutral[200]}` }),
      backgroundPosition: '50% 50%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '0% 0%',
      transition: 'background-size .1s',
      '&.Mui-disabled': {
        backgroundColor: color({
          primary: primary.main,
          secondary: secondary.main,
          white: '#fff',
        }),
        opacity: 0.8,
      },
      '&:hover': { backgroundSize: '100% 100%' },
    },
    tabLabel: {
      display: 'flex',
      alignItems: 'center',
      '& > label': { marginLeft: spacing(1) },
    },
  }),
)

export default useStyle

const selector = (objMap: any, key?: string): string | undefined => {
  if (!key) {
    return undefined
  }
  return objMap[key]
}

const color = (obj: { [key in IColor]?: string }, defArg?: string) => ({
  color,
}: IStyleProps) => {
  const result = selector(obj, color)
  if (result) {
    return result
  }
  return defArg
}

const activeColor = (
  obj: { [key in IActiveColor]?: string },
  defArg?: string,
) => ({ activeColor, color }: IStyleProps): string | undefined => {
  if (color !== 'white') {
    return '#fff'
  }
  const result = selector(obj, activeColor)
  if (result) {
    return result
  }
  return defArg
}
