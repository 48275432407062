import * as React from 'react'

const Upload = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    id="jasmijn_web_ico_upload_01"
    xmlns="http://www.w3.org/2000/svg"
    width="24.002"
    height="24"
    viewBox="0 0 24.002 24"
    {...props}
  >
    <rect id="container" width="24" height="24" fill="none" />
    <path
      id="jasmijn_web_ico_upload_01-2"
      data-name="jasmijn_web_ico_upload_01"
      d="M20003.748,18224.078a1.755,1.755,0,0,1-1.75-1.758v-1.5a.75.75,0,1,1,1.5,0v1.5a.251.251,0,0,0,.25.254h6.5a.251.251,0,0,0,.25-.254v-1.5a.75.75,0,1,1,1.5,0v1.5a1.755,1.755,0,0,1-1.75,1.758Zm2.5-3.764v-5.709l-.971.973a.751.751,0,0,1-1.059-1.064l2.25-2.258a.744.744,0,0,1,1.059,0l2.25,2.258a.754.754,0,0,1,0,1.064.745.745,0,0,1-1.059,0l-.973-.975v5.711a.749.749,0,1,1-1.5,0Zm5.482-2.258a.753.753,0,0,1,0-1.506h1.137a4.577,4.577,0,0,0,.281-9.148.754.754,0,0,1-.639-.43,6.1,6.1,0,0,0-11.539,1.705.751.751,0,0,1-.744.646,3.614,3.614,0,1,0-.076,7.227h2.1a.753.753,0,0,1,0,1.506h-2.1a5.117,5.117,0,0,1-.549-10.209,7.608,7.608,0,0,1,14.068-1.908,6.082,6.082,0,0,1-.8,12.117Z"
      transform="translate(-19994.996 -18200.996)"
      fill="#52646f"
    />
  </svg>
)

const MemoSvgComponent = React.memo(Upload)
export default MemoSvgComponent
