import { makeFloraMaidStyles } from 'layout/FloraMaidTheme'

interface IStyleProps {
  color: 'primary' | 'secondary'
}
export default makeFloraMaidStyles<IStyleProps>(({ palette }) => ({
  root: { position: 'relative' },
  tick: {
    '& rect': {
      fill: ({ color }: { color: 'primary' | 'secondary' }) =>
        color && palette[color].main,
    },
  },
}))
