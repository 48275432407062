import { CardActions } from '@material-ui/core'
import { ArrowIcon } from '@sur-ui/icons'
import SurButton from 'components/SurButton'
import SurIconButton from 'components/SurIconButton'
import React, { FC } from 'react'
import { isNotNilAndEmpty } from 'utils/nullish'
import { ISurCardActions } from './SurCard'

export interface ISurCardActionsComponent extends ISurCardActions {
  className?: string
}

const SurCardActions: FC<ISurCardActionsComponent> = ({
  className,
  actionsComponent,
  isExpanded,
  actionButtons,
  onExpandedChange,
  collapseComponent,
}) => (
  <CardActions disableSpacing classes={{ root: className }}>
    {actionsComponent ? (
      actionsComponent(isExpanded)
    ) : isNotNilAndEmpty(isExpanded) ? (
      <div className="SurExpandButtons">
        <div className="SurExpandLeftButtons" style={{ display: 'flex' }}>
          {actionButtons &&
            actionButtons.map((button, index) => (
              <SurButton
                key={index}
                hasNeutral
                ripple="simple"
                variant={button.variant ? button.variant : 'text'}
              >
                {button.content}
              </SurButton>
            ))}
        </div>
        {collapseComponent && (
          <div className="SurExpandRightButtons">
            <SurIconButton
              onClick={() => {
                onExpandedChange!(!isExpanded)
              }}
              variant="text"
              hasNeutral
              shape="circle"
            >
              <ArrowIcon
                style={{
                  transition: '0.5s all',
                  transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                }}
              />
            </SurIconButton>
          </div>
        )}
      </div>
    ) : (
      <>
        {actionButtons &&
          actionButtons.map((button, index) => (
            <SurButton
              key={index}
              hasNeutral
              ripple="simple"
              variant={button.variant ? button.variant : 'text'}
            >
              {button.content}
            </SurButton>
          ))}
      </>
    )}
  </CardActions>
)

export default SurCardActions
