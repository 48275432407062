import { format } from 'date-fns'

export const isToday = (dayLabel: string, month: number, year: number) => {
  const today = new Date()

  return (
    dayLabel === format(today, 'E') &&
    month === today.getMonth() &&
    year === today.getFullYear()
  )
}
