import React, { FC, memo, useState, useEffect, useMemo } from 'react'
import {
  useTable,
  usePagination,
  useRowSelect,
  useSortBy,
  TableToggleAllRowsSelectedProps,
  useResizeColumns,
  useFlexLayout,
  Row,
  useGlobalFilter,
  useColumnOrder,
  Column,
  TableState,
} from 'react-table'
import useStyles from './SurDataGrid.style'
import {
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Table,
  TableFooter,
  TableSortLabel,
  TableContainer,
  Checkbox,
} from '@material-ui/core'
import SurDataGridToolbar from './SurDataGridToolbar'
import SurDataGridPagination from './SurDataGridPagination'
import SurDataGridEditableCell from './SurDataGridEditableCell'
import { innerJoin, map } from 'ramda'
import SurDataGridActionsCell from './SurDataGridActionsCell'
import type {
  ISurDataGrid,
  ISurDataGridInstanceProps,
} from './SurDataGrid.types'
import {Sort as SortIconAsc} from '@sur-ui/icons'
import SurLoading from 'components/SurLoading'

const SurDataGrid: FC<ISurDataGrid> = ({
  idExp = 'id',
  columns,
  data,
  phrases,
  isInlineEdit,
  paginationProps,
  initialValues,
  defaultSortedColumsList,
  defaultColumnOrderList,
  searchInputValue,
  isLoading,
  hasSelection,
  noToolbar,
  hidenColumnsList,
  onRefreshClick,
  onExportClick,
  onPrintClick,
  // onDataChange, //replace Data
  onRowChange, // change row data
  onEditClick, // edit buton event
  onAddClick, // add button event
  onSortChange,
  onSearchInputChange,
  customActions, // custom action right side of edit delete buttons
  onDelete, // list of ids for delete
  'data-testid': dataCy,
}) => {
  const classes = useStyles({})
  const [editedRow, setEditedRow] = useState<any | undefined>() //active edited row original datas

  const newData = useMemo(() => {
    if (editedRow?.isAdd) {
      return [editedRow].concat(data)
    }
    return data
  }, [data, editedRow])

  const defaultColumnOrder = defaultColumnOrderList
    ? [hasSelection ? 'selection' : '', ...defaultColumnOrderList]
    : []

  const defaultColumn: Partial<Column<object>> = React.useMemo(
    () => ({
      // When using the useFlexLayout:
      minWidth: 30, // minWidth is only used as a limit for resizing
      width: 150, // width is used for both the flex-basis and flex-grow
      maxWidth: 200, // maxWidth is only used as a limit for resizing
      Cell: SurDataGridEditableCell,
    }),
    [],
  )

  const initialState: Partial<TableState<object>> = React.useMemo(
    () => ({
      sortBy: defaultSortedColumsList ? defaultSortedColumsList : [],
      columnOrder:
        defaultColumnOrderList && defaultColumnOrderList?.length > 0
          ? defaultColumnOrder
          : undefined,
      hiddenColumns: hidenColumnsList
        ? [!hasSelection ? 'selection' : '', ...hidenColumnsList]
        : [!hasSelection ? 'selection' : ''],
    }),
    [
      hidenColumnsList,
      defaultColumnOrder,
      hasSelection,
      defaultColumnOrderList,
      defaultSortedColumsList,
    ],
  )

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    gotoPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, selectedRowIds, globalFilter, pageSize },
  } = useTable(
    {
      columns,
      data: newData,
      defaultColumn,
      idExp,
      phrases,
      isInlineEdit,
      initialState,
      editedRow,
      setEditedRow,
      customActions,
      onDelete,
      onEdit: onEditClick,
      onRowChange,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useFlexLayout,
    useResizeColumns,
    useColumnOrder,
    (hooks) => {
      hooks.allColumns.push((cols) => [
        // make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox.  Pagination is a problem since this will select all
          // rows even though not all rows are on the current page.
          minWidth: 15, // minWidth is only used as a limit for resizing
          width: 15, // width is used for both the flex-basis and flex-grow
          maxWidth: 15, // maxWidth is only used as a limit for resizing

          Header: ({
            getToggleAllRowsSelectedProps,
          }: {
            getToggleAllRowsSelectedProps: (
              props?: Partial<TableToggleAllRowsSelectedProps>,
            ) => TableToggleAllRowsSelectedProps
          }) => (
            <Checkbox
              color="primary"
              {...(getToggleAllRowsSelectedProps() as any)}
            />
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <Checkbox
              color="primary"
              {...(row.getToggleRowSelectedProps() as any)}
            />
          ),
        },
        ...cols,
        {
          id: 'actions',
          minWidth: 66, // minWidth is only used as a limit for resizing
          width: 66, // width is used for both the flex-basis and flex-grow
          maxWidth: 66, // maxWidth is only used as a limit for resizing
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox.  Pagination is a problem since this will select all
          // rows even though not all rows are on the current page.  The solution should
          // be server side pagination.  For one, the clients should not download all
          // rows in most cases.  The client should only download data for the current page.
          // In that case, getToggleAllRowsSelectedProps works fine.
          Header: ({ phrases: phras }) => phras.actionColumnTitle,
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: (cellProps: ISurDataGridInstanceProps) => (
            <SurDataGridActionsCell
              {...cellProps}
              {...{
                customActions,
                classes: { root: classes.SurDataGridActionsCell },
              }}
            />
          ),
        },
      ])
    },
  )

  useEffect(() => {
    if (paginationProps && paginationProps.defaultPageSize) {
      setPageSize(paginationProps.defaultPageSize)
    }
  }, [paginationProps, setPageSize])

  const handleAdd = () => {
    const emptyRawData = {
      ...initialValues,
      [idExp]: `new-${new Date().getTime()}`,
    }
    if (isInlineEdit) {
      setEditedRow({ ...emptyRawData, isAdd: true })
    } else {
      onAddClick && onAddClick(emptyRawData)
    }
  }

  const handleDelete = () => {
    const deleteListIds = map(
      (item: Row<any>) => item.original[idExp],
      innerJoin(
        (record, index) => Number(record.index) === Number(index),
        rows,
        Object.keys(selectedRowIds),
      ),
    )
    onDelete(deleteListIds)
  }

  return (
    <TableContainer className={classes.root} data-testid={dataCy}>
      {!noToolbar && (
        <SurDataGridToolbar
          numSelected={Object.keys(selectedRowIds).length}
          deleteHandler={handleDelete}
          isAddState={editedRow?.isAdd}
          addHandler={handleAdd}
          {...{
            hasSelection,
            onRefreshClick,
            onExportClick,
            onPrintClick,
            onSearchInputChange,
            globalFilter,
            preGlobalFilteredRows,
            setGlobalFilter,
            searchInputValue,
          }}
        />
      )}
      <Table {...(getTableProps() as any)}>
        <SurDataGridTableHead {...{ headerGroups, onSortChange }} />
        {isLoading ? (
          <SurLoading isLoading={isLoading} />
        ) : (
          <>
            <TableBody>
              {page.map((row) => {
                prepareRow(row)
                const rowProps = row.getRowProps()
                return (
                  <TableRow
                    className={classes.row}
                    {...(rowProps as any)}
                    key={rowProps.key}
                    data-testid={`DataGridRow-${row.cells[0].value}`}
                  >
                    {row.cells.map((cell) => {
                      const cellProps = cell.getCellProps()
                      return (
                        <TableCell {...(cellProps as any)} key={cellProps.key}>
                          {cell.render('Cell')}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
            {paginationProps && (
              <TableFooter>
                <TableRow>
                  <TableCell>
                    <SurDataGridPagination
                      total={paginationProps?.total ?? data.length}
                      {...({
                        ...paginationProps,
                        gotoPage,
                        setPageSize,
                        pageSize: paginationProps?.pageSize ?? pageSize,
                        currentPage:
                          paginationProps?.currentPage ?? pageIndex + 1,
                        onChangePageSize:
                          paginationProps?.onChangePageSize ??
                          ((pageS: number) => setPageSize(pageS)),
                        onChangePage:
                          paginationProps?.onChangePage ??
                          ((current: number) => {
                            gotoPage(current - 1)
                          }),
                      } as any)}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            )}
          </>
        )}
      </Table>
    </TableContainer>
  )
}

const SurDataGridTableHead = ({ headerGroups, onSortChange }: any) => (
  <TableHead>
    {headerGroups.map((headerGroup: any, index: number) => (
      <TableRow key={index} {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map((column: any, i: number) => (
          <TableCell
            key={i}
            className={
              column.isSorted && column.sortable ? 'ColumnIsSorted' : ''
            }
            {...(column.id === 'selection' ?? !column.sortable
              ? column.getHeaderProps()
              : column.getHeaderProps(column.getSortByToggleProps()))}
          >
            {column.render('Header')}
            {column.isSorted && column.sortable ? (
              <TableSortLabel
                active={column.isSorted}
                // react-table has a unsorted state which is not treated here
                direction={column.isSortedDesc ? 'desc' : 'asc'}
                IconComponent={SortIconAsc as any}
                onChange={() =>
                  column.sortable &&
                  onSortChange?.(
                    column.isSorted,
                    column.isSortedDesc ? 'desc' : 'asc',
                  )
                }
              />
            ) : null}
          </TableCell>
        ))}
      </TableRow>
    ))}
  </TableHead>
)

export default memo(SurDataGrid)
