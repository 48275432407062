import { TreeItem, TreeView } from '@material-ui/lab'
import {
  TreeArrow,
  TreeMines as TreeExpandMines,
  TreePlus as TreeExpandPlus,
} from '@sur-ui/icons'
import SurCheckbox from 'components/SurCheckbox'
import React, { FC } from 'react'
import useStyles from './SurTreeView.style'

interface ISurTreeViewProps {
  data: any[]
  checked: string[]
  onCheckedChange: (checked: string[]) => void
  selected: string
  onSelectedChange: (event: React.ChangeEvent<object>, nodeIds: string) => void
  defaultExpanded: string[]
  type: 'checkable' | 'withIcon' | 'customArrow' | 'normal'
  valueExp: string
  labelExp: string
  childrenExp: string
  iconExp?: string
}

export const SurTreeView: FC<ISurTreeViewProps> = ({
  data,
  type,
  checked,
  onCheckedChange,
  selected,
  onSelectedChange,
  defaultExpanded,
  childrenExp = 'children',
  labelExp = 'name',
  valueExp = 'id',
  iconExp = 'icon',
}) => {
  const classes = useStyles({ type })

  // const list_to_tree = (list: any) => {
  // 	var tree: any[] = [],
  // 		mappedArr: object = {},
  // 		arrElem: any,
  // 		mappedElem: any;

  // 	// First map the nodes of the array to an object -> create a hash table.
  // 	for (var i = 0, len = list.length; i < len; i++) {
  // 		arrElem = list[i];
  // 		mappedArr[arrElem.id] = arrElem;
  // 		mappedArr[arrElem.id]["children"] = [];
  // 	}

  // 	for (var id in mappedArr) {
  // 		if (mappedArr.hasOwnProperty(id)) {
  // 			mappedElem = mappedArr[id];
  // 			// If the element is not at the root level, add it to its parent array of children.
  // 			if (mappedElem.parentId) {
  // 				mappedArr[mappedElem["parentId"]]["children"].push(mappedElem);
  // 			}
  // 			// If the element is at the root level, add it to first level elements array.
  // 			else {
  // 				tree.push(mappedElem);
  // 			}
  // 		}
  // 	}
  // 	return tree;
  // };

  const leafIds = (node: any): string[] =>
    !node[childrenExp] ||
    node[childrenExp].length === 0 ||
    node[childrenExp] === null
      ? [node[valueExp]]
      : node[childrenExp].map(leafIds).flat()
  const handleCheck = (node: any, newValue: boolean) => {
    const value = checked.includes(node[valueExp] as never)
    if (!node[childrenExp] || node[childrenExp].length === 0) {
      if (value === newValue) {
        return
      }
      onCheckedChange(
        newValue
          ? [...checked, node[valueExp]]
          : checked.filter((id: string) => id !== node[valueExp]),
      )
    } else {
      const ids = leafIds(node)
      const remaining = checked.filter(
        (id: string) => !ids.includes(String(id)),
      )
      onCheckedChange(newValue ? [...remaining, ...ids] : remaining)
    }
  }

  const TreeNode = ({ node }: { node: any }) => {
    const isChecked = leafIds(node).every((id) => checked.includes(id))
    const isIndeterminate =
      !isChecked && leafIds(node).some((id) => checked.includes(id))
    const onChange = () => {
      handleCheck(node, !isChecked)
    }

    const pickLabel = () => {
      switch (type) {
        case 'checkable':
          return (
            <div style={{ display: 'flex' }}>
              <div
                className="SurTreeCheckBoxContainer"
                onClick={(e) => e.stopPropagation()}
              >
                <SurCheckbox
                  className={classes.checkbox}
                  checked={isChecked}
                  onChange={onChange}
                  onClick={(e) => e.stopPropagation()}
                  indeterminate={isIndeterminate}
                />
              </div>
              <div
                className="SurTreeLabelContainer"
                style={{
                  marginLeft: type === 'checkable' && node.children ? 18 : 0,
                }}
              >
                {node[labelExp]}
              </div>
            </div>
          )

        case 'withIcon':
          return (
            <div className="SurTreeIconContainer">
              {node[iconExp] && (
                <img
                  className="SurTreeIcon"
                  width={18}
                  src={node[iconExp]}
                  alt=""
                />
              )}
              {node[labelExp]}
            </div>
          )

        default:
          return node[labelExp]
      }
    }

    return (
      <TreeItem
        classes={{
          root: classes.treeItem,
          content: 'treeContent',
          expanded: 'treeExpanded',
          selected: 'treeSelected',
          group: 'treeGroup',
          label: 'treeLabel',
        }}
        key={node[valueExp]}
        nodeId={node[valueExp]}
        label={pickLabel()}
      >
        {node[childrenExp] && <TreeNodes nodes={node[childrenExp]} />}
      </TreeItem>
    )
  }

  const TreeNodes = ({ nodes }: { nodes: any[] }) => (
    <>
      {nodes.map((node) => (
        <TreeNode node={node} key={node.id} />
      ))}
    </>
  )

  return (
    <div>
      <TreeView
        {...{
          defaultExpanded,
          selected,
        }}
        className={classes.root}
        defaultCollapseIcon={
          type === 'customArrow' ? (
            <TreeExpandMines />
          ) : (
            <TreeArrow style={{ transform: 'rotate(90deg)' }} />
          )
        }
        defaultExpandIcon={
          type === 'customArrow' ? <TreeExpandPlus /> : <TreeArrow />
        }
        onNodeSelect={onSelectedChange}
      >
        <TreeNodes nodes={data} />
      </TreeView>
    </div>
  )
}

export default SurTreeView
