import React, { FC, memo, useMemo } from 'react'
import classnames from 'classnames'
import { useSurDatePickerContext } from '../SurDatePicker.context'

interface ISurDatePickerWeekLabelProps {
  label: string
  month: number
}

export const SurDatePickerWeekLabel: FC<ISurDatePickerWeekLabelProps> = ({
  label,
  month,
}) => {
  const { value, weekdayLabelFormat } = useSurDatePickerContext()

  const classes = useMemo(() => {
    const today = new Date()

    const isSame = (date: Date) =>
      date.getMonth() === month && label === weekdayLabelFormat(date)
    const startDateClass =
      value.startDate && isSame(value.startDate) && 'activeWeek'
    const endDateClass = value.endDate && isSame(value.endDate) && 'activeWeek'
    const todayClass =
      !(value.startDate ?? value.endDate) && isSame(today) && 'todayWeek'

    return startDateClass ?? endDateClass ?? todayClass
  }, [value, label, month, weekdayLabelFormat])

  const className = classnames('datePickerWeekLabels', classes)

  return <div {...{ className }}>{label}</div>
}

export default memo(SurDatePickerWeekLabel)
