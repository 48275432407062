import React, {
  useState,
  useCallback,
  FC,
  memo,
  useMemo,
  WheelEventHandler,
  ChangeEventHandler,
} from 'react'
import classNames from 'classnames'
import useStyles from './SurScrollableInput.style'
import {InputArrowUp as ScrollableInputArrowUp} from '@sur-ui/icons'
import {InputArrowDown as ScrollableInputArrowDown} from '@sur-ui/icons'

import { usePrevious } from 'react-use'
import { isNotNilAndEmpty } from 'utils/nullish'
import { last } from 'ramda'

export interface ISurScrollableInput<T> {
  value: T
  options?: SurOption[]
  padStart?: number
  min?: number
  max?: number
  color?: 'black' | 'white' | string
  onChange(value: T): void
  range?: any
}

const SurScrollableInput: FC<ISurScrollableInput<any>> = ({
  value,
  padStart = 0,
  onChange,
  options,
  color,
  range,
}) => {
  const [isFocused, setIsFocused] = useState(false)
  const [direction, setDirection] = useState<'up' | 'down' | undefined>()
  const data: SurOption[] = useMemo(() => {
    if (options) {
      return options
    }
    if (!range) {
      return []
    }

    const [max, min = 0, step = 1] = range

    return Array.from({ length: Math.ceil((max - min + 1) / step) }, (_, i) => {
      const val = min + i * step
      return {
        label: `${val}`.padStart(padStart, '0'),
        value: val,
      } as SurOption
    })
  }, [options, range, padStart])

  const current = useMemo(() => {
    const index = data.findIndex((item) => item.value === value)
    return index === -1 ? 0 : index
  }, [value, data])

  const previous = usePrevious(current) as number

  const setDirectionDelay = useCallback(
    (dir: 'up' | 'down') => {
      setDirection(dir)
      setTimeout(() => setDirection(undefined), 300)
    },
    [setDirection],
  )

  const classes = useStyles({ isFocused, color })

  const handleUpButtonClick = () => {
    setDirectionDelay('up')
    onChange(data[(current + 1) % data.length].value)
  }
  const handleDownButtonClick = () => {
    setDirectionDelay('down')
    onChange(data[(data.length + current - 1) % data.length].value)
  }
  const handleInputWheelChange: WheelEventHandler<HTMLInputElement> = (event) =>
    event.deltaY < 0 ? handleUpButtonClick() : handleDownButtonClick()
  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (direction) {
      return
    }
    const min: number = data[0].value
    const max: number = last(data)!.value
    const newValue = parseInt(event.target.value, 10)
    if (min > newValue) {
      onChange(min)
      return
    }
    if (max < newValue) {
      onChange(max)
      return
    }
    onChange(newValue)
  }

  return (
    <div
      className={classes.root}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
    >
      <div className="pickerButtonContainer">
        <button onClick={handleUpButtonClick}>
          <ScrollableInputArrowUp />
        </button>
      </div>
      <div
        className="currentContainer"
        onWheel={(event) => {
          event.stopPropagation()
          event.preventDefault()
        }}
      >
        <span
          className={classNames('counter', {
            [`animating-${direction}`]: direction,
          })}
        >
          <input
            style={{
              backgroundColor: 'transparent',
              border: 0,
              textAlign: 'center',
              flex: 1,
            }}
            readOnly={!range}
            className="current"
            type={!range ? 'string' : 'number'}
            value={isFocused ? data[current].value : data[current].label}
            onChange={handleInputChange}
            onWheel={handleInputWheelChange}
          />
          {direction && isNotNilAndEmpty(previous) && (
            <span className="previous">{data[previous].label}</span>
          )}
        </span>
      </div>
      <div className="pickerButtonContainer">
        <button onClick={handleDownButtonClick}>
          <ScrollableInputArrowDown />
        </button>
      </div>
    </div>
  )
}

SurScrollableInput.displayName = 'SurScrollableInput'
export default memo(SurScrollableInput)
