import * as React from 'react'

const More = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    id="jasmijn_web_ico_more_02"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <rect id="container" width="24" height="24" fill="none" />
    <circle
      id="_01"
      data-name="01"
      cx="2"
      cy="2"
      r="2"
      transform="translate(14 2) rotate(90)"
      fill="#52646f"
    />
    <circle
      id="_02"
      data-name="02"
      cx="2"
      cy="2"
      r="2"
      transform="translate(14 10) rotate(90)"
      fill="#52646f"
    />
    <circle
      id="_03"
      data-name="03"
      cx="2"
      cy="2"
      r="2"
      transform="translate(14 18) rotate(90)"
      fill="#52646f"
    />
  </svg>
)

const MemoSvgComponent = React.memo(More)
export default MemoSvgComponent
