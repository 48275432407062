import { makeFloraMaidStyles } from 'layout/FloraMaidTheme'

interface IStyleProps {
  options?: {
    width?: number | string
    height?: number | string
  }
}

export default makeFloraMaidStyles<IStyleProps>(
  ({ palette: { primary, background }, shadows, spacing }) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      minWidth: 500,
      minHeight: 500,
      overflow: 'hidden',
      backgroundColor: background.paper,
      borderRadius: 5,
      boxShadow: shadows[3],
      position: 'relative',
      // padding: spacing(2, 4, 3),
      '&:focus': {
        outline: 'unset',
      },

      width: ({ options }) => options?.width ?? 500,
      height: ({ options }) => options?.height ?? 500,
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: spacing(4, 5),
      marginBottom: spacing(2),
      alignItems: 'center',
      borderBottom: '1px solid #AFAFAF',
      '& h5': {
        display: 'flex',
        alignItems: 'center',
        color: primary[500],
        fontWeight: '400',
      },
    },
    closeIcon: {
      '& path': {
        fill: primary[100],
      },
    },
    content: {
      padding: spacing(9),
    },
    closeBtn: {
      backgroundColor: 'transparent',
      border: 'unset',
    },
  }),
  { name: 'SurModal' },
)
