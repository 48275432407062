import { makeFloraMaidStyles } from 'layout/FloraMaidTheme'

const useStyle = makeFloraMaidStyles(
  ({ spacing }) => ({
    root: {
      margin: spacing(1),
      color: '#fff',
    },
  }),
  { name: 'SurChip' },
)

export default useStyle
