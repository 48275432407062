import * as React from 'react'

const NoImage = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="55"
    height="42"
    viewBox="0 0 55 42"
    {...props}
  >
    <g id="picture" transform="translate(-1 -15)">
      <rect
        id="Rectangle_1"
        data-name="Rectangle 1"
        width="53"
        height="40"
        transform="translate(2 16)"
        fill="#e7eced"
        stroke="#424a60"
        stroke-miterlimit="10"
        stroke-width="2"
      />
      <rect
        id="Rectangle_2"
        data-name="Rectangle 2"
        width="45"
        height="32"
        transform="translate(6 20)"
        fill="#424a60"
        stroke="#e7eced"
        stroke-miterlimit="10"
        stroke-width="2"
      />
      <circle
        id="Ellipse_2"
        data-name="Ellipse 2"
        cx="4.569"
        cy="4.569"
        r="4.569"
        transform="translate(10.431 23)"
        fill="#efce4a"
      />
      <path
        id="Path_9778"
        data-name="Path 9778"
        d="M50,42.111,49,41,37,30,26.5,41.5l5.483,5.483L36,51H50Z"
        fill="#1a9172"
      />
      <path
        id="Path_9779"
        data-name="Path 9779"
        d="M7,51H36l-4.017-4.017L21.017,36.017,7,48.359Z"
        fill="#25ae88"
      />
    </g>
  </svg>
)

const MemoSvgComponent = React.memo(NoImage)
export default MemoSvgComponent
