import React, { FC } from 'react'
import { useField } from 'formik'
import SurCheckbox, {
  ISurCheckboxProps,
} from 'components/SurCheckbox/SurCheckbox'

type IReqType = 'error' | 'checked' | 'onChange'

const SurCheckboxField: FC<Omit<ISurCheckboxProps, IReqType>> = (props) => {
  const [{ value, ...field }, meta] = useField(props.name as string)

  return (
    <SurCheckbox
      {...props}
      {...field}
      checked={value}
      error={meta.touched && meta.error}
    />
  )
}

export default SurCheckboxField
