import * as React from 'react'

const TreeArrow = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    {...props}
  >
    <g id="leading" transform="translate(-328 -207)">
      <g
        id="jasmijn_web_ico_arrow_02"
        transform="translate(328 223) rotate(-90)"
      >
        <rect id="container" width="16" height="16" fill="none" />
        <path
          id="jasmijn_web_ico_arrow_02-2"
          data-name="jasmijn_web_ico_arrow_02"
          d="M6.587.2A.667.667,0,0,1,7.53.2L13.92,6.586a.667.667,0,0,1-.471,1.138H.668A.667.667,0,0,1,.2,6.586Z"
          transform="translate(14.92 11.724) rotate(180)"
          fill="#52646f"
        />
      </g>
    </g>
  </svg>
)

const MemoSvgComponent = React.memo(TreeArrow)
export default MemoSvgComponent
