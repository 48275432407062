import * as React from 'react'

const Search = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="15.999"
    viewBox="0 0 16 15.999"
    {...props}
  >
    <g id="search" transform="translate(-966 -13)">
      <g id="jasmijn_web_ico_search_01" transform="translate(966 13)">
        <rect
          id="container"
          width="15.999"
          height="15.999"
          transform="translate(0.001)"
          fill="none"
        />
        <path
          id="jasmijn_web_ico_search_01-2"
          data-name="jasmijn_web_ico_search_01"
          d="M16.361,12.608,13.728,9.975a7.265,7.265,0,0,0,.8-3.192,6.783,6.783,0,1,0-6.783,6.783,7.265,7.265,0,0,0,3.192-.8L13.568,15.4a1.928,1.928,0,0,0,2.793,0A2.08,2.08,0,0,0,16.361,12.608ZM7.743,11.97A5.187,5.187,0,1,1,12.93,6.783,5.176,5.176,0,0,1,7.743,11.97Z"
          transform="translate(-0.96)"
          fill="#809eb0"
        />
      </g>
    </g>
  </svg>
)

export default React.memo(Search)
