import { MonthType } from 'hooks/datePicker/DatePicker.types'
import React, { FC } from 'react'
import { useSurDatePickerContext } from '../SurDatePicker.context'
import type { ISurDatePickerContainer } from '../SurDatePicker.types'
import SurDatePickerMonth from './SurDatePickerMonth'

const SurDatePickerContainer: FC<ISurDatePickerContainer> = ({ phrases }) => {
  const { activeMonths, classes } = useSurDatePickerContext()

  return (
    <main className={classes?.months}>
      {activeMonths?.map(({ month, year }: MonthType) => (
        <SurDatePickerMonth key={String(month)} {...{ phrases, month, year }} />
      ))}
    </main>
  )
}

export default SurDatePickerContainer
