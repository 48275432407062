import React, {
  FC,
  createContext,
  useContext,
  useState,
  useCallback,
} from 'react'

import type { OnDatesChangeProps } from 'hooks/datePicker/DatePicker.types'
import { useDatepicker } from 'hooks/datePicker'
import useStyles from './SurDatePicker.styles'
import {
  ISurDatePickerState,
  ISurDatepickerProvider,
  ISurDatepickerContext,
} from './SurDatePicker.types'

export const datepickerContextDefaultValue: ISurDatepickerContext = {
  value: { endDate: null, focusedInput: 'startDate', startDate: null },
  weekdayLabelFormat: () => 'weekdayLabelFormat',
  classes: null,
  hasTime: false,
  focusedDate: null,
  isHoliday: () => false,
  isDateBlocked: () => false,
  isDateFocused: () => false,
  isDateHovered: () => false,
  isDateSelected: () => false,
  isEndDate: () => false,
  isFirstOrLastSelectedDate: () => 'startDate',
  isHolidaied: () => false,
  isStartDate: () => false,
  onDateFocus: () => false,
  onDateHover: () => false,
  onDateSelect: () => false,
  onResetDates: () => false,
  onDatesChange: () => {},
}
const SurDatePickerContext = createContext<ISurDatepickerContext>(
  datepickerContextDefaultValue,
)
const SurDatePickerProvider: FC<ISurDatepickerProvider> = ({
  value,
  onChange,
  dayLabelFormat,
  weekdayLabelFormat,
  monthLabelFormat,
  exactMinBookingDays,
  hasTime,
  children,
  ...rest
}) => {
  const [datePickerState, setDatePickerState] = useState<ISurDatePickerState>({
    isMonthModalOpen: false,
    isYearModalOpen: false,
  })
  const classes = useStyles(datePickerState)

  const onDatesChange = useCallback(
    (data: OnDatesChangeProps) => {
      if (!data.focusedInput) {
        onChange({ ...data, focusedInput: 'startDate' })
      } else {
        onChange(data)
      }
    },
    [onChange],
  )

  const { onDateSelect, ...datepickerResults } = useDatepicker({
    ...value,
    ...rest,
    exactMinBookingDays,
    onDatesChange,
  })

  const handleMonthModalToggle = useCallback(() => {
    setDatePickerState((prev) => ({
      isMonthModalOpen: !prev.isMonthModalOpen,
      isYearModalOpen: false,
    }))
  }, [])
  const handleYearModalToggle = useCallback(() => {
    setDatePickerState((prev) => ({
      isMonthModalOpen: false,
      isYearModalOpen: !prev.isYearModalOpen,
    }))
  }, [])

  const handleResetModals = useCallback(() => {
    setDatePickerState({
      isMonthModalOpen: false,
      isYearModalOpen: false,
    })
  }, [])

  return (
    <SurDatePickerContext.Provider
      value={{
        ...datepickerResults,
        ...datePickerState,
        value,
        classes,
        hasTime,
        dayLabelFormat,
        weekdayLabelFormat,
        monthLabelFormat,
        onDateSelect,
        onDatesChange,
        onMonthModalToggle: handleMonthModalToggle,
        onYearModalToggle: handleYearModalToggle,
        onResetModals: handleResetModals,
        exactMinBookingDays,
      }}
    >
      <div className={classes.root}>{children}</div>
    </SurDatePickerContext.Provider>
  )
}

export const useSurDatePickerContext = () => useContext(SurDatePickerContext)
export default SurDatePickerProvider
