import React, { FC, ReactNode } from 'react'
import { Switch, SwitchProps } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import SurError from 'components/SurError'

type IRqTypes = 'checked' | 'name' | 'color' | 'disabled' | 'onChange'

export interface ISurSwitchProps extends Pick<SwitchProps, IRqTypes> {
  label?: string
  error?: ReactNode
  'data-testid'?: string
}

const SurSwitch: FC<ISurSwitchProps> = ({
  checked,
  onChange,
  name,
  color,
  disabled,
  label,
  error,
  'data-testid': dataCy,
}) => (
  <div style={{ position: 'relative', marginBottom: 25 }}>
    <FormControlLabel
      control={
        <Switch
          {...{
            checked,
            color,
            disabled,
            name,
            onChange,
            'data-testid': dataCy,
          }}
        />
      }
      {...{ label }}
      labelPlacement="start"
    />
    <SurError {...{ error }} />
  </div>
)

SurSwitch.defaultProps = { color: 'primary' }
SurSwitch.displayName = 'SurSwitch'

export default SurSwitch
