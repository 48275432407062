import { makeFloraMaidStyles } from 'layout/FloraMaidTheme'

const useStyle = makeFloraMaidStyles(
  ({ spacing }) => ({
    root: {
      margin: spacing(1),
      color: '#fff',
      '& h5': {
        fontSize: '0.8125rem',
      },
    },
  }),
  { name: 'SurChip' },
)

export default useStyle
