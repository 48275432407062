import { makeFloraMaidStyles } from 'layout/FloraMaidTheme'

interface ISurDataGridStyleProps {
  pageSize?: number
}
export default makeFloraMaidStyles<ISurDataGridStyleProps>(() => ({
  root: {
    height: '100%',
    // '& .MuiTable-root': {
    //   height: ({ pageSize }) => (pageSize ? 50 * pageSize + 114 : 364),
    // },
  },
  row: {
    '& .SurDataGridEditableCell': {
      width: '100%',
      margin: '0 10px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& .SurDataGridTextField': {
        flex: '1',
        backgroundColor: '#fff',
        '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
          padding: '2.2% 4%',
        },
        '& .SurTextField': {
          margin: 0,
        },
      },
    },
  },
  SurDataGridActionsCell: {
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiButtonBase-root': {
      width: 42,
      height: 42,
      padding: 0,
      margin: '0 3px',
    },
  },
}))
