import React, { useCallback } from 'react'
import addDays from 'date-fns/addDays'
import type { UseDayProps } from '../DatePicker.types'

const useDay = ({
  date,
  focusedDate,
  isDateSelected,
  isDateFocused,
  isFirstOrLastSelectedDate,
  isDateHovered,
  isDateBlocked,
  isHolidaied,
  onDateSelect,
  onDateFocus,
  onDateHover,
}: UseDayProps) => {
  const onClick = useCallback(() => onDateSelect(date), [date, onDateSelect])
  const onMouseEnter = useCallback(() => {
    onDateHover(date)
  }, [date, onDateHover])

  const disabled = isDateBlocked(date) && !isDateHovered(date)
  const holiday = isHolidaied(date)

  return {
    tabIndex: focusedDate === null || isDateFocused(date) ? 0 : -1,
    isSelected: isDateSelected(date),
    isSelectedStartOrEnd: isFirstOrLastSelectedDate(date),
    isWithinHoverRange: isDateHovered(date),
    disabledDate: disabled,
    holidayDate: holiday,
    onKeyDown: (e: React.KeyboardEvent<HTMLButtonElement>) => {
      switch (e.key) {
        case 'ArrowRight':
          onDateFocus(addDays(date, 1))
          break
        case 'ArrowLeft':
          onDateFocus(addDays(date, -1))
          break
        case 'ArrowUp':
          onDateFocus(addDays(date, -7))
          break
        case 'ArrowDown':
          onDateFocus(addDays(date, 7))
          break
        default:
          break
      }
    },
    onClick: disabled ? () => {} : onClick,
    onMouseEnter,
  }
}

export default useDay
