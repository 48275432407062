import { sha256 } from 'js-sha256'
import React, { FC } from 'react'
import Dropzone from 'react-dropzone'
import SurFileUploaderProvider from './SurFileUploader.context'
import { ISurUploaderProps } from './SurFileUploader.types'
import SurUploaderLayout from './SurUploaderLayout'
import SurError from '../SurError'

const generateFiles = (
  result: any[],
  onChange: any,
  value: any,
  reset?: boolean,
) => {
  for (let i = 0; i < result.length; i++) {
    const id = sha256(`${result[i].name}-${result[i].size}`)
    if (reset) {
      onChange({ [id]: { id, name: result[i].name } })
    } else {
      onChange({ ...value, [id]: { id, name: result[i].name } })
    }
  }
}
const uploadFiles = async (
  result: any[],
  onUpload: (file: File, id: string, reset?: boolean) => Promise<void>,
  reset?: boolean,
) => {
  for (let i = 0; i < result.length; i++) {
    const id = sha256(`${result[i].name}-${result[i].size}`)
    await onUpload(result[i], id, reset)
  }
}

const SurFileUploader: FC<ISurUploaderProps> = ({
  value,
  error,
  onChange,
  defaultImageAddress,
  onUpload,
  onRemoveDrops,
  onCancelDrops,
  options,
  localization,
}) => {
  const valueCount = Object.keys(value).length
  const {
    accept,
    multiple,
    maxSize,
    minSize,
    dragable,
    clickable,
    maxFiles,
  } = options!

  const onDrop = async (result: any) => {
    if (maxFiles && maxFiles === valueCount) {
      generateFiles(result, onChange, value, true)
      await uploadFiles(result, onUpload, true)
    } else {
      generateFiles(result, onChange, value)
      await uploadFiles(result, onUpload)
    }
  }

  return (
    <SurFileUploaderProvider
      value={{
        value,
        onChange,
        defaultImageAddress,
        onUpload,
        onRemoveDrops,
        onCancelDrops,
        options,
        localization,
      }}
    >
      <div style={{ position: 'relative' }}>
        <Dropzone
          {...{
            onDrop,
            accept,
            multiple,
            maxSize,
            minSize,
            noDrag: !dragable,
            noClick: !clickable,
          }}
        >
          {(props) => <SurUploaderLayout {...props} />}
        </Dropzone>
        <SurError {...{ error }} />
      </div>
    </SurFileUploaderProvider>
  )
}

SurFileUploader.displayName = 'SurFileUploader'
export default SurFileUploader
