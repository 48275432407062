import { makeFloraMaidStyles } from 'layout/FloraMaidTheme'

const useStyle = makeFloraMaidStyles(
  ({ palette: { neutral }, spacing }) => ({
    root: {
      '& .MuiButtonBase-root': {
        width: 36,
        height: 28,
        borderRadius: spacing(1),
        backgroundColor: neutral?.[100],

        '&:hover': {
          backgroundColor: neutral?.[200],
        },
      },
    },
    li: {
      color: neutral?.[500],
    },
  }),
  { name: 'SurBreadcrumb' },
)

export default useStyle
