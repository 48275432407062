import * as React from 'react'

const DialogClose = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    id="jasmijn_web_ico_plus_01"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <rect id="container" width="24" height="24" fill="none" />
    <path
      id="jasmijn_web_ico_plus_01-2"
      data-name="jasmijn_web_ico_plus_01"
      d="M22.8,10.8H13.2V1.2a1.2,1.2,0,0,0-2.4,0v9.6H1.2a1.2,1.2,0,0,0,0,2.4h9.6v9.6a1.2,1.2,0,1,0,2.4,0V13.2h9.6a1.2,1.2,0,1,0,0-2.4Zm0,0"
      fill="#52646f"
    />
  </svg>
)

const MemoSvgComponent = React.memo(DialogClose)
export default MemoSvgComponent
