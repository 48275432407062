import { Checkbox, CheckboxProps } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import SurError from 'components/SurError'
import React, { FC, ReactNode } from 'react'
import {CheckboxTick as Tick} from '@sur-ui/icons'
import {CheckboxIndeterminate as Indeterminate} from '@sur-ui/icons'
import {CheckboxUnchecked as Unchecked} from '@sur-ui/icons'
import useChackboxStyle from './SurCheckbox.styles'

type IReqProps =
  | 'checked'
  | 'style'
  | 'onClick'
  | 'indeterminate'
  | 'name'
  | 'className'
  | 'disabled'
  | 'onChange'

export interface ISurCheckboxProps extends Pick<CheckboxProps, IReqProps> {
  'data-testid'?: string
  label?: string
  error?: ReactNode
  color?: 'primary' | 'secondary'
}

const SurCheckbox: FC<ISurCheckboxProps> = ({
  checked,
  className,
  style,
  indeterminate,
  onChange,
  onClick,
  name,
  color = 'primary',
  disabled,
  label,
  error,
  'data-testid': dataCy,
}) => {
  const classes = useChackboxStyle({ color })
  return (
    <div className={classes.root}>
      <FormControlLabel
        control={
          <Checkbox
            {...{
              checked,
              className,
              style,
              color,
              disabled,
              indeterminate,
              name,
              onClick,
              onChange,
              'data-testid': dataCy,
            }}
            indeterminateIcon={<Indeterminate />}
            checkedIcon={<Tick className={classes.tick} />}
            icon={<Unchecked />}
          />
        }
        {...{ label }}
        labelPlacement="end"
      />
      <SurError {...{ error }} />
    </div>
  )
}

SurCheckbox.displayName = 'SurCheckbox'

export default SurCheckbox
