import { makeFloraMaidStyles } from 'layout/FloraMaidTheme'
import { ISurAvatarSizes } from './SurAvatar'

interface IStyleProps {
  size?: ISurAvatarSizes
}

const containerSizeSelector = (size?: ISurAvatarSizes) => {
  switch (size) {
    case 'small':
      return 32
    case 'large':
      return 78
    default:
      return 48
  }
}
const fontSizeSelector = (size?: ISurAvatarSizes) => {
  switch (size) {
    case 'small':
      return 14
    case 'large':
      return 32
    default:
      return 26
  }
}

const svgSizeSelector = (size?: ISurAvatarSizes) => {
  switch (size) {
    case 'small':
      return 24
    case 'large':
      return 58
    default:
      return 32
  }
}

export default makeFloraMaidStyles<IStyleProps>(() => ({
  root: {
    width: ({ size }) => containerSizeSelector(size),
    height: ({ size }) => containerSizeSelector(size),
    fontSize: ({ size }) => fontSizeSelector(size),
    '& > svg': {
      width: ({ size }) => svgSizeSelector(size),
      height: ({ size }) => svgSizeSelector(size),
    },
    zIndex: 0,
    // border: "2px solid #ffffff",
  },
}))
