// import { reduce, max, min, pipe } from "ramda";

// export const maxList = (list: number[], percent: number): number => {
// 	return pipe(
// 		reduce(max, -Infinity),
// 		Number,
// 		num => (num += num * percent),
// 		num => Number(num.toFixed(2)),
// 	)(list);
// };

// export const minList = (list: number[], percent: number): number => {
// 	return pipe(
// 		reduce(min, Infinity),
// 		Number,
// 		num => (num -= num * percent),
// 		num => Number(num.toFixed(2)),
// 	)(list);

// 	// return reduce(min, Infinity, list) as number;
// };

export const byteConvertor = (bytes: number) => {
  const sizes = ['b', 'kb', 'mb', 'gb', 'tb']
  if (bytes === 0) {
    return 'n/a'
  }
  //@ts-ignore
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
  if (i === 0) {
    return `${bytes} ${sizes[i]}`
  }
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`
}

export const calcPercentage = (value: number, max: number): number => {
  const percentValue = Number(((value * 100) / max).toFixed(0))
  return value >= max ? 100 : percentValue
}
