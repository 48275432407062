import * as React from 'react'

const CheckboxIndeterminate = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g id="control" transform="translate(-1100 -128)">
      <rect
        id="box"
        width="24"
        height="24"
        rx="3"
        transform="translate(1100 128)"
        fill="#578871"
      />
      <g id="mines">
        <g id="jasmijn_web_ico_mines_01" transform="translate(1104 132)">
          <rect id="container" width="16" height="16" fill="none" />
          <rect
            id="jasmijn_web_ico_mines_01-2"
            data-name="jasmijn_web_ico_mines_01"
            width="16"
            height="4"
            rx="2"
            transform="translate(0 6)"
            fill="#fff"
          />
        </g>
      </g>
    </g>
  </svg>
)

const MemoSvgComponent = React.memo(CheckboxIndeterminate)
export default MemoSvgComponent
