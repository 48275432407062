import * as React from 'react'

const Add = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    {...props}
  >
    <g id="ico" transform="translate(-352 -120)">
      <path
        id="ico-2"
        data-name="ico"
        d="M14.571,6.571H9.714a.286.286,0,0,1-.286-.286V1.429a1.429,1.429,0,1,0-2.857,0V6.286a.286.286,0,0,1-.286.286H1.429a1.429,1.429,0,1,0,0,2.857H6.286a.286.286,0,0,1,.286.286v4.857a1.429,1.429,0,0,0,2.857,0V9.714a.286.286,0,0,1,.286-.286h4.857a1.429,1.429,0,0,0,0-2.857Zm0,0"
        transform="translate(352 120)"
        fill="#de8165"
      />
    </g>
  </svg>
)

const MemoSvgComponent = React.memo(Add)
export default MemoSvgComponent
