import React, { FC } from 'react'
import { ISurDataGridPaginationProps } from '../SurDataGrid.types'
import SurDataGridPaginationPageRange from './SurDataGridPaginationPageRange'
import SurDataGridPaginationDetail from './SurDataGridPaginationDetail'
import SurDataGridPaginationDisplayRows from './SurDataGridPaginationDisplayRows'
import useStyle from './SurDataGridPagination.styles'

const SurDataGridPagination: FC<ISurDataGridPaginationProps> = ({
  currentPage,
  pageSize,
  total,
  pageSizes = [5, 10, 15, 20],
  onChangePage,
  onChangePageSize,
  hideArrow,
  labelDisplayedRows = SurDataGridPaginationDisplayRows,
  labelPerPage = 'Per Page',
  hidePageRange,
  hideDetail,
  gotoPage,
  setPageSize,
  boundaryCount = 1,
  itemsBeforeActive = 1,
  itemsAfterActive = 1,
}) => {
  const classes = useStyle({ hidePageRange })

  return (
    <div className={classes.root}>
      {!hidePageRange && (
        <SurDataGridPaginationPageRange
          {...{
            currentPage,
            itemsBeforeActive,
            itemsAfterActive,
            boundaryCount,
            hideArrow,
            onChangePage,
            pageSize,
            total,
            gotoPage,
          }}
        />
      )}
      {!hideDetail && (
        <SurDataGridPaginationDetail
          {...{
            pageSize,
            setPageSize,
            currentPage,
            total,
            labelDisplayedRows,
            labelPerPage,
            pageSizes,
            onChangePageSize,
          }}
        />
      )}
    </div>
  )
}

export default SurDataGridPagination
