import * as React from 'react'

const AlertWarning = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    id="jasmijn_web_ico_attention_01"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <rect id="container" width="24" height="24" fill="none" />
    <path
      id="jasmijn_web_ico_attention_01-2"
      data-name="jasmijn_web_ico_attention_01"
      d="M23.781,40.236h0c-1.6-3.277-4.276-7.926-6.639-12.027-1.236-2.146-2.4-4.173-3.249-5.714a2.124,2.124,0,0,0-3.787,0c-.845,1.541-2.013,3.57-3.251,5.718C4.493,32.313,1.816,36.96.219,40.236A2.131,2.131,0,0,0,0,41.174a2.153,2.153,0,0,0,2.144,2.159H21.856A2.153,2.153,0,0,0,24,41.174,2.131,2.131,0,0,0,23.781,40.236ZM12,41.333a1,1,0,1,1,1-1A1,1,0,0,1,12,41.333Zm1-3.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-11a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Z"
      transform="translate(0 -20.332)"
      fill="#F3AF29"
    />
  </svg>
)

const MemoSvgComponent = React.memo(AlertWarning)
export default MemoSvgComponent
